import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { ModelBase } from "../Models/ModelBase";
import { IApiServiceExt } from "./Shared/ApiServiceExt";
import { UtentiBOModel } from "../app/Contents/Utenti/PageUtentiBORicerca";

export interface IUtentiBOEditService extends IBaseComponentService {
    SaveUtenti(data: UtentiBOModel): Promise<UtentiBOModel>;
    ResetPassword(data: UtentiBOModel): Promise<UtentiBOModel>
}

@injectable()
export class UtentiEditService extends BaseComponentService implements IUtentiBOEditService {

    RenderApi: string = Api.RenderEditBOUtenti;
    _apiService: IApiServiceExt;    

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);        
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.EDITUTENTIBO;
    }

    async SaveUtenti(data: UtentiBOModel): Promise<UtentiBOModel> {
        const resp = await this._apiService.postAsync<any>(Api.SaveUtenteBO, data, "Salvataggio avvenuto correttamente")
        return resp
    }
    async ResetPassword(data: UtentiBOModel): Promise<UtentiBOModel> {
        const resp = await this._apiService.getAsync<any>(Api.ResetPassword, { id : data.Id })
        return resp
    }
}