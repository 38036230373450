import { Store, Action, ActionCreator, AnyAction, Reducer } from 'redux';
import { HeaderModel } from '../HeaderTs';
import { injectable } from 'inversify';
import IoCContainer from '../../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../../../Wires/Identifiers";

//modello di base dei modelli a corredo delle action
export interface IActionModel{    
}

export interface IActionBase<T extends IActionModel> extends Action{
    //Dispatch(model:T):Action;
    Eval(state:T,action:AnyAction) :T;
    payload():T;
    Dispatch(model:T,type:string):void;
}
@injectable()
export abstract class ActionBase<T extends IActionModel> implements IActionBase<T>  {
   ///ereditata dall'interfaccia Action 
   type(): any{
    return null;
   }
   protected state:T;
   protected action:AnyAction & IActionBase<T>;

   private _store : Store<any> = null;

   payload():T
   {
       return this._actionModel;
   }

   protected _actionModel:T;
   //dispatcher delle store.
   public Dispatch(model:T,type:string):void{          
        if (this._store==null) this._store =IoCContainer.get(SERVICE_IDENTIFIER.STORE);
        this._actionModel=model;
        this._store.dispatch( {type:type,payload:this.payload()} as Action);    
   }       
   //usato nei reducers è la factory che smista i vari "types"
   Eval(state:T,action:AnyAction & IActionBase<T>) :T{
        
        let type = ((action as Action).type);     
        if(typeof this[type] !== "function"){
            return state;            
        }
        
        this.state=state;
        this.action=action;
        return this[type](state,action);
   }

   
}


