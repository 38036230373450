import * as React from 'react'
import { Map, TileLayer, MapProps } from 'react-leaflet'
import './OpenStreetMap.css'

export const OpenStreetMap: React.FC<MapProps> = (props) => {
    return (
        <Map {...props}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {props.children}
        </Map>
    )
}