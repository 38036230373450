import { inject, injectable } from "inversify";
import { IApiService } from './Shared/ApiService';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import * as Api from "../Constants/ApiUrlConstants";
import { ProjectionPagedModel } from '../Models/ProjectionPageModel';
import { IBaseComponentService, BaseComponentService } from './Shared/BaseComponentService';
import IoCContainer from '../Wires/Bootstrapper';
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { UserSearcherModel } from "../Models/UserSearcher";
import { UserProjection } from "../Models/UserProjection";

export interface IRicercaUtentiService extends IBaseComponentService
{
    GetUtenti(text:string, page:number) : Promise<ProjectionPagedModel<UserProjection>>;   
}

@injectable()
export class RicercaUtentiService extends BaseComponentService implements IRicercaUtentiService {
    getProviderName(): string {
        return PROVIDERS_REGISTRATION.RICERCAUTENTI;
    }
    RenderApi: string = Api.RenderRicercaUtenti;
    _apiService:IApiService;
    constructor()
    {
        super();
        this._apiService=IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    async GetUtenti(text:string, page:number) : Promise<ProjectionPagedModel<UserProjection>>
    {        
        let searcher : UserSearcherModel = 
        {
            FullText : text,
            Pagina : page,
            Righe : 3,            
         }

        const resp = await this._apiService.postAsync<any>(Api.GetUtentiProjection,searcher);

        return resp;

    }
}
