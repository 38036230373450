import * as React from 'react';
import { TTIcon } from './TTIcon';
import { Avatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';

export interface ITTAvatarProps extends AvatarProps {
    tticon: string
}

export const TTAvatar: React.FC<ITTAvatarProps> = (props) => {
    return (
        <Avatar size={40} {...props}>
            <TTIcon icon={props.tticon} style={{ fontSize: 20 }} />
        </Avatar>
    );
}