import * as React from 'react'
import { Card, List } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'

import TTComponent from '../../../Shared/BaseComponent/TTComponentTs'
import container from '../../../Wires/Bootstrapper'
import { IAppInfoAssistenzaService } from '../../../Services/AppInfoAssistenzaService'
import { TTIcon } from '../../Contents/TTIcon'
import { IAppOfficeService } from '../../../Services/AppOfficeService'
import { SupportoAppModel } from '../../../Models/AppOfficeModels'
import { TTAvatar } from '../../Contents/TTAvatar'

class PageInfoAssistenzaState extends SupportoAppModel {}

export class PageInfoAssistenzaComp extends React.Component<RouteComponentProps, PageInfoAssistenzaState> {

    service: IAppOfficeService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.APP_OFFICE_SERVICE)

        let toolbar = container.get<IAppInfoAssistenzaService>(SERVICE_IDENTIFIER.APP_ASSISTENZA_SERVICE).getToolbar()
        toolbar.addBackClick(() => this.props.history.push("/"))
        toolbar.addButtonClick("Privacy", () => this.openUrl(this.state.LinkPrivacy))
        toolbar.addButtonClick("Condizioni", () => this.openUrl(this.state.LinkCondizioni))

        let state = new PageInfoAssistenzaState()
        state.OrariUfficio = []
        this.state = state
    }

    async componentDidMount() {
        let resp = await this.service.GetTootechData()
        this.setState(resp)
    }

    openUrl = (url: string) => {
        window.open(url)
    }

    onCallClick = () => {
        window.open(`tel:${this.state.Telefono}`)
    }

    onMailClick = () => {
        window.open(`mailto:${this.state.Mail}`)
    }

    render() {
        return (
            <Card title="Assistenza" bordered={false}>
                <p>Chiama il servizio clienti</p>
                <br />
                <List.Item.Meta
                    avatar={<TTAvatar tticon="chiama" style={{ background: '#00966c' }} />}
                    title={<a onClick={() => this.onCallClick()}>{this.state.Telefono}</a>}
                    description="Chiama" />
                <br />
                <List.Item.Meta
                    avatar={<TTAvatar tticon="modifica" style={{ background: '#00966c' }} />}
                    title={<a onClick={() => this.onMailClick()}>{this.state.Mail}</a>}
                    description="Scrivi" />
                <br />
                <br />
                <i>{this.state.OrariUfficio.map((orari, i) => <span key={i}>{orari}<br /></span>)}</i>
            </Card>
        )
    }
}

export const PageInfoAssistenza = withRouter(TTComponent(PageInfoAssistenzaComp, 'Info & Assistenza', SERVICE_IDENTIFIER.APP_ASSISTENZA_SERVICE))