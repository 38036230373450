import * as React from 'react';
import { Col, Row, List, Avatar, Button, message, Modal, Input } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment'

import { TTComponentBoxed } from '../../../Shared/BaseComponent/TTComponentTs';

import { RouteComponentProps } from 'react-router';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import IoCContainer from '../../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';

import { ICircolariService } from '../../../Services/CircolariService';
import { CircolariState } from '../../../Models/CircolariState';
import { ProjectionPagedModel } from '../../../Models/ProjectionPageModel';
import { CircolareProjection } from '../../../Models/CircolareProjection';
import { SearcherBaseModel } from '../../../Models/SearcherBaseModel';
import { BaseFormReactComponent } from '../BaseReactComponent';
import { TTAvatar } from '../TTAvatar';
import { TTIcon } from '../TTIcon';
import { TTList } from '../TTList';
import { TTModal } from '../TTModal';
import { TTIconButton } from '../TTIconButton';
const { Search } = Input
import * as Constants from './../../../Constants/AppConstants'

const ComponentService: symbol = SERVICE_IDENTIFIER.CIRCOLARI_SERVICE;

export class PageCircolari extends BaseFormReactComponent<IStoreModel & RouteComponentProps & ITTProps, CircolariState> {

    _service: ICircolariService;
    private RigheLista = Constants.ListSizePage

    constructor(props) {
        super(props)
        this.state = this.props.componentData || new CircolariState();

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CIRCOLARI_SERVICE)
        this._service.getToolbar().addButtonClick("NuovaCircolare", this.onNewClick)
    }

    onNewClick = () => {
        this.props.history.push('/CircolareEdit')
    }

    onCircolareEdit = item => {
        
        this.props.history.push('/CircolareEdit/' + item.Id)
    }

    onCircolareDelete = item => {
        TTModal.confirm(
            {
                title:'Attenzione',
                content: 'Sei sicuro di voler cancellare la circolare?',
                okText: 'Si',
                cancelText: 'No',
                onOk:()=>
                {
                    this._service.DeleteCircolare(item.Id).then(
                        resp => {
                            debugger;
                            this.onPageChange(this.state.Page)
                        }
                    );
                }
            });

       
    }

    onSearch = () => {
        this.getCircolari(1);
    }

    getCircolari = page => {
        let searcher: SearcherBaseModel =
        {
            Pagina: page,
            Righe: this.RigheLista,
            FullText: this.state.Searcher
        }

        this._service.GetCircolari(searcher).then(
            resp => this.setState({ Data: resp, Page: page })
        )
    }

    onPageChange = page => {
        this.getCircolari(page);
    }

    formatDate = date => {
        return date == null ? '' : moment(date).format('l')
    }

    render() {

        let list: ProjectionPagedModel<CircolareProjection> = this.state.Data;
        return (
            <div>
                <Row>
                    <Col offset={1} span={22}>
                        <Search placeholder="Cerca circolare" onChange={this.onChangeText} onSearch={value => this.onSearch()} value={this.state.Searcher} name="Searcher" enterButton />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <TTList
                            itemLayout="horizontal"
                            dataSource={list.Data}
                            pagination={{
                                onChange: (page) => this.onPageChange(page),
                                pageSize: this.RigheLista,
                                total: list.Total,
                                showTotal: (total) => `${total} elementi`,
                                current: this.state.Page
                            }}
                            renderItem={item => (
                                <List.Item
                                    actions={[
                                        <TTIconButton tticon="modifica" onClick={() => this.onCircolareEdit(item)} />,
                                        <TTIconButton tticon="elimina" onClick={() => this.onCircolareDelete(item)} />
                                    ]}>
                                    <List.Item.Meta
                                        avatar={<TTAvatar tticon="circolari" />}
                                        title={item.Titolo}
                                        description={this.formatDate(item.CreationDate)}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const Circolari = withRouter(TTComponentBoxed(PageCircolari, 'Elenco Circolari', ComponentService));
export default Circolari;