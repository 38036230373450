import * as React from 'react'
import { Button } from 'antd'
import { TTIcon } from './TTIcon'
import { ButtonProps } from 'antd/lib/button'

export interface ITTIconProps extends ButtonProps {
    tticon: string
}

export const TTIconButton: React.FC<ITTIconProps> = props => {
    // I pulsanti con sola icona devono essere tondi (e neri?)
    return (
        <Button type="primary" shape="circle" size="large" {...props} >
            <TTIcon icon={props.tticon} />
        </Button>
    )
}