import * as React from 'react'
import { Card, Row, Col, List, Avatar, Typography } from 'antd'
import TTComponent from '../../../Shared/BaseComponent/TTComponentTs'
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import IoCContainer from '../../../Wires/Bootstrapper';
import { LicenzaClientiModel } from '../../../Models/LicenzeModels';
import { RicercaLicenzaClienti } from './RicercaLicenzaClienti'
import moment from 'moment';
import { ILicenzaClientiService } from '../../../Services/LicenzaClientiService';


const ComponentService: symbol = SERVICE_IDENTIFIER.LICENZA_CLIENTI_SERVICE;

class LicenzaClientiState extends LicenzaClientiModel {
    Clienti: Array<string>
    RefreshList: boolean
}

export class PageLicenzaClienti extends React.Component<RouteComponentProps & ITTProps, LicenzaClientiState> {

    service: ILicenzaClientiService

    constructor(props) {
        super(props)

        var states = this.props.componentData
        states.RefreshList = false

        this.state = states

        this.service = IoCContainer.get(SERVICE_IDENTIFIER.LICENZA_CLIENTI_SERVICE);
        this.service.getToolbar().addBackClick(() => this.props.history.push("/Licenze"));
        this.service.getToolbar().addButtonClick("Assegna", () => this.onAssegna());
    }

    getDescription = () => {
        return (
            <span>
                {this.state.LicenzaId} <br />
                {`Scadenza ${moment(this.state.Scadenza).format('L')} - ${this.state.ClientiRimanenti} disponibili`}
            </span>
        )
    }

    onAssegna = () => {
        const { LicenzaId, Clienti } = this.state
        this.service.AssegnaClienti(LicenzaId, Clienti).then(it => {
            if (it.IsValid) {
                this.service.Render(this.state.LicenzaId).then(it => {
                    this.setState({ ...it, RefreshList: !this.state.RefreshList, Clienti:[] })
                })
            }
        })
    }

    updateClienti = (Clienti: string[]) => {
        this.setState({ Clienti })
        console.log(Clienti)
    }

    render() {
        return (
            <>
                <Row type="flex" gutter={24}>
                    <Col span={12}>
                        <Card bordered={false} title="Licenza selezionata" className="card-full-height">
                            <List.Item.Meta
                                avatar={<Avatar icon="account-book" size={50}></Avatar>}
                                title={`Licenza per ${this.state.NumeroClienti} clienti`}
                                description={this.getDescription()}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} title="Clienti assegnati a questa licenza" className="card-full-height">
                            <List
                                grid={{ gutter: 16, column: 2 }}
                                dataSource={this.state.ClientiAssegnati}
                                split={false}
                                locale={{ emptyText: 'Nessun cliente assegnato' }}
                                renderItem={item => (
                                    <List.Item className="card-item" key={item.Id}>
                                        {`• ${item.Descrizione}`}
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Col>
                </Row>
                <br />
                <RicercaLicenzaClienti refresh={this.state.RefreshList} clienti={this.updateClienti} />
            </>
        )
    }
}

const LicenzaClienti = withRouter(TTComponent(PageLicenzaClienti, 'Licenze', ComponentService))
export default LicenzaClienti