import * as React from 'react';
import { IBaseComponentService } from '../../Services/Shared/BaseComponentService';
import { IApiService } from '../../Services/Shared/ApiService';
import { IAuthService } from '../../Services/Security/AuthServiceTs';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import container from '../../Wires/Bootstrapper';
import { IToolbarService } from '../../Services/ToolbarService/ToolbarServiceTs';
import { IToolbaractions } from '../../Shared/BaseComponent/ITTProps';
import { IComponentService } from '../../Services/Shared/ComponentService';
import { Select,Radio } from 'antd';
import moment, { Moment } from 'moment';
import { FormComponentProps } from 'antd/lib/form/Form';
import { ModelBase } from '../../Models/ModelBase';



export abstract class BaseFormReactComponent<TP extends any & IToolbaractions & FormComponentProps, TS> extends React.Component<TP, TS> {    
    protected apiClient: IApiService
    private authService: IAuthService;
    protected toolbarService: IToolbarService;
    private compData: any;
    //protected abstract getServiceIdentifier(): symbol;

    constructor(props) {
        super(props);
        this.apiClient = container.get(SERVICE_IDENTIFIER.API_SERVICE);
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE);
        //this.service = container.get(this.getServiceIdentifier());
    }

    

    protected onChangeText = e => {     
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.updateFieldValue(value, name)
        this.changeText(value, name);
    };

    private changeText = (value,name) => {                
        let data = this.state;
        data[name] = value;
        this.setState(data);
    };

    protected onChangeData = (data: Moment, dateString, name) => {
        if (data == null) dateString = moment().format("DD/MM/YYYY");
        dateString = data.toISOString(true)
        this.updateFieldValue(data, name)
        this.changeText(dateString, name)
    };

    protected renderSelect = (source, key:string, value:string) => {
        let items = source.map(item =>
            <Select.Option key={item[key]} value={item[key]}>{item[value]}</Select.Option>
        );

        return items;
    }

    protected renderSelectDictionary = (source) => {
        let items = Object.keys(source).map((key, index) =>
            <Select.Option key={index} value={key}>{source[key]}</Select.Option>
        )

        return items;
    }

    protected renderRadioButton = source => {
        let items = [];

        Object.keys(source).map((key, index) => (
            items.push(<Radio.Button key={index} value={key}>{source[key]}</Radio.Button>)
        ));

        return items;
    }

    protected getListOf(values: Array<string>): Array<any>
    {
        var items = [];
        values.map(h => items.push({ Id: h }));

        return items;
    }

    private updateFieldValue(value, name) {
        const { form } = this.props

        // Il campo non è contenuto in una form o non è sotto fieldDecorator
        if (form == null || form.getFieldValue(`${name}`) == null) return

        form.setFieldsValue({
            [`${name}`]: value
        });
    }

    public getRequiredField(child: JSX.Element) {
        return this.getRequiredFieldInit(this.state[child.props.name], child)
    }

    public getRequiredFieldInit(initialValue: any, child: JSX.Element) {
        return this.getRequiredFieldFull(child.props.name, initialValue, child)
    }

    public getRequiredFieldFull(key: string, initialValue: any, child: React.ReactNode) {
        const { getFieldDecorator } = this.props.form;

        return getFieldDecorator(key, {
            initialValue: initialValue,
            rules: [
                {
                    required: true,
                    message: `Campo obbligatorio`,
                }
            ]
        })(child)
    }

    public getFieldDecorator(key: string, initialValue: any, child: React.ReactNode) {
        const { getFieldDecorator } = this.props.form;

        return getFieldDecorator(key, {
            initialValue: initialValue
        })(child)
    }

    public validateAndSaveExtra<T extends ModelBase>(call: () => Promise<T>, postCall: (resp: T) => void) {
        this.props.form.validateFields((error, values) => {
            if (error == null) {
                call().then(resp => {
                    if (resp.IsValid == false) {
                        this.props.form.setFields((resp as any).FormValidation);
                    }

                    postCall(resp)
                })
            }
        });
    }

    public validateAndSave(call: () => Promise<ModelBase>) {
        this.validateAndSaveExtra(call, () => null)
    }

}

export abstract class BaseReactComponent<TP extends any & IToolbaractions, TS, TProj> extends BaseFormReactComponent<TP, TS> {
    protected service: IComponentService<TProj>;
    protected abstract getServiceIdentifier(): symbol;

    constructor(props) {
        super(props);
        this.service = container.get(this.getServiceIdentifier());
    }
}
