import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { CircolareData } from "../app/Contents/Circolari/PageCircolareEdit";
import { ModelBase } from "../Models/ModelBase";
import { IFormDataConverter } from "./Shared/FormDataConverter";
import { IApiServiceExt } from "./Shared/ApiServiceExt";

export interface ICircolareEditService extends IBaseComponentService {
    SaveCircolare(data: CircolareData): Promise<ModelBase>
}

@injectable()
export class CircolareEditService extends BaseComponentService implements ICircolareEditService {

    RenderApi: string = Api.RenderEditCircolari;
    _apiService: IApiServiceExt;
    _formDataConverter: IFormDataConverter

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
        this._formDataConverter = IoCContainer.get(SERVICE_IDENTIFIER.FORMDATA_CONVERTER);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.CIRCOLAREEDIT;
    }

    async SaveCircolare(data: CircolareData): Promise<ModelBase> {
        var input = this._formDataConverter.convert(data)

        const resp = await this._apiService.postAsync<any>(Api.SaveCircolare, input, "Salvataggio avvenuto correttamente")
        return resp
    }
}