import * as React from 'react'
import { Card } from 'antd'
import { AdempimentiChart } from './AdempimentiChart'

import * as Colors from '../../../Constants/ColorConstants'
import IoCContainer from '../../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IHomeService } from '../../../Services/HomeService';
import { AdempimentiDashboardModel } from '../../../Models/DashboardModels';

export class AdempimentiComponent extends React.Component<any, AdempimentiDashboardModel> {

    private service: IHomeService

    constructor(props) {
        super(props)
        this.service = IoCContainer.get(SERVICE_IDENTIFIER.HOME_SERVICE);

        this.state = new AdempimentiDashboardModel();
    }

    componentDidMount() {
        this.service.getAdempimenti().then(resp => this.setState(resp))
    }

    render() {
        return (
            <Card bordered={false} title="Stato adempimenti">
                <AdempimentiChart
                    todoColor={Colors.Todo} inProgressColor={Colors.InProgress} doneColor={Colors.Done}
                    todo={this.state.ToDo} inProgress={this.state.InProgress} done={this.state.Done} />
            </Card>
        )
    }
}