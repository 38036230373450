import { IErrorServer } from '../../Models/ServerErrorModel';
export interface IError {
  isError: boolean;
  message?: IErrorServer | null;
}
export class Error implements IError {
  isError: boolean;
  message?: IErrorServer | null;
  constructor() {
    isError: false;
  }
}