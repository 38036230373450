import * as React from 'react'
import { Table, Avatar, List } from 'antd';
import { ClienteSearcher } from '../../../Models/UtentiSearcher';
import { IClienteService } from '../../../Services/ClienteService';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { UtentiProjection } from '../../../Models/UtentiProjection';
import { PagedBaseState } from '../../../Models/PagedBaseState';
import { TTAvatar } from '../TTAvatar';

export interface IUserPickerProps {
    clienteId: string
    selectedUsers: (users: string[]) => void
}

export class UserPickerState extends PagedBaseState<UtentiProjection> {
    public SelectedKeys: string[]
}

export class UserPicker extends React.Component<IUserPickerProps, UserPickerState> {

    private _service: IClienteService;

    constructor(props) {
        super(props)

        var state = new UserPickerState()
        state.SelectedKeys = []

        this.state = state
    }

    componentDidMount() {
        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE)

        var searcher: ClienteSearcher = {
            ClienteId: this.props.clienteId,
            Pagina: 1,
            Righe: 10000
        }

        this._service.GetClienteUtenti(searcher).then(resp => this.setState({ Data: resp }))
    }

    getRowLayout = (text: string, item: any) => {
        var title = `${item.FirstName} ${item.LastName} (${item.UserName})`
        return <List.Item.Meta avatar={<TTAvatar tticon="user" />} title={title} />
    }

    onSelectChange = SelectedKeys => {
        this.setState({ SelectedKeys })
        this.props.selectedUsers(SelectedKeys)
    }

    render() {
        const tableColumns = [
            {
                render: this.getRowLayout
            }
        ]

        const { SelectedKeys } = this.state

        const rowSelection = {
            SelectedKeys,
            onChange: this.onSelectChange,
        }

        return (
            <Table
                rowSelection={rowSelection}
                columns={tableColumns}
                dataSource={this.state.Data.Data}
                rowKey="Id"
                pagination={false}
                showHeader={false} />
        )
    }
}