import * as React from 'react'
import { Card } from 'antd'

import { FatturatoModel } from '../../../Models/FatturatoModels' 
import { IClienteService } from '../../../Services/ClienteService';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { IClienteBaseProps } from '../../../Models/IClienteBaseProps';

import { AxesChartFatturato, FatturatoBarSeriesPoint } from './AxesChartFatturato'

class ClienteFatturatoState {
    Current: Array<FatturatoBarSeriesPoint>
    Previous: Array<FatturatoBarSeriesPoint>
}

export class ClienteFatturato extends React.Component<IClienteBaseProps, ClienteFatturatoState> {

    private service: IClienteService;

    constructor(props) {
        super(props)
        this.service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE);
        this.state = new ClienteFatturatoState()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.clienteId != this.props.clienteId) {
            this.load()
        }
    }

    load = async () => {
        let resp = await this.service.GetClienteFatturato(this.props.clienteId)
        if (resp.IsValid) {
            let curr = resp.Current.map(e => this.mapBar(e, resp.CurrentSerieColor))
            let prev = resp.Previous.map(e => this.mapBar(e, resp.PreviousSerieColor))
            this.setState({
                Current: curr,
                Previous: prev
            })
        }
    }

    mapBar = (e: FatturatoModel, color: string) => {
        let model: FatturatoBarSeriesPoint = {
            x: e.x,
            y: e.y,
            year: e.year,
            color
        }

        return model
    }

    render() {
        return (
            <Card bordered={false} title="Fatturato" className="card-full-height">
                <AxesChartFatturato
                    previousYear={this.state.Previous}
                    currentYear={this.state.Current}
                    tickXLabelAngle={-30}
                    barWidth={undefined} />
            </Card>
        )
    }
}