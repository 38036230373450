import { ModelBase } from './ModelBase'

export class ClienteUtenteBaseState extends ModelBase {
    public DeliveryId: string
    public ClienteId: string
    public AccountId: string
    public Username: string
    public Nome: string
    public Cognome: string
    public Email: string
    public CodiceFiscale: string
    public Telefono: string
    public PaeseResidenza: string
    public TipoDocumento: string
    public EnteRilascioDocumento: string
    public CodiceDocumento: string
    public RilascioDocumento: Date
    public ScadenzaDocumento: Date
}