import { injectable } from 'inversify'
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService"
import { PROVIDERS_REGISTRATION } from '../Constants/IocConstants'

export interface IAppAttivitaListService extends IBaseComponentService { }

@injectable()
export class AppAttivitaListService extends BaseComponentService implements IAppAttivitaListService {
    RenderApi: string

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.APP_ATTIVITA_LIST
    }
}

export interface IAppAttivitaService extends IBaseComponentService { }

@injectable()
export class AppAttivitaService extends BaseComponentService implements IAppAttivitaService {
    RenderApi: string

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.APP_ATTIVITA
    }
}