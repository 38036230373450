import { injectable } from 'inversify'
import { PROVIDERS_REGISTRATION } from '../Constants/IocConstants'
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService"

export interface IAppInformativeListaService extends IBaseComponentService { }

@injectable()
export class AppInformativeListaService extends BaseComponentService implements IAppInformativeListaService {
    RenderApi: string

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.APP_INFORMATIVE_LIST
    }
}