import * as React from 'react'
import { Row, Col, Input } from 'antd'
import { TTClientPagedList } from './TTClientPagedList'
import { ListProps } from 'antd/lib/list'
import { PaginationConfig } from 'antd/lib/pagination'
import { SearchProps } from 'antd/lib/input'

class TTClientPagedSearchState<T> {
    dataSource: Array<T>
    searcher: string
}

export interface IPagedSearchProps {
    searchItemProp: string
    searchProps: SearchProps
}

export class TTClientPagedSearchList<T> extends React.Component<ListProps<T> & IPagedSearchProps, TTClientPagedSearchState<T>>{

    constructor(props) {
        super(props)

        this.state = {
            dataSource: [],
            searcher: null
        }
    }

    componentDidMount() {
        this.setState({ dataSource: this.props.dataSource })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dataSource != prevProps.dataSource) {
            this.onSearch(this.state.searcher)
        }
    }

    protected onChangeText = e => {
        this.setState({ searcher: e.target.value })
    };

    onSearch = value => {
        debugger
        if (value == null || value == "") {
            console.log(this.props.dataSource)
            this.setState({ dataSource: this.props.dataSource })
            return
        }
        let source = this.props.dataSource.filter(e => e[this.props.searchItemProp].toUpperCase().includes(value.toUpperCase()))
        this.setState({ dataSource: source })
    }

    render() {
        const { pagination, searchProps, searchItemProp, dataSource, ...other } = this.props
        const { pageSize } = this.props.pagination as PaginationConfig
        return (
            <div>
                <Row>
                    <Col offset={2} span={18}>
                        <Input.Search {...searchProps} onChange={this.onChangeText} onSearch={value => this.onSearch(value)} value={this.state.searcher} enterButton />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <TTClientPagedList {...other} dataSource={this.state.dataSource}
                            pagination={{
                                total: this.state.dataSource == null ? 0 : this.state.dataSource.length,
                                showTotal: (total) => `${total} elementi`,
                                pageSize: pageSize
                            }} />
                    </Col>
                </Row>
            </div>
        )
    }
}