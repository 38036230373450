import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { GruppiStateEditModel } from "../app/Contents/Gruppi/PageGruppoEdit";
import { ModelBase } from "../Models/ModelBase";
import { ClientiiByGruppoCriteriSearcher } from "../Models/ClientiiByGruppoCriteriSearcher";
import { ProjectionPagedModel } from "../Models/ProjectionPageModel";
import { ClienteProjection } from "../Models/ClienteProjection";
import { INotifyService } from "./Shared/NotifyService";
import { IApiServiceExt } from "./Shared/ApiServiceExt";

export interface IGruppoEditService extends IBaseComponentService {
    SaveGruppo(data: GruppiStateEditModel): Promise<ModelBase>;
    GetClienti(searcher: ClientiiByGruppoCriteriSearcher): Promise<ProjectionPagedModel<ClienteProjection>>
}

@injectable()
export class GruppoEditService extends BaseComponentService implements IGruppoEditService {

    RenderApi: string = Api.RenderEditGruppi;
    _apiService: IApiServiceExt;    

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);        
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.GRUPPOEDIT;
    }

    async SaveGruppo(data: GruppiStateEditModel): Promise<ModelBase> {
        const resp = await this._apiService.postAsync<any>(Api.SaveGruppo, data, "Salvataggio avvenuto correttamente")
        return resp
    }

    async GetClienti(searcher: ClientiiByGruppoCriteriSearcher): Promise<ProjectionPagedModel<ClienteProjection>> {
        
        const resp = await this._apiService.postAsync<ProjectionPagedModel<ClienteProjection>>(Api.GetGruppiClienti, searcher)
        return resp
    }
}