import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";


import { RichiesteDashboardModel, AdempimentiDashboardModel } from "../Models/DashboardModels";
import { SearcherBaseModel } from "../Models/SearcherBaseModel";
import { ProjectionPagedModel } from "../Models/ProjectionPageModel";

export interface IHomeService extends IBaseComponentService {
    searchRichieste(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<RichiesteDashboardModel>>
    getAdempimenti(): Promise<AdempimentiDashboardModel>
}

@injectable()
export class HomeService extends BaseComponentService implements IHomeService {

    RenderApi: string = '';
    _apiService: IApiService;

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.HOME;
    }

    async searchRichieste(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<RichiesteDashboardModel>> {
        var c = await this._apiService.postAsync<any>(Api.SearchDashboardRichieste, searcher)
        return c
    }

    async getAdempimenti(): Promise<AdempimentiDashboardModel> {
        var c = await this._apiService.getAsync<any>(Api.GetDashboardAdempimenti, {})
        return c
    }
}