import { ModelBase } from "./ModelBase"
import { TaskBaseItem } from './AppTaskModels'

export class CircolariList extends ModelBase
{
    Circolari: Array<CircolariItem>
}

export class CircolariItem extends TaskBaseItem
{
    ToRead: boolean
}

export class CircolareInput {
    CircolareId: string
}