import { ModelBase } from "./ModelBase"
import { ClientiProjection } from "./ClientiState"
import { StatoLicenza } from "../Constants/StatoLicenza"

export class LicenzeProjection extends ModelBase {
    public LicenzaId: string
    public NumeroClienti: number
    public Scadenza: Date
    public ClientiRimanenti: number
    public Stato: StatoLicenza
}

export class LicenzaClientiModel extends LicenzeProjection {
    public ClientiAssegnati: ClientiProjection[] 
}

export class AssegnaLicenzaClientiInput {
    public IdLicenza: string
    public IdClienti: Array<string>
}

export class BuyOutputModel extends ModelBase {
    public UrlPagamento: string
}

export class StatoLicenzaModel extends ModelBase
{
    public Descrizione: string
    public Stato: StatoLicenza
    public ProssimoRinnovo: Date
    public LicenzaId: string
}

export class CompraLicenzaClienteInput {
    ClienteId: string
    Email: string
}
