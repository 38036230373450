import { injectable } from 'inversify'
import { PROVIDERS_REGISTRATION } from '../Constants/IocConstants'
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService"

export interface IAppDashboardService extends IBaseComponentService { }

@injectable()
export class AppDashboardService extends BaseComponentService implements IAppDashboardService {
    RenderApi: string

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.APP_DASHBOARD
    }
}