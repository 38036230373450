import { injectable } from "inversify";
import * as Api from "../Constants/ApiUrlConstants";
import { AdempimentoEditService, IAdempimentoEditService } from "./AdempimentoEditService";

export interface IAdempimentoCopyService extends IAdempimentoEditService {}

@injectable()
export class AdempimentoCopyService extends AdempimentoEditService  {

    RenderApi: string = Api.Copy;

    constructor() {
        super();
    }
}