import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { IImportBaseService, ImportBaseService } from "./ImportBaseService";

export interface IImportFatturatoService extends IImportBaseService {

}

@injectable()
export class ImportFatturatoService extends ImportBaseService implements IImportFatturatoService {

    UploadUrl = Api.ImportFatturato

    constructor() {
        super()
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.IMPORTFATTURATO;
    }
}