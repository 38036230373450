import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import TTComponent from '../../Shared/BaseComponent/TTComponentTs';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.AddNotification = this.AddNotification.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.state = {
      titolo: null,
      descrizione: null
    };

  }

  onChangeText(e) {

    const target = e.target;
    const value = target.value;
    const name = target.name;
    const data = this.state;
    data[name] = value;
    this.setState(data);
  }

    AddNotification = () => {
      this.props.addNotification( {
        title: this.state.titolo,
        descrizione: this.state.descrizione,
        tipo: 1
      });
    };

    render() {
      const FormItem = Form.Item;
      return (
        <div>
          <Form layout="inline">
            <FormItem>
              <Input name="titolo" placeholder="Titolo" value={this.state.titolo} onChange={this.onChangeText} />
            </FormItem>
            <FormItem>
              <Input name="descrizione" placeholder="Descrizione" value={this.state.descrizione} onChange={this.onChangeText} />
            </FormItem>
            <Button type="primary" onClick={this.AddNotification}>Notifica</Button>
          </Form>
        </div>
      );
    }
}

export default withRouter(TTComponent(Notification, 'Notification'));
