import { ActionCreator, Action } from 'redux';
import { ToggleBoxedLayoutAction, ChangeUserAction, RefreshToolbarAction, ErrorShowAction, ErrorRemoveAction, HeadRefreshAction, EnableButtonToolbarAction, RenderButtonToolbarAction } from '../Actions/ActionTypesTs'
import { IError } from "../../Models/ErrorStoreModel";
import { IHeader } from '../../Reducers/HeaderTs';
import { ToolbarProxy } from '../../../Services/ToolbarService/ToolbarServiceTs';

export const ToggleBoxedLayout: ActionCreator<ToggleBoxedLayoutAction> = (boxedLayout: boolean) => ({
    type: 'TOGGLE_BOXED_LAYOUT',
    payload: {
        boxedLayout,
    },
});

export const ChangeUser: ActionCreator<ChangeUserAction> = (name: string) => ({
    type: 'CHANGE_USER',
    payload: {
        name,
    },
});

export const RefreshToolbar: ActionCreator<RefreshToolbarAction> = (data: ToolbarProxy) => ({
    type: 'REFRESH_TOOLBAR',
    payload: {
        data,
    },
});

export const EnableButtonToolbar: ActionCreator<EnableButtonToolbarAction> = (provider: string, key: string, enabled: boolean) => ({
    type: 'ENABLEBUTTON_TOOLBAR',
    payload: {
        provider, key, enabled
    },
});

export const RenderButtonToolbar: ActionCreator<RenderButtonToolbarAction> = (provider: string, key: string, visible: boolean) => ({
    type: 'RENDERBUTTON_TOOLBAR',
    payload: {
        provider, key, visible
    },
});

export const HeadRefresh: ActionCreator<HeadRefreshAction> = (model: IHeader) => ({
    type: 'REFRESH_HEAD',
    payload: model
}
);

// Non pi� usati
//export const ErrorShow: ActionCreator<ErrorShowAction> = (message: IError) => ({
//    type: 'ERROR_SHOW',
//    payload: message
//});
//export const ErrorRemove: ActionCreator<ErrorRemoveAction> = () => ({
//    type: 'ERROR_REMOVE'
//});
