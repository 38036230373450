import { ModelBase } from './ModelBase'


export class RichiesteDashboardModel extends ModelBase {
    public Richiesta: string
    public RagioneSociale: string
    public Adempimento: string
    public Attivita: string
    public DataRichiesta: Date
}

export class AdempimentiDashboardRow {
    public x: string
    public y: number
    public Descrizione: string
}

export class AdempimentiDashboardModel extends ModelBase
{
    public ToDo: Array<AdempimentiDashboardRow>
    public InProgress: Array<AdempimentiDashboardRow>
    public Done: Array<AdempimentiDashboardRow>
}
