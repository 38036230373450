import * as React from 'react';
import { Card, List, Input, Progress, Row, Col } from 'antd';
const Search = Input.Search;

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { IClienteService } from '../../../Services/ClienteService';

import { AdempimentoCustomersState } from './../../../Models/AdempimentoCustomersState';
import { GetClientiByAdempimentoSearcher } from '../../../Models/GetClientiByGruppoSearcher';
import { TTAvatar } from '../TTAvatar';
import { TTList } from '../TTList';

import * as Constants from './../../../Constants/AppConstants'

export interface IAdempimentoCustomersProps { id:string}

export class AdempimentoCustomers extends React.Component<any & IAdempimentoCustomersProps, AdempimentoCustomersState> {

    private _service: IClienteService
    private RigheLista = Constants.ListSize.AdempimentoClienti;

    constructor(props) {
        super(props);

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE);

        this.state =  new AdempimentoCustomersState();
    }

    componentDidMount() {
        this.Search(1);
    }

    Search = (page: number) => {

        if (this.props.id == null) return;

        let searcher: GetClientiByAdempimentoSearcher = {
            IdAdempimento: this.props.id,
            Righe: this.RigheLista,
            Pagina: page
        }

        if (this.state.FullText != null && this.state.FullText != "") {

            searcher.FullText = this.state.FullText
        }

        this._service.GetClientiByAdempimento(searcher).then(resp => this.setState({ ...this.state, Data: resp, Page: page }))
    }
    onSearchTextChange = e => {
        this.setState({ FullText: e.target.value })
    }

    onSearch = value => {
        this.Search(1);
    }

    onPageChange = page => {
        this.Search(page);
    }

    itemClick = (idcliente: string) => {
        this.props.history.push('/AdempimentoCustomer/' + idcliente + '/' + this.props.id);
    }

    render() {
        return (
            <Card bordered={false} title="Clienti" className="last-card">
                <Row>
                    <Col offset={1} span={22} >
                        <Search placeholder="Cerca cliente" onChange={this.onSearchTextChange} onSearch={this.onSearch} enterButton value={this.state.FullText} />
                    </Col>
                </Row>
                <br />
                <TTList
                    itemLayout="horizontal"
                    dataSource={this.state.Data.Data}
                    pagination={{
                        onChange: (page) => this.onPageChange(page),
                        pageSize: this.RigheLista,
                        total: this.state.Data.Total,
                        showTotal: (total) => `${total} elementi`,
                        current: this.state.Page
                    }}

                    renderItem={item => (
                        <List.Item
                            key={item.Id}
                            actions={[<Progress type="circle" percent={item.Progresso} width={45} />]}>
                            <List.Item.Meta
                                avatar={<TTAvatar tticon="user" />}
                                title={<a onClick={() => this.itemClick(item.Id)}>{item.Descrizione}</a>}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        );
    }
}