import * as React from 'react'
import { Col, Row, Card, Form, Input, Select } from 'antd';
import { withRouter } from 'react-router-dom';

import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { RouteComponentProps } from 'react-router';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import { ICircolareEditService } from '../../../Services/CircolareEditService';
import IoCContainer from '../../../Wires/Bootstrapper';
import { FormComponentProps } from 'antd/lib/form';

import { ListaCustomers } from '../Shared/ListaCustomers';
import { UploadFile } from 'antd/lib/upload/interface';
import { SingleFileUpload } from '../SingleFileUpload';
import { BaseFormReactComponent } from '../BaseReactComponent';
import { TTSelect } from '../TTSelect';

const Option = Select.Option
const TextArea = Input.TextArea

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        lg: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        lg: { span: 14 },
    }
}

export class CircolareData {
    public Id: string
    public Titolo: string
    public Descrizione: string
    public Gruppi: Array<GruppoModel>
    public GruppiSource: Array<GruppoModel>
    public AllegatiList: Array<UploadFile>
    public AllegatiCarica: Array<File>
}

export class GruppoModel {
    public Id: string
    public Titolo: string
    public Criterio: string
    public Descrizione: string
}

export class PageCircolareState extends CircolareData {
    EditMode: boolean
}

const ComponentService: symbol = SERVICE_IDENTIFIER.CIRCOLARE_EDIT_SERVICE;

interface CircolariRoute {
    id: string
}

export class PageCircolareEdit extends BaseFormReactComponent<IStoreModel & RouteComponentProps<CircolariRoute> & ITTProps & FormComponentProps, PageCircolareState> {

    private _service: ICircolareEditService

    constructor(props) {
        super(props);
        this.state = this.props.componentData || new PageCircolareState();

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CIRCOLARE_EDIT_SERVICE);
        this._service.getToolbar().addBackClick(() => this.props.history.push("/Circolari"));
        this._service.getToolbar().addButtonClick("Salva", () => this.saveCircolare())
    }

    componentDidMount() {
        const { match } = this.props;

        if (match.params.id != null) {
            this.setState({ EditMode: true })
        }
    }

    saveCircolare = () => {
        this.validateAndSaveExtra(() => this._service.SaveCircolare(this.state),
            (resp => {
                if (resp.IsValid) {
                    this.setState({ EditMode: true })
                }
            })
        )
    }

    onChangeGroups = (selectedGroups) => {
        var groups = [];
        selectedGroups.map(h => groups.push({ Id: h }));

        this.setState({Gruppi: groups})
    }

    handleSelectedItem = (file: File) => {
        if (file == null) {
            this.setState({ AllegatiCarica: null });
            return
        }

        this.setState({ AllegatiCarica: [file] });
    }

    render() {

        const { GruppiSource, Gruppi } = this.state

        const options = GruppiSource.map(item => <Option key={item.Id}>{item.Titolo}</Option>)
        const selectedGroups = Gruppi.map(item => item.Id)

        var file
        if (this.state.AllegatiCarica == null) {
            file = null
        } else {
            file = this.state.AllegatiCarica[0]
        }

        return (
            <Row gutter={24}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>

                    <Card bordered={false} title="Modifica Circolare">
                        <Form>
                            <Form.Item {...formItemLayout} label="Titolo">
                                {this.getRequiredField(<Input name="Titolo" size="small" onChange={this.onChangeText} />)}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Descrizione">
                                {this.getRequiredField(<TextArea name="Descrizione" autosize={{ minRows: 5 }} onChange={this.onChangeText} />)}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Gruppi">
                                {
                                    this.getRequiredFieldFull("Gruppi", selectedGroups,
                                        <TTSelect
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Seleziona Gruppi"
                                            onChange={x => this.onChangeGroups(x)} >
                                            {options}
                                        </TTSelect>
                                    )
                                }
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Allegato">
                                <SingleFileUpload AllegatiList={this.state.AllegatiList}
                                    SelectedItem={this.handleSelectedItem}
                                    disabled={this.state.EditMode}
                                    file={file} />
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Card bordered={false} title="Clienti Inclusi" className="last-card">
                        <ListaCustomers groups={selectedGroups} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export const CircolarePEdit = Form.create()(PageCircolareEdit)
const CircolareEdit = withRouter(TTComponent(CircolarePEdit, 'Modifica Circolare', ComponentService));
export default CircolareEdit;