import * as React from "react"
import { IMenuModel } from '../../../Services/ToolbarService/IMenuItems';
import { IMenuService } from "../../../Services/MenuService/MenuServiceTs";
import { Menu,Icon } from 'antd';
import {Link } from 'react-router-dom';
import container from "../../../Wires/Bootstrapper";
import SERVICE_IDENTIFIER from "../../../Wires/Identifiers";
import { TTIcon } from "../../../app/Contents/TTIcon";

const MenuItem = (data) => {
    const {id, link, description, icon} = data;
    const returnValue = [];
  
    
    if (data.link != null) {
        let text = <span className="nav-text">{description}</span>
        let line = <Link to={link}>{text}</Link>
        if (icon != null) {
            line = <Link to={link}><TTIcon icon={icon} />{text}</Link>
        }

        returnValue.push (
            <Menu.Item key={id}>
                {line}
            </Menu.Item>
        );
    }
  
    if (data.groupName != null && data.groupName !== '') returnValue.push(SubMenu(data));

    return returnValue;
  
  };

const SubMenu = (data) => {
    const {groupName, menu, icon} = data;
    return (
        <Menu.SubMenu title={<span><TTIcon icon={icon} /><span className="nav-text">{groupName}</span></span>} >
            {menu.map(MenuItem)}
        </Menu.SubMenu>
    );
};

export default class AppMenu extends React.Component<{},IMenuModel> {

    service: IMenuService
    constructor(props) {
        super(props);
        this.service = container.get(SERVICE_IDENTIFIER.MENU_SERVICE);
        this.state = { menu: [] };
    }
  
  
    async componentDidMount() {
        let resp = await this.service.getMenu();
        this.setState(resp as IMenuModel);
    }

    getSelectedItems = () => {
        var arr = []
        if (this.state.menu != null && this.state.menu.length > 0) {
            arr.push(this.state.menu[0].id)
        }

        return arr;
    }
  
    render() {
        var menu = this.state.menu.map(MenuItem)
        return (
            <Menu mode="inline" defaultSelectedKeys={this.getSelectedItems()} theme="dark">
                {menu}
            </Menu>
        );
    }
  }