import * as React from 'react'
import { List, Pagination, Row, Col } from 'antd'
import { ListProps } from 'antd/lib/list';
import { PaginationConfig } from 'antd/lib/pagination';

class TTPagedListState<T> {
    public page: Array<T>
    public currentPage: number
}

// Per poter fissare l'altezza della lista paginata lato client con pagination a fondo pagina
export class TTClientPagedList<T> extends React.Component<ListProps<T>, TTPagedListState<T>> {

    constructor(props) {
        super(props)

        this.state = {
            page: [],
            currentPage: 1
        }
    }

    componentDidMount() {
        if (this.props.dataSource != null) {
            this.page(this.props.dataSource, 1)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dataSource != prevProps.dataSource) {
            this.page(this.props.dataSource, 1)
        }
    }

    page = (list: Array<T>, currentPage: number) => {
        const { pageSize } = this.props.pagination as PaginationConfig
        if (list.length > (currentPage - 1) * pageSize) {
            let splitDataSource = [...list].splice(
                (currentPage - 1) * pageSize,
                pageSize,
            );

            this.setState({ page: splitDataSource, currentPage });
        } else {
            this.setState({ page: [], currentPage });
        }
    }

    onPageChange = (page, pageSize) => {
        this.page(this.props.dataSource, page)
    }

    render() {
        const { pagination, dataSource, ...other } = this.props

        return (
            <div>
                <Row>
                    <Col>
                        <List {...other} dataSource={this.state.page} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col style={{ textAlign: 'right' }}>
                        <Pagination {...pagination}
                            size="small"
                            current={this.state.currentPage}
                            onChange={this.onPageChange} />
                    </Col>
                </Row>
            </div>
        )
    }
}