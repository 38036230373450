import * as React from 'react';
import { Row, Col, List, Input, Button } from 'antd';
import { withRouter, RouteComponentProps } from "react-router";
import { TTComponentBoxed } from "../../../Shared/BaseComponent/TTComponentTs";
import { FormComponentProps } from 'antd/lib/form';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { GruppoState } from '../../../Models/GruppoState'
import { GruppoProjection } from '../../../Models/GruppoProjection'
import { ProjectionPagedModel } from '../../../Models/ProjectionPageModel';
import { SearcherBaseModel } from '../../../Models/SearcherBaseModel';
import { BaseReactComponent } from '../BaseReactComponent';
import { TTAvatar } from '../TTAvatar';
import { TTList } from '../TTList';
import { TTIconButton } from '../TTIconButton';
import * as Constants from './../../../Constants/AppConstants'

const Search = Input.Search;
const ComponentService: symbol = SERVICE_IDENTIFIER.GRUPPO_SERVICE;

export class PageGruppi extends BaseReactComponent<IStoreModel & RouteComponentProps & ITTProps & FormComponentProps, GruppoState,GruppoProjection> {
    protected getServiceIdentifier(): symbol {
        return SERVICE_IDENTIFIER.GRUPPO_SERVICE;
    }

    //private _service: IGruppoService;
    private RigheLista = Constants.ListSizePage;

    constructor(props) {
        super(props);

        this.state = this.props.componentData || new GruppoState();

        //this._service = IoCContainer.get(SERVICE_IDENTIFIER.GRUPPO_SERVICE);
        this.service.getToolbar().addButtonClick("NuovoGruppo", this.addGroup);
    }

    addGroup = () => {
        this.props.history.push('/GruppoEdit')
    }

    onSearch = text => {
        this.setState({ ...this.state, Searcher:text });
        this.DoSearch(this.state.Page);
    }

    onGruppoClick = (item: GruppoProjection) => {
        console.log(item);
        this.props.history.push('GruppoEdit/' + item.Id);
    }

    onPageChange = page => {
        this.DoSearch(page);
    }

    private DoSearch(page: any) {        
        const searcher: SearcherBaseModel = {
            Righe: this.RigheLista,
            Pagina: page,
            FullText: this.state.Searcher
        };
        
        this.service.Search(searcher).then(resp => this.setState({ ...this.state, Data: resp, Page: page }));
    }


    render() {
      
        let list: ProjectionPagedModel<GruppoProjection> = this.state.Data;

        return (
            <div>
                <Row>
                    <Col offset={1} span={22} >
                        <Search placeholder="Cerca gruppo"
                            onSearch={value => this.onSearch(value)}
                            onChange={this.onChangeText}
                            name="Searcher"
                            value={this.state.Searcher}
                            enterButton />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <TTList
                            itemLayout="horizontal"
                            dataSource={list.Data}
                            pagination={{
                                onChange: (page) => this.onPageChange(page),
                                pageSize: this.RigheLista,
                                total: list.Total,
                                showTotal: (total) => `${total} elementi`,
                                current: this.state.Page
                            }}
                            renderItem={item => (
                                <List.Item
                                    actions={[<TTIconButton tticon="modifica" onClick={() => this.onGruppoClick(item)} />]}
                                    key={item.Id}>
                                    <List.Item.Meta
                                        avatar={<TTAvatar tticon="clienti" />}
                                        title={item.Titolo}
                                        description={item.Descrizione}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const Gruppi = withRouter(TTComponentBoxed(PageGruppi, 'Lista Gruppi', ComponentService));
export default Gruppi;