import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'
import container from '../../../Wires/Bootstrapper'

import { TTComponentBoxed } from '../../../Shared/BaseComponent/TTComponentTs'
import { IAppTaskService } from '../../../Services/AppTaskService'

import { IAppAttivitaListService } from '../../../Services/AppAttivitaService'
import { TaskListComponent, FiltroAttivita } from './../TaskListComponent'
import { ActivityItem } from '../../../Models/AppActivityModels'


class PageAttivitaListState {
    List: Array<ActivityItem>
    Filtro: FiltroAttivita
}

export class PageAttivitaListComp extends React.Component<RouteComponentProps, PageAttivitaListState> {

    service: IAppTaskService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.APP_TASK_SERVICE)
        this.state = new PageAttivitaListState()

        let toolbar = container.get<IAppAttivitaListService>(SERVICE_IDENTIFIER.APP_ATTIVITA_LISTA_SERVICE).getToolbar()
        toolbar.addBackClick(() => this.props.history.push("/"))
    }

    componentDidMount() {
        this.loadAttivita(FiltroAttivita.DaCompletare)
    }

    loadAttivita = async (filtro: FiltroAttivita) => {
        this.setState({ Filtro: filtro })
        let resp = await this.service.GetActivities(filtro == FiltroAttivita.DaCompletare)
        if (resp.IsValid)
            this.setState({ List: resp.Activities })
    }

    onFilterChange = (filtro: FiltroAttivita) => {
        this.loadAttivita(filtro)
    }

    render() {
        return (
            <TaskListComponent
                formLabel="Visualizza attività"
                list={this.state.List}
                filter={this.state.Filtro}
                onFilterChange={e => this.onFilterChange(e)} />
        )
    }
}
export const PageAttivitaList = withRouter(TTComponentBoxed(PageAttivitaListComp, 'Attivita', SERVICE_IDENTIFIER.APP_ATTIVITA_LISTA_SERVICE))