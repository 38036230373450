import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { ModelBase } from "../Models/ModelBase";

export interface ISupportService extends IBaseComponentService {
    InviaRichiesta(message: string): Promise<ModelBase>
}

@injectable()
export class SupportService extends BaseComponentService implements ISupportService {

    RenderApi: string = Api.RenderSupporto;
    apiService: IApiService;

    constructor() {
        super();
        this.apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.SUPPORTO;
    }

    async InviaRichiesta(message: string): Promise<ModelBase> {
        return this.apiService.postAsync<ModelBase>(Api.InviaRichiestaSupporto, { Richiesta: message });
    }
}