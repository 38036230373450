import * as React from 'react'
import { List, Card, Icon, Button, Row, Col, Empty } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'
import moment from 'moment'

import IoCContainer from '../../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../Wires/Identifiers'

import { TTAvatar } from './TTAvatar'

import { IDrawerService } from '../../Services/DrawerService'
import { BackofficeResponseModel } from '../../Models/SignalRResponseModel'
import { ISignalRServiceHandler } from '../../Services/Shared/SignalRServiceHandler';
import { DispatcherContext } from '../../Services/Shared/Dispatcher-Context'

export class RichiesteDrawerState {
    Data: Array<BackofficeResponseModel>
}

export class RichiesteDrawerComp extends React.Component<RouteComponentProps, RichiesteDrawerState> {

    static contextType = DispatcherContext
    context: React.ContextType<typeof DispatcherContext>

    private service: IDrawerService
    private signalRService: ISignalRServiceHandler;

    constructor(props) {
        super(props)
        this.service = IoCContainer.get(SERVICE_IDENTIFIER.DRAWER_SERVICE);
        this.signalRService = IoCContainer.get(SERVICE_IDENTIFIER.SIGNALR);

        this.state = new RichiesteDrawerState();
    }


    onResponseRecieved = (data: BackofficeResponseModel) => {
        let array = this.state.Data
        array.unshift(data)
        this.setState({ Data: array })
    }

    componentDidMount() {
        this.service.GetRisposte().then(resp => this.setState({ Data: resp }))

        this.signalRService.AddEventHandlerFor('ResponseRecieved', this.onResponseRecieved);
    }

    componentWillUnmount() {
        this.signalRService.RemoveEventHandlerFor('ResponseRecieved', this.onResponseRecieved);
    }

    closeClick = (id: string) => {
        this.service.CancellaRisposte(id).then(resp => {
            if (resp.IsValid) {
                let arr = this.state.Data
                let idx = arr.findIndex(it => it.MessageId == id)
                arr.splice(idx, 1)

                this.setState({ Data: arr })
            }
        })
    }

    closeAllClick = () => {
        this.service.CancellaRisposte(null).then(resp => {
            if (resp.IsValid) {
                this.setState({ Data: [] })
            }
        })
    }

    itemClicked = (item: BackofficeResponseModel) => {
        this.context.ShowDrawer(false)
        this.props.history.push('/AdempimentoCustomer/' + item.IdCliente + '/' + item.IdAdempimento);
    }

    getCardLayout = (item: BackofficeResponseModel) => {
        return (
            <List.Item className="card-item" key={item.Id}>
                <Card style={{ width: '100%', cursor: 'pointer' }} bordered={false} >
                    <Row>
                        <Col onClick={() => this.itemClicked(item)} span={5}><TTAvatar tticon="notifica" /></Col>
                        <Col onClick={() => this.itemClicked(item)} span={18}>
                            <Row>
                                <Col><h5>{item.Titolo}</h5></Col>
                            </Row>
                            <Row>
                                <Col>{item.Descrizione}</Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>{moment(item.MessageDate).format('lll')}</Col>
                            </Row>
                        </Col>
                        <Col span={1} style={{ textAlign: 'right' }}>
                            <Icon type="close" onClick={(e) => this.closeClick(item.MessageId) } />
                        </Col>
                    </Row>
                </Card>
            </List.Item>
        )
    }

    render() {
        let list: Array<BackofficeResponseModel> = this.state.Data;
        return (
            <div>
                <Row type="flex" align="bottom" justify="space-between">
                    <Col span={14}>
                        <h4>Richieste completate</h4>
                    </Col>
                    <Col span={10} style={{ textAlign: 'right' }}>
                        <Button style={{ marginRight: '0px' }} onClick={this.closeAllClick}>Cancella</Button>
                    </Col>
                </Row>
                
                <List itemLayout="horizontal"
                    dataSource={list}
                    split={false}
                    renderItem={this.getCardLayout} />
                
            </div>
        )
    }
}

export const RichiesteDrawer = withRouter(RichiesteDrawerComp)