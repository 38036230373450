import * as React from 'react';
import { Card, List } from 'antd';

import { IAdempimentoActivities } from './../../../Models/IAdempimentoActivities';

export function AdempimentoActivities (props: IAdempimentoActivities) {

    return (
        <Card bordered={false} className="card-full-height" title="Attività">
            <List
                itemLayout="horizontal"
                dataSource={props.activities}
                renderItem={item => (
                    <List.Item key={item.Id} >
                        <List.Item.Meta
                            title={item.Titolo}
                            description={item.Descrizione} />
                    </List.Item>
                )} />
        </Card>
    );
}