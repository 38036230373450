import * as React from 'react';
import { Form, Input } from 'antd';
import { BaseFormReactComponent } from '../BaseReactComponent';
import TextArea from 'antd/lib/input/TextArea';
import { INotifyService } from '../../../Services/Shared/NotifyService';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { FormComponentProps } from 'antd/lib/form/Form';
import { IModalProps } from '../TTModal';
import { AdempimentoActivity } from '../../../Models/IAdempimentoActivities';
import Modal, { ModalProps } from 'antd/lib/modal';

export interface IEditAttivitaStateModel {
    selectedActivity: AdempimentoActivity;
}

class NuovaAttivitaForm extends BaseFormReactComponent<IModalProps & ModalProps & IEditAttivitaStateModel & FormComponentProps, AdempimentoActivity>
{
    notifyService: INotifyService;

    constructor(props) {
        super(props);
        this.notifyService = IoCContainer.get(SERVICE_IDENTIFIER.NOTIFY);
        this.state = this.props.selectedActivity || new AdempimentoActivity();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedActivity != prevProps.selectedActivity)
        {
            this.setState(this.props.selectedActivity);
        }
    }

    closeEdit = () => {
        this.props.form.resetFields()
        this.props.closeModal()
    }

    onCreate = () => {
        this.props.form.validateFields((error, values) => {
            if (error == null) {
                this.props.do(this.state);
                this.closeEdit()
            }
        });
    }

    render() {
        return (
            <Modal visible={this.props.visible} title="Crea nuova Attività" onCancel={this.closeEdit} onOk={() => this.onCreate()} okText="Crea">
                <Form>
                    <Form.Item label="Titolo">
                        {this.getRequiredField(<Input size="small" onChange={this.onChangeText} name="Titolo" />)}
                    </Form.Item>
                    <Form.Item label="Descrizione">
                        {this.getRequiredField(<TextArea autosize={{ minRows: 5 }} onChange={this.onChangeText} name="Descrizione" />)}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export const NuovaAttivita = Form.create<IModalProps & ModalProps & IEditAttivitaStateModel & FormComponentProps>({ name: 'form_in_modal' })(NuovaAttivitaForm);
