import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { IFormDataConverter } from "./Shared/FormDataConverter";
import { IApiServiceExt } from "./Shared/ApiServiceExt";
import { ModelBase } from "../Models/ModelBase";
import { TenantData } from "../app/Contents/Super/PageTenantEdit";

export interface ITenantEditService extends IBaseComponentService {
    SaveTenant(data: TenantData): Promise<ModelBase>
}

@injectable()
export class TenantEditService extends BaseComponentService implements ITenantEditService {
    async SaveTenant(data: TenantData): Promise<ModelBase> {
        return await this._apiService.postAsync<any>(Api.SaveTenant, this._formDataConverter.convert(data), "Salvataggio avvenuto correttamente")
    }

    RenderApi: string = Api.RenderEditTenant;
    _apiService: IApiServiceExt;
    _formDataConverter: IFormDataConverter

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
        this._formDataConverter = IoCContainer.get(SERVICE_IDENTIFIER.FORMDATA_CONVERTER);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.TENANTEDIT;
    }

}