import * as React from 'react'
import { Upload } from 'antd';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { TTUploadLayout } from './TTUploadLayout';

export interface ISingleUploadProps {
    AllegatiList?: Array<UploadFile>
    SelectedItem: (item: File) => void
    showRemoveIcon?: boolean
    file: File
}

export class SingleFileUploadState {
    public AllegatiCarica: Array<UploadFile>
    public UploadingFile: boolean
}

export class SingleFileUpload extends React.Component<ISingleUploadProps & UploadProps, SingleFileUploadState> {
    constructor(props) {
        super(props)

        this.state = {
            AllegatiCarica: [],
            UploadingFile: false
        }
    }

    static defaultProps = {
        AllegatiList: [],
        disableRemove: false
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.file != this.props.file && this.props.file == null) {
            this.setState({ AllegatiCarica: [] })
        }
    }

    onRemoveFile = file => {
        const index = this.state.AllegatiCarica.indexOf(file);
        const newFileList = this.state.AllegatiCarica.slice();
        newFileList.splice(index, 1);

        this.setState({ AllegatiCarica: newFileList });
        this.props.SelectedItem(null)
    }

    onBeforeUpload = (file) => {
        if (this.state.AllegatiCarica.length > 0) {
            //message.warning('Non è possibile caricare più elementi. Il file ' + file.name + ' verrà ignorato');
            return;
        }

        var attach = this.state.AllegatiCarica
        attach.push(file)

        this.setState({ AllegatiCarica: attach });
        this.props.SelectedItem(file)

        return false;
    }

    render() {
        const { AllegatiList, showRemoveIcon, ...other } = this.props
        const { AllegatiCarica } = this.state

        var showUploadList = {}
        if (showRemoveIcon == false) {
            showUploadList = { showRemoveIcon: false }
        }

        // Per mostrare una lista di file già caricati (defaultFileList)
        // fileList non ci deve essere
        var upProps
        if (AllegatiList.length > 0) {
            upProps = {
                defaultFileList: AllegatiList
            }
        } else {
            upProps = {
                fileList: AllegatiCarica 
            }
        }

        return (
            <Upload.Dragger
                onRemove={file => this.onRemoveFile(file)}
                beforeUpload={file => this.onBeforeUpload(file)}
                showUploadList={showUploadList}
                {...upProps} {...other}>
                <TTUploadLayout maxFiles={1} />
            </Upload.Dragger>
        )
    }
}