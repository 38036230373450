import * as types from '../Constants/ActionTypes';

export function toggleBoxedLayout(isBoxedLayout) {
  return { type: types.TOGGLE_BOXED_LAYOUT, isBoxedLayout };
}
export function toggleCollapsedNav(isCollapsedNav) {
  return { type: types.TOGGLE_COLLAPSED_NAV, isCollapsedNav };
}
export function toggleOffCanvasNav(isOffCanvasNav) {
  return { type: types.TOGGLE_OFFCANVAS_NAV, isOffCanvasNav };
}
export function toggleFixedHeader(isFixedHeader) {
  return { type: types.TOGGLE_FIXED_HEADER, isFixedHeader };
}
export function changeSidebarWidth(sidebarWidth) {
  return { type: types.CHANGE_SIDEBAR_WIDTH, sidebarWidth };
}
export function changeColorOption(colorOption) {
  return { type: types.CHANGE_COLOR_OPTION, colorOption };
}
export function changeTheme(themeOption) {
  return { type: types.CHANGE_THEME, theme: themeOption };
}

// User
export function changeUser(name) {
  return { type: types.CHANGE_USER, name };
}
export function addNotification(notification) {
  return { type: types.ADD_NOTIFICATION, notification };
}

// Auth
export function setLogged(isLogged) {
  return { type: types.CHANGE_LOGGED, isLogged };
}
