import * as React from 'react'
import { Form, Input, Modal } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { IModalProps } from '../TTModal'
import { FilesystemVirtualeStateEditModel } from '../../../Models/FilesystemVirtualeModels'

class ModalRenameFileState {
    Name: string = ""
}

export interface IRenameForm extends IModalProps {
    visible: boolean
    currentFile: FilesystemVirtualeStateEditModel
}

class RenameFileForm extends React.Component<FormComponentProps & IRenameForm, ModalRenameFileState> {
    constructor(props) {
        super(props)

        this.state = new ModalRenameFileState()
    }

    componentDidUpdate(prevProps: IRenameForm, prevState: ModalRenameFileState) {
        if (this.props.currentFile != null && this.props.currentFile != prevProps.currentFile) {
            this.setState({ Name: this.props.currentFile.Code })
        }
    }

    closeModal = () => {
        this.props.form.resetFields()
        this.props.closeModal()
    }

    onCreate = () => {
        this.props.form.validateFields((error, values) => {
            if (error == null) {
                this.props.do(values['Nome'])
                this.closeModal()
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal title="Rinomina file"
                visible={this.props.visible}
                onCancel={this.closeModal}
                onOk={this.onCreate}>
                <Form>
                    <Form.Item label="Nome">
                        {
                            getFieldDecorator('Nome', {
                                initialValue: this.state.Name,
                                rules: [
                                    {
                                        required: true,
                                        message: `Campo obbligatorio`,
                                    }
                                ]
                            })(<Input size="small" />)
                        }
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export const ModalRenameFile = Form.create<FormComponentProps & IRenameForm>({ name: "form" })(RenameFileForm)