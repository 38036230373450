import * as React from 'react'
import { List, Spin, Card, PageHeader, Button, Icon } from 'antd'
import moment from 'moment'
import { withRouter, RouteComponentProps } from 'react-router'

import container from '../../../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'

import { IToolbarService } from '../../../Services/ToolbarService/ToolbarServiceTs'
import { IAppAttivitaService } from '../../../Services/AppAttivitaService'
import { IAppTaskService } from '../../../Services/AppTaskService'
import { RouteParamId } from './../RouteParamId'

import { ActivityItem, ActivityAction, ActivityActionType } from '../../../Models/AppActivityModels'

import { TTComponent } from '../../../Shared/BaseComponent/TTComponentTs'
import { TTIcon } from '../../Contents/TTIcon'
import { TTModal } from '../../Contents/TTModal'
import { IFSVirtualeService } from '../../../Services/FSVirtualeService'

class PageAttivitaState extends ActivityItem {
    IsLoading: boolean = false
}

export class PageAttivitaComp extends React.Component<RouteComponentProps<RouteParamId>, PageAttivitaState> {

    service: IAppTaskService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.APP_TASK_SERVICE)
        this.state = new PageAttivitaState()

        let toolbar = container.get<IAppAttivitaService>(SERVICE_IDENTIFIER.APP_ATTIVITA_SERVICE).getToolbar()
        toolbar.addBackClick(() => this.props.history.goBack())
    }

    postFeedback = (data: ActivityAction) => {
        let id = this.props.match.params.id

        TTModal.confirm({
            title: 'Attenzione',
            content: 'Confermare la risposta?',
            okText: 'Ok',
            cancelText: 'Annulla',
            onOk: async () => {
                let resp = await this.service.PostActivityUserFeedback(id, data)
                if (resp.IsValid)
                    this.loadPage()
            }
        })
    }

    componentDidMount() {
        this.loadPage()
    }

    loadPage = async () => {
        this.setState({ IsLoading: true })

        let id = this.props.match.params.id
        let resp = await this.service.GetActivity(id)
        if (resp.IsValid) {
            this.setState(resp)
        }

        this.setState({ IsLoading: false })
    }

    setupButtons = () => {
        // Se l'attività non è in "da fare", non mostrare i pulsanti
        if (this.state.Todo == false) return []

        // Visualizza il pulsante in base al tipo di attività
        switch (this.state.Action) {
            case ActivityActionType.ReadOnly:
                return [
                    <Button key="read" type="primary" icon="check" onClick={() => this.postFeedback(ActivityAction.Read)}>Letto</Button>
                ]
            case ActivityActionType.YesNo:
                return [
                    <Button key="yes" type="primary" icon="check" onClick={() => this.postFeedback(ActivityAction.Yes)}>Sì</Button>,
                    <Button key="no" type="primary" icon="stop" onClick={() => this.postFeedback(ActivityAction.No)}>No</Button>
                ]
            case ActivityActionType.Sign:
            case ActivityActionType.Done:
                return [
                    <Button key="done" type="primary" icon="check" onClick={() => this.postFeedback(ActivityAction.Done)}>Fatto</Button>
                ]
            default:
                return []
        }
    }

    onSignClick = () => {
        window.open(this.state.SignUrl)
    }

    onFileClick = () => {
        let fsService = container.get<IFSVirtualeService>(SERVICE_IDENTIFIER.FSVIRTUALE_SERVICE)
        fsService.GetFile(this.state.ResourceId);
    }

    onFolderClick = () => {
        this.props.history.push(`/Documenti/${this.state.ResourceId}`)
    }

    renderAttachment = (icon: string, callback: () => void) => {
        return <>
            <Card bordered={false} style={{ padding: "12px 24px 12px 24px" }}>
                <List.Item.Meta
                    avatar={<TTIcon icon={icon} style={{ fontSize: 40 }} ttstyle="accent" />}
                    title={<a onClick={callback}>{this.state.ResourceName}</a>}
                    description="Clicca sul nome per visualizzare" />
            </Card>
        </>
    }

    render() {
        let attachment = <></>
        if (this.state.ResourceId != null)
            if (this.state.TipoRecord == "Directory")
                attachment = this.renderAttachment("cartella", () => this.onFolderClick())
            else
                attachment = this.renderAttachment("documento", () => this.onFileClick())

        return (
            <Spin spinning={this.state.IsLoading}>
                <Card bordered={false}>
                    <PageHeader title={<h2>{this.state.Title}</h2>} extra={this.setupButtons()}>
                        <p>{this.state.Description}</p>
                        <br />
                        <br />
                        <List.Item.Meta
                            avatar={<TTIcon icon="scadenze" style={{ fontSize: 40 }} ttstyle="accent" />}
                            title={moment(this.state.Date).format('LL')}
                            description="Data creazione" />
                    </PageHeader>
                </Card>
                <br />
                {attachment}
            </Spin>
        )
    }
}
export const PageAttivita = withRouter(TTComponent(PageAttivitaComp, 'Visualizzazione', SERVICE_IDENTIFIER.APP_ATTIVITA_SERVICE))