import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router';


import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { TTComponent } from '../../../Shared/BaseComponent/TTComponentTs';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IClienteUtenteEditService } from '../../../Services/ClienteUtenteEditService';
import IoCContainer from '../../../Wires/Bootstrapper';
import { message, Form, Input, Select, DatePicker, Card } from 'antd';
import { BaseFormReactComponent } from '../BaseReactComponent';
import FormItem from 'antd/lib/form/FormItem';
import { ClienteUtenteBaseState } from '../../../Models/ClienteUtenteBaseState';
import moment from 'moment'
import { TTSelect } from '../TTSelect';

const ComponentService: symbol = SERVICE_IDENTIFIER.CLIENTE_UTENTE_EDIT_SERVICE;

export class UtenteStateModel extends ClienteUtenteBaseState {
    EditMode: boolean
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    }
}

export class PageClienteUtenteEdit extends BaseFormReactComponent<any & IStoreModel & RouteComponentProps & ITTProps, UtenteStateModel> {

    private _service: IClienteUtenteEditService

    constructor(props) {
        super(props)
        this.state = this.props.componentData || new UtenteStateModel();

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_UTENTE_EDIT_SERVICE);
        //this._service.getToolbar().addBackClick(() => this.props.history.push("/ClienteUtente"));
        this._service.getToolbar().addBackClick(() => this.props.history.push("/Clienti"));
        this._service.getToolbar().addButtonClick("Save", () => this.onSaveClick());
    }

    componentDidMount() {
        const { match } = this.props;
        this.setState({ ClienteId: match.params.IdCliente })

        if (match.params.id != null) {
            this.setState({ EditMode: true })
        }
    }

    onSaveClick = () => {
        this.validateAndSaveExtra(() => this._service.Save(this.state),
            (resp => {
                if (resp.IsValid) {
                    resp.EditMode = true
                    this.setState(resp)
                }
            })
        );
    }

    handleTipoDocumento = type => {
        this.setState({ TipoDocumento: type })
    }

    handleEnteDocumento = ente => {
        this.setState({ EnteRilascioDocumento: ente })
    }

    disableBeforeToday = (current) => {
        return current && current < moment().startOf('day')
    }

    disableAfterToday = (current) => {
        return current && current > moment().endOf('day')
    }

    render() {
        return (
            <div>
                <Card title="Dati personali" bordered={false}>
                    <Form>
                        <FormItem {...formItemLayout} label="Username">
                            {
                                this.getRequiredField(<Input size="small" onChange={this.onChangeText} name="Username" disabled={this.state.EditMode} />)
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label="Nome">
                            {
                                this.getRequiredField(<Input size="small" onChange={this.onChangeText} name="Nome" />)
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label="Cognome">
                            {
                                this.getRequiredField(<Input size="small" onChange={this.onChangeText} name="Cognome" />)
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label="Email">
                            {
                                this.getRequiredField(<Input type="email" size="small" onChange={this.onChangeText} name="Email" disabled={this.state.EditMode} />)
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label="Codice Fiscale">
                            {
                                this.getRequiredField(<Input size="small" onChange={this.onChangeText} name="CodiceFiscale" />)
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label="Telefono Cellulare">
                            {
                                this.getRequiredField(<Input size="small" onChange={this.onChangeText} placeholder="esempio: +39340123456" name="Telefono" />)
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label="Codice Paese di Residenza">
                            {
                                this.getRequiredField(<Input size="small" onChange={this.onChangeText} placeholder="esempio: IT" name="PaeseResidenza" />)
                            }
                        </FormItem>
                    </Form>
                </Card>
                <br />
                <Card title="Documento Identificativo" bordered={false}>
                    <Form>
                        <FormItem {...formItemLayout} label="Tipo documento">
                            {
                                this.getRequiredFieldFull("TipoDocumento", "CI",
                                    <TTSelect disabled={true} onSelect={this.handleTipoDocumento}>
                                        <Select.Option key="ci" value="CI">Carta d'identità</Select.Option>
                                    </TTSelect>
                                )
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label="Ente di rilascio">
                            {
                                this.getRequiredFieldFull("EnteRilascioDocumento", "Comune",
                                    <TTSelect disabled={true} onSelect={this.handleEnteDocumento}>
                                        <Select.Option key="comune" value="Comune">Comune</Select.Option>
                                    </TTSelect>
                                )
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label="Data rilascio">
                            {
                                this.getRequiredFieldInit(moment(this.state.RilascioDocumento),
                                    <DatePicker
                                        format="L"
                                        allowClear={false}
                                        disabledDate={this.disableAfterToday}
                                        onChange={(data, dateString) => this.onChangeData(data, dateString, 'RilascioDocumento')}
                                        name="RilascioDocumento" />
                                )
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label="Data scadenza">
                            {
                                this.getRequiredFieldInit(moment(this.state.ScadenzaDocumento),
                                    <DatePicker
                                        format="L"
                                        allowClear={false}
                                        disabledDate={this.disableBeforeToday}
                                        onChange={(data, dateString) => this.onChangeData(data, dateString, 'ScadenzaDocumento')}
                                        name="ScadenzaDocumento" />
                                )
                            }
                        </FormItem>
                        <FormItem {...formItemLayout} label="Codice Documento">
                            {
                                this.getRequiredField(<Input size="small" onChange={this.onChangeText} name="CodiceDocumento" />)
                            }
                        </FormItem>
                    </Form>
                </Card>
                <br />
            </div>
        )
    }
}

const ClienteUtenteEdit = Form.create()(withRouter(TTComponent(PageClienteUtenteEdit, 'Nuovo Utente', ComponentService)))
export default ClienteUtenteEdit;
