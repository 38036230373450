export const StatoAdempimentoDaFare = "LOCALIZATION_ADEMPIMENTO_TODO"
export const StatoAdempimentoInCorso = "LOCALIZATION_ADEMPIMENTO_INPROGRESS"
export const StatoAdempimentoFatto = "LOCALIZATION_ADEMPIMENTO_DONE"

export const ListSizePage = 10
export const ListSizeClientiInForm = ListSizePage

export const ListSize = {
    AdempimentoClienti: ListSizePage,
    AdempimentoAttivitaCliente: ListSizePage,
    ClienteRichieste: 5,
    ClienteScadenze: 5,
    ClienteAdempimenti: 5,
    ClienteUtenti: 5,
    HomeRichieste: ListSizePage
}

export const GuidaPdf = '/FilesRepository/test.pdf'
