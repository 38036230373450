import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";

import { FilesystemVirtualeStateEditModel, FilesystemVirtualeUploadModel } from "../Models/FilesystemVirtualeModels";
import { ModelBase } from "../Models/ModelBase";

import { IApiServiceExt } from "./Shared/ApiServiceExt";
import { IFormDataConverter } from "./Shared/FormDataConverter";
import { IFSVirtualeServiceBase, FSVirtualeServiceBase } from './FSVirtualeServiceBase'

export interface IFSVirtualeService extends IFSVirtualeServiceBase {
    GetRootFolder(data: string): Promise<FilesystemVirtualeStateEditModel>
    GetById(data: string, foldersOnly: boolean): Promise<FilesystemVirtualeStateEditModel>
    Upload(data: FilesystemVirtualeUploadModel): Promise<ModelBase>
    Delete(id: string): Promise<ModelBase>
    Rename(data: FilesystemVirtualeStateEditModel): Promise<ModelBase>
    Move(data: FilesystemVirtualeStateEditModel): Promise<ModelBase>
}

@injectable()
export class FSVirtualeService extends FSVirtualeServiceBase implements IFSVirtualeService {

    _apiServiceExt: IApiServiceExt;
    _formDataConverter: IFormDataConverter

    constructor() {
        super()
        this._apiServiceExt = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
        this._formDataConverter = IoCContainer.get(SERVICE_IDENTIFIER.FORMDATA_CONVERTER);
    }

    async GetRootFolder(data: string): Promise<FilesystemVirtualeStateEditModel> {
        const resp = await this._apiService.getAsync<any>(Api.GetRootFolder, { Cliente: data })
        return resp
    }

    async GetById(data: string, foldersOnly: boolean = false): Promise<FilesystemVirtualeStateEditModel> {
        const resp = await this._apiService.getAsync<any>(Api.GetById, { Id: data, FoldersOnly: foldersOnly })
        return resp
    }

    async Upload(data: FilesystemVirtualeUploadModel): Promise<ModelBase> {
        var input = this._formDataConverter.convert(data)
        const resp = await this._apiService.postAsync<any>(Api.UploadFile, input)
        return resp
    }

    async Delete(id: string): Promise<ModelBase> {
        const resp = await this._apiServiceExt.getAsync<any>(Api.DeleteFile, { Id: id })
        return resp
    }

    async Rename(data: FilesystemVirtualeStateEditModel): Promise<ModelBase> {
        const resp = await this._apiServiceExt.postAsync<any>(Api.RenameFile, data)
        return resp
    }

    async Move(data: FilesystemVirtualeStateEditModel): Promise<ModelBase> {
        const resp = await this._apiServiceExt.postAsync<any>(Api.MoveFile, data)
        return resp
    }
}