import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { IImportBaseService, ImportBaseService } from "./ImportBaseService";

export interface IImportScadenzeService extends IImportBaseService {

}

@injectable()
export class ImportScadenzeService extends ImportBaseService implements IImportScadenzeService {

    UploadUrl = Api.ImportScadenze

    constructor() {
        super()
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.IMPORTSCADENZE;
    }
}