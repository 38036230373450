import * as React from 'react';
import { Avatar, Badge } from "antd";
import { IAvatarNotification } from './../../Models/AvatarNotification'
import { TTAvatar } from './TTAvatar';

export const AvatarNotification : React.FC<IAvatarNotification> = (props) => {

    let avatar = <TTAvatar tticon="notifica" />
    let status: 'success' | 'processing' | 'default' | 'error' | 'warning' = 'default'

    if (props.status == null) {
        return avatar;
    }

    if (props.status == 'Si' || props.status == 'Fatto') {
        status = 'success'
    } else if (props.status == 'No') {
        status = 'error'
    }

    return (
        <Badge dot status={status}>
            {avatar}
        </Badge>
    );
}