import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import IoCContainer from '../Wires/Bootstrapper';
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { IBaseComponentService, BaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";
import { IApiServiceExt } from "./Shared/ApiServiceExt";
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { SearcherBaseModel } from "../Models/SearcherBaseModel";
import { LicenzeProjection, AssegnaLicenzaClientiInput, LicenzaClientiModel } from "../Models/LicenzeModels";
import { ProjectionPagedModel } from "../Models/ProjectionPageModel";
import { ModelBase } from "../Models/ModelBase";
import { ClienteProjection } from "../Models/ClienteProjection";

export interface ILicenzaClientiService extends IBaseComponentService {
    Render(idLicenza: string): Promise<LicenzaClientiModel>
    Search(search: SearcherBaseModel): Promise<ProjectionPagedModel<ClienteProjection>>
    AssegnaClienti(idLicenza: string, idClienti: Array<string>): Promise<ModelBase>
}

@injectable()
export class LicenzaClientiService extends BaseComponentService implements ILicenzaClientiService {

    RenderApi = Api.RenderLicenzaClienti
    apiService: IApiService
    apiServiceExt: IApiServiceExt

    constructor() {
        super()
        this.apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        this.apiServiceExt = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.LICENZACLIENTI;
    }

    async Render(idLicenza: string): Promise<LicenzaClientiModel> {
        return await this.apiService.getAsync<LicenzaClientiModel>(Api.RenderLicenzaClienti, { Id: idLicenza });
    }

    async Search(search: SearcherBaseModel): Promise<ProjectionPagedModel<ClienteProjection>> {
        return await this.apiService.postAsync<ProjectionPagedModel<ClienteProjection>>(Api.LicenzaCercaClienti, search);
    }

    async AssegnaClienti(IdLicenza: string, IdClienti: Array<string>): Promise<ModelBase> {
        let data: AssegnaLicenzaClientiInput = { IdClienti, IdLicenza }
        return await this.apiServiceExt.postAsync<ModelBase>(Api.AssegnaLicenzaClienti, data);
    } 
}