import "reflect-metadata"
import { Container } from "inversify"

import SERVICE_IDENTIFIER from "./Identifiers"
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants"
import { IToolbarService, ToolbarService } from "../Services/ToolbarService/ToolbarServiceTs"

import { Service } from '../Services/TestToolbarService'
import TestService, { ITestService } from '../Services/TestService'
import { IBaseComponentService } from "../Services/Shared/BaseComponentService"

import MenuService, { IMenuService } from "../Services/MenuService/MenuServiceTs"
import { NotifyService, INotifyService } from "../Services/Shared/NotifyService"
import { FormDataConverter, IFormDataConverter } from "../Services/Shared/FormDataConverter"
import { IApiService, ApiService } from "../Services/Shared/ApiService"
import { ApiServiceExt, IApiServiceExt } from "../Services/Shared/ApiServiceExt"
import { IAppLayoutService, AppLayoutService } from '../Services/Shared/AppLayout'
import AuthService, { IAuthService } from "../Services/Security/AuthServiceTs"
import HeadService, { IHeadService } from "../Services/MenuService/HeadService"
import { HeaderModel } from "../Redux/Reducers/HeaderTs"
import { IActionBase } from "../Redux/Reducers/Actions/ActionBase"
import { IHeadRefreshAction, HeadRefreshAction } from "../Redux/Reducers/Actions/HeadRefreshAction"
import { IApiLoaderAction, ApiLoaderActions } from "../Redux/Reducers/Actions/ApiLoaderAction"
import { IErrorHandlerAction, ErrorHandlerActions } from "../Redux/Reducers/Actions/ErrorHandlerAction"
import { IUserServiceEdit, UserServiceEdit } from '../Services/UserService'
import { RicercaUtentiService, IRicercaUtentiService } from "../Services/RicercaUtentiService"
import { UserToolbarService } from "../Services/ToolbarService/UtentiToolBarService"
import { UserSearchToolbarService } from "../Services/ToolbarService/RicercaUtentiToolBarService"
import AuthServiceMock from "../Services/Security/AuthServiceMock"
import { IAccountService, AccountService } from "../Services/Security/AccountService"

import { IAdempimentoCopyService, AdempimentoCopyService } from "../Services/AdempimentoCopyService"
import { IAdempimentoEditService, AdempimentoEditService } from "../Services/AdempimentoEditService"
import { IAdempimentoService, AdempimentoService } from "../Services/AdempimentoService"
import { IAdempimentoCustomerService, AdempimentoCustomerService } from "../Services/AdempimentoCustomerService"
import { IAdempimentiService, AdempimentiService } from "../Services/AdempimentiService"
import { IAdempimentoNotificationService, AdempimentoNotificationService } from "../Services/AdempimentoNotificationService"
import { IGruppoService, GruppoService } from "../Services/GruppoService"
import { IGruppoEditService, GruppoEditService } from "../Services/GruppoEditService"
import { IClienteService, ClienteService } from "../Services/ClienteService"
import { IClienteUtenteService, ClienteUtenteService } from "../Services/ClienteUtenteService"
import { IClienteUtenteEditService, ClienteUtenteEditService } from "../Services/ClienteUtenteEditService"
import { IClientiService, ClientiService } from "../Services/ClientiService"
import { IHomeService, HomeService } from "../Services/HomeService"
import { IServices, Services } from "../Services/Services"
import { ICircolariService, CircolariService } from "../Services/CircolariService"
import { ICircolareEditService, CircolareEditService } from "../Services/CircolareEditService"
import { IFSVirtualeService, FSVirtualeService } from "../Services/FSVirtualeService"
import { IImportClientiService, ImportClientiService } from "../Services/ImportClientiService"
import { IImportFatturatoService, ImportFatturatoService } from "../Services/ImportFatturatoService"
import { IImportScadenzeService, ImportScadenzeService } from "../Services/ImportScadenzeService"
import { IFirmaService, FirmaService } from "../Services/FirmaService"
import { IDrawerService, DrawerService } from "../Services/DrawerService"
import { ISignalRServiceHandler, SignalRServiceHandler } from "../Services/Shared/SignalRServiceHandler"
import { IUtentiService, UtentiService } from "../Services/UtentiService"
import { UtentiEditService, IUtentiBOEditService } from "../Services/UtentiEditService"
import { ITenantEditService, TenantEditService } from "../Services/TenantEditService"
import { IFcmServiceHandler, FcmServiceHandler } from "../Services/Shared/FcmServiceHandler"
import { IDeviceService, DeviceService } from "../Services/DeviceService"
import { IDeepLinkService, DeepLinkService } from "../Services/DeepLinkService"
import { ILicenzeService, LicenzeService } from "../Services/LicenzeService"
import { ILicenzaClientiService, LicenzaClientiService } from "../Services/LicenzaClientiService"
import { ISupportService, SupportService } from "../Services/SupportService"
import { IFileService, FileService } from "../Services/FileService"

import { IAppTaskService, AppTaskService } from "../Services/AppTaskService"
import { IAppOfficeService, AppOfficeService } from "../Services/AppOfficeService"
import { IAppFSVirtualeService, AppFSVirtualeService } from "../Services/AppFSVirtualeService"
import {
    IAppAttivitaListService, AppAttivitaListService,
    IAppAttivitaService, AppAttivitaService
} from "../Services/AppAttivitaService"
import { IAppInformativeListaService, AppInformativeListaService } from '../Services/AppInformativeService'
import { IAppScadenzeListaService, AppScadenzeListaService } from '../Services/AppScadenzeService'
import { IAppCircolariService, AppCircolariService, IAppCircolareService, AppCircolareService } from '../Services/AppCircolariService'
import { IAppAdempimentiService, AppAdempimentiService } from '../Services/AppAdempimentiService'
import { IAppDocumentiService, AppDocumentiService } from '../Services/AppDocumentiService'
import { IAppInfoAssistenzaService, AppInfoAssistenzaService } from '../Services/AppInfoAssistenzaService'
import { IAppDashboardService, AppDashboardService } from '../Services/AppDashboardService'

let container = new Container()

container.bind<IToolbarService>(SERVICE_IDENTIFIER.TOOLBAR).to(Service).whenTargetNamed(PROVIDERS_REGISTRATION.TEST)
container.bind<IBaseComponentService>(SERVICE_IDENTIFIER.TEST).to(TestService).whenTargetNamed(PROVIDERS_REGISTRATION.TEST)
container.bind<ITestService>(SERVICE_IDENTIFIER.TEST).to(TestService)
container.bind<IMenuService>(SERVICE_IDENTIFIER.MENU_SERVICE).to(MenuService)
container.bind<INotifyService>(SERVICE_IDENTIFIER.NOTIFY).to(NotifyService)
container.bind<IFormDataConverter>(SERVICE_IDENTIFIER.FORMDATA_CONVERTER).to(FormDataConverter)
container.bind<IApiServiceExt>(SERVICE_IDENTIFIER.API_SERVICE_EXT).to(ApiServiceExt)
container.bind<IApiService>(SERVICE_IDENTIFIER.API_SERVICE).to(ApiService)
container.bind<IAppLayoutService>(SERVICE_IDENTIFIER.APPLAYOUT_SERVICE).to(AppLayoutService)
container.bind<IAuthService>(SERVICE_IDENTIFIER.AUTH_SERVICE).to(AuthService)
//container.bind<IAuthService>(SERVICE_IDENTIFIER.AUTH_SERVICE).to(AuthServiceMock);
container.bind<IHeadService>(SERVICE_IDENTIFIER.HEADER).to(HeadService)
container.bind<IHeadRefreshAction>(SERVICE_IDENTIFIER.HEADER_ACTION).to(HeadRefreshAction)
container.bind<IApiLoaderAction>(SERVICE_IDENTIFIER.API_LOADER_ACTION).to(ApiLoaderActions)
container.bind<IErrorHandlerAction>(SERVICE_IDENTIFIER.ERROR_HANDLER_ACTION).to(ErrorHandlerActions)
container.bind<IUserServiceEdit>(SERVICE_IDENTIFIER.USER_SERVICE).to(UserServiceEdit)
container.bind<IRicercaUtentiService>(SERVICE_IDENTIFIER.USER_SEARCH_SERVICE).to(RicercaUtentiService)
container.bind<IAccountService>(SERVICE_IDENTIFIER.ACCOUNT_SERVICE).to(AccountService)

// tPocket
container.bind<IAdempimentoCopyService>(SERVICE_IDENTIFIER.ADEMPIMENTO_COPY_SERVICE).to(AdempimentoCopyService)
container.bind<IAdempimentoEditService>(SERVICE_IDENTIFIER.ADEMPIMENTO_EDIT_SERVICE).to(AdempimentoEditService)
container.bind<IAdempimentoService>(SERVICE_IDENTIFIER.ADEMPIMENTO_SERVICE).to(AdempimentoService)
container.bind<IAdempimentoCustomerService>(SERVICE_IDENTIFIER.ADEMPIMENTO_CUSTOMER_SERVICE).to(AdempimentoCustomerService)
container.bind<IAdempimentiService>(SERVICE_IDENTIFIER.ADEMPIMENTI_SERVICE).to(AdempimentiService)
container.bind<IAdempimentoNotificationService>(SERVICE_IDENTIFIER.ADEMPIMENTO_NOTIFICATION_SERVICE).to(AdempimentoNotificationService)

container.bind<ICircolariService>(SERVICE_IDENTIFIER.CIRCOLARI_SERVICE).to(CircolariService)
container.bind<ICircolareEditService>(SERVICE_IDENTIFIER.CIRCOLARE_EDIT_SERVICE).to(CircolareEditService)
container.bind<ITenantEditService>(SERVICE_IDENTIFIER.TENANT_EDIT_SERVICE).to(TenantEditService)

container.bind<IClienteService>(SERVICE_IDENTIFIER.CLIENTE_SERVICE).to(ClienteService)
container.bind<IClienteUtenteService>(SERVICE_IDENTIFIER.CLIENTE_UTENTE_SERVICE).to(ClienteUtenteService)
container.bind<IClienteUtenteEditService>(SERVICE_IDENTIFIER.CLIENTE_UTENTE_EDIT_SERVICE).to(ClienteUtenteEditService)
container.bind<IClientiService>(SERVICE_IDENTIFIER.CLIENTI_SERVICE).to(ClientiService)

container.bind<IDeepLinkService>(SERVICE_IDENTIFIER.DEEP_LINK).to(DeepLinkService)
container.bind<IDeviceService>(SERVICE_IDENTIFIER.DEVICE_SERVICE).to(DeviceService)
container.bind<IDrawerService>(SERVICE_IDENTIFIER.DRAWER_SERVICE).to(DrawerService)

container.bind<IFirmaService>(SERVICE_IDENTIFIER.FIRMA_SERVICE).to(FirmaService)
container.bind<IFSVirtualeService>(SERVICE_IDENTIFIER.FSVIRTUALE_SERVICE).to(FSVirtualeService)

container.bind<IGruppoService>(SERVICE_IDENTIFIER.GRUPPO_SERVICE).to(GruppoService)
container.bind<IGruppoEditService>(SERVICE_IDENTIFIER.GRUPPO_EDIT_SERVICE).to(GruppoEditService)

container.bind<IHomeService>(SERVICE_IDENTIFIER.HOME_SERVICE).to(HomeService)

container.bind<ILicenzeService>(SERVICE_IDENTIFIER.LICENZE_SERVICE).to(LicenzeService)
container.bind<ILicenzaClientiService>(SERVICE_IDENTIFIER.LICENZA_CLIENTI_SERVICE).to(LicenzaClientiService)

container.bind<IImportClientiService>(SERVICE_IDENTIFIER.IMPORT_CLIENTI_SERVICE).to(ImportClientiService)
container.bind<IImportFatturatoService>(SERVICE_IDENTIFIER.IMPORT_FATTURATO_SERVICE).to(ImportFatturatoService)
container.bind<IImportScadenzeService>(SERVICE_IDENTIFIER.IMPORT_SCADENZE_SERVICE).to(ImportScadenzeService)

container.bind<IUtentiService>(SERVICE_IDENTIFIER.UTENTI_SERVICE).to(UtentiService)
container.bind<IUtentiBOEditService>(SERVICE_IDENTIFIER.UTENTI_EDIT_SERVICE).to(UtentiEditService)

container.bind<ISupportService>(SERVICE_IDENTIFIER.SUPPORT_SERVICE).to(SupportService)

container.bind<IServices>(SERVICE_IDENTIFIER.SERVICES).to(Services)

container.bind<IFileService>(SERVICE_IDENTIFIER.FILE_SERVICE).to(FileService)

// Singleton
container.bind<ISignalRServiceHandler>(SERVICE_IDENTIFIER.SIGNALR).to(SignalRServiceHandler).inSingletonScope()
container.bind<IFcmServiceHandler>(SERVICE_IDENTIFIER.FCM).to(FcmServiceHandler).inSingletonScope()
container.bind<IToolbarService>(SERVICE_IDENTIFIER.TOOLBAR).to(ToolbarService).inSingletonScope()

//container.bind<IToolbarService>(SERVICE_IDENTIFIER.TOOLBAR).to(UserToolbarService).inSingletonScope().whenTargetNamed(PROVIDERS_REGISTRATION.EDITUTENTI);
//container.bind<IToolbarService>(SERVICE_IDENTIFIER.TOOLBAR).to(UserSearchToolbarService).inSingletonScope().whenTargetNamed(PROVIDERS_REGISTRATION.RICERCAUTENTI);

// App Services
container.bind<IAppTaskService>(SERVICE_IDENTIFIER.APP_TASK_SERVICE).to(AppTaskService)
container.bind<IAppOfficeService>(SERVICE_IDENTIFIER.APP_OFFICE_SERVICE).to(AppOfficeService)
container.bind<IAppFSVirtualeService>(SERVICE_IDENTIFIER.APP_FSVIRTUALE_SERVICE).to(AppFSVirtualeService)
container.bind<IAppDashboardService>(SERVICE_IDENTIFIER.APP_DASHBOARD_SERVICE).to(AppDashboardService)
container.bind<IAppAttivitaListService>(SERVICE_IDENTIFIER.APP_ATTIVITA_LISTA_SERVICE).to(AppAttivitaListService)
container.bind<IAppAttivitaService>(SERVICE_IDENTIFIER.APP_ATTIVITA_SERVICE).to(AppAttivitaService)
container.bind<IAppInformativeListaService>(SERVICE_IDENTIFIER.APP_INFORMATIVE_LISTA_SERVICE).to(AppInformativeListaService)
container.bind<IAppScadenzeListaService>(SERVICE_IDENTIFIER.APP_SCADENZE_LISTA_SERVICE).to(AppScadenzeListaService)
container.bind<IAppCircolariService>(SERVICE_IDENTIFIER.APP_CIRCOLARI_LISTA_SERVICE).to(AppCircolariService)
container.bind<IAppCircolareService>(SERVICE_IDENTIFIER.APP_CIRCOLARE_SERVICE).to(AppCircolareService)
container.bind<IAppAdempimentiService>(SERVICE_IDENTIFIER.APP_ADEMPIMENTI_SERVICE).to(AppAdempimentiService)
container.bind<IAppDocumentiService>(SERVICE_IDENTIFIER.APP_DOCUMENTI_SERVICE).to(AppDocumentiService)
container.bind<IAppInfoAssistenzaService>(SERVICE_IDENTIFIER.APP_ASSISTENZA_SERVICE).to(AppInfoAssistenzaService)

export default container