import { injectable } from "inversify";

import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { AttivitaClienteLogDataModel } from "../app/Contents/Adempimenti/AdempimentoCustomerActivities";
import * as Api from "../Constants/ApiUrlConstants";
import { ModelBase } from "../Models/ModelBase";
import { Adempimento } from "../app/Contents/Clienti/ClienteAdempimenti";
import { AdempimentoState } from "../app/Contents/Adempimenti/PageAdempimentoEdit";

export interface IAdempimentoService extends IBaseComponentService {
    GetAttivitaDetails(idCliente: string, idAdempimento: string): Promise<Array<AttivitaClienteLogDataModel>>;
    CambiaStatoAttivita(attivitaId: string, clienteId: string, completato: boolean): Promise<ModelBase>;
}

@injectable()
export class AdempimentoService extends BaseComponentService implements IAdempimentoService {

    RenderApi: string = '';
    _apiService: IApiService;

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.ADEMPIMENTO;
    }

    async GetAttivitaDetails(idCliente: string, idAdempimento: string): Promise<Array<AttivitaClienteLogDataModel>> {
        const resp = await this._apiService.getAsync<any>(Api.GetAttivitaDetails, { adempimentoId: idAdempimento, clienteId: idCliente })
        return resp
    }

    async CambiaStatoAttivita(attivitaId: string, clienteId: string, completato: boolean): Promise<ModelBase> {
        const resp = await this._apiService.getAsync<any>(Api.AttivitaCambioStato, { attivitaId: attivitaId, clienteId: clienteId, completato: completato })
        return resp
    }

    
}