import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';

import { IGruppoEditService } from '../../../Services/GruppoEditService';
import IoCContainer from '../../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';

import { GruppoCustomers } from './GruppoCustomers';

import Form from 'antd/lib/form';
import { Card, Row, Col, Input, Select } from 'antd';

import { ModelBase } from '../../../Models/ModelBase';
import { BaseFormReactComponent } from '../BaseReactComponent';
import { TTSelect } from '../TTSelect';


const Option = Select.Option;
const ComponentService: symbol = SERVICE_IDENTIFIER.GRUPPO_EDIT_SERVICE;

export class GruppiStateEditModel extends ModelBase {
    public Id: string
    public Titolo: string
    public Descrizione: string
    public Tipo: string
    public Citta: string
    public Liquidazione: string
    public TipoSource: { [key: string]: string; } = {};
    public CittaSource: { [key: string]: string; } = {};
    public LiquidazioneSource: { [key: string]: string; } = {};
    public ContabilitaSource: { [key: string]: string; } = {};
    public Contabilita: string
    public RegimeIva: string
    public RegimeIvaSource: { [key: string]: string; } = {}
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    }
}

export class PageGruppoEdit extends BaseFormReactComponent<IStoreModel & RouteComponentProps & ITTProps, GruppiStateEditModel> {
    

    private _service: IGruppoEditService;

    constructor(props) {
        super(props);

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.GRUPPO_EDIT_SERVICE);
        this._service.getToolbar().addBackClick(() => this.props.history.push("/Gruppi"));
        this._service.getToolbar().addButtonClick("SalvaGruppo", this.saveGroup);

        this.state = this.props.componentData || new GruppiStateEditModel();
    }

    saveGroup = () => {
        this.validateAndSaveExtra(() => this._service.SaveGruppo(this.state),
            (r => { if (r.IsValid) this.setState(r) })
        );
    }

    onTypeChange = value => {
        this.setState({ Tipo: value });
    }

    onCityChange = value => {
        this.setState({ Citta: value });
    }

    onLiquidazioneChange = value => {
        this.setState({ Liquidazione: value });
    }

    onContabilitaChange = value => {
        this.setState({ Contabilita: value });
    }

    onRegimeIvaChange = value => {
        this.setState({ RegimeIva: value });
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <Card bordered={false} title="Modifica Gruppo">
                            <Row>
                                <Col span={24}>
                                    <Form>
                                        <Form.Item {...formItemLayout} label="Titolo">
                                            {
                                                this.getRequiredField(<Input name="Titolo" size="small" onChange={this.onChangeText} />)
                                            }
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Descrizione">
                                            <Input name="Descrizione" size="small" value={this.state.Descrizione} onChange={this.onChangeText} />
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Tipo Cliente">
                                            <TTSelect allowClear={true} defaultValue={this.state.Tipo} onChange={this.onTypeChange}>
                                                {this.renderSelectDictionary(this.state.TipoSource)}
                                            </TTSelect>
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Provincia">
                                            <TTSelect allowClear={true} defaultValue={this.state.Citta} onChange={this.onCityChange}>
                                                {this.renderSelectDictionary(this.state.CittaSource)}
                                            </TTSelect>
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Liquidazione">
                                            <TTSelect allowClear={true} defaultValue={this.state.Liquidazione} onChange={this.onLiquidazioneChange}>
                                                {this.renderSelectDictionary(this.state.LiquidazioneSource)}
                                            </TTSelect>
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Tipo Contabilità">
                                            <TTSelect allowClear={true} defaultValue={this.state.Contabilita} onChange={this.onContabilitaChange}>
                                                {this.renderSelectDictionary(this.state.ContabilitaSource)}
                                            </TTSelect>
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Regime IVA">
                                            <TTSelect allowClear={true} defaultValue={this.state.RegimeIva} onChange={this.onRegimeIvaChange}>
                                                {this.renderSelectDictionary(this.state.RegimeIvaSource)}
                                            </TTSelect>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <GruppoCustomers {...this.state} {...this.props} />
                    </Col>
                </Row>
            </div>
        );
    }
}

const GruppoEdit = withRouter(TTComponent(Form.create()(PageGruppoEdit), 'Modifica Gruppo', ComponentService));
export default GruppoEdit;