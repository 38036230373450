import { injectable } from 'inversify'

import * as Api from "../Constants/ApiUrlConstants"
import container from '../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../Wires/Identifiers'

import { IFileService } from './FileService'

import { ModelBase } from '../Models/ModelBase'
import { IApiService } from './Shared/ApiService'

export interface IFSVirtualeServiceBase {
    GetFile(FileId: string)
}

@injectable()
export class FSVirtualeServiceBase implements IFSVirtualeServiceBase {

    fileService: IFileService
    _apiService: IApiService

    constructor() {
        this._apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
        this.fileService = container.get(SERVICE_IDENTIFIER.FILE_SERVICE)
    }

    async GetFile(FileId: string) {
        var resp = await this._apiService.getAsync<ModelBase>(Api.FSVirtualePrepareFile, { FileId })
        this.fileService.GetFileRaw(resp.Code)
    }
}
