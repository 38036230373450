import * as React from 'react'
import { Form, Select, List } from 'antd'
import moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router'

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'
import container from '../../../Wires/Bootstrapper'

import { IAppTaskService } from '../../../Services/AppTaskService'

import { TTSelect } from '../../Contents/TTSelect'
import { TTList } from '../../Contents/TTList'
import { TTAvatar } from '../../Contents/TTAvatar'
import { ScadenzeItem } from '../../../Models/AppScadenzeModels'
import { formatEuro } from '../../Contents/TTFormatUtils'
import { TTComponentBoxed } from '../../../Shared/BaseComponent/TTComponentTs'
import { IAppScadenzeListaService } from '../../../Services/AppScadenzeService'

enum FiltroScadenze {
    Pagato, NonPagato, Tutto
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    }
}

class PageScadenzeState {
    List: Array<ScadenzeItem>
    Filtro: FiltroScadenze
    IsLoading: boolean
}

export class PageScadenzeComp extends React.Component<RouteComponentProps, PageScadenzeState> {

    service: IAppTaskService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.APP_TASK_SERVICE)
        this.state = new PageScadenzeState()

        let toolbar = container.get<IAppScadenzeListaService>(SERVICE_IDENTIFIER.APP_SCADENZE_LISTA_SERVICE).getToolbar()
        toolbar.addBackClick(() => this.props.history.push("/"))
    }

    componentDidMount() {
        this.loadScadenze(FiltroScadenze.NonPagato)
    }

    loadScadenze = async (data: FiltroScadenze) => {
        this.setState({ IsLoading: true, Filtro: data })

        let filtro = null
        if (data == FiltroScadenze.Pagato)
            filtro = true
        else if (data == FiltroScadenze.NonPagato)
            filtro = false

        let resp = await this.service.GetScadenze(filtro)
        this.setState({ List: resp.Scadenze })

        this.setState({ IsLoading: false })
    }

    renderPagato = (pagato: boolean) => {
        return pagato == true ? "Pagato" : "Non pagato"
    }

    render() {
        return (
            <>
                <Form.Item {...formItemLayout} label="Visualizza scadenze">
                    <TTSelect value={this.state.Filtro} onChange={e => this.loadScadenze(e as FiltroScadenze)} style={{ width: '100%' }}>
                        <Select.Option value={FiltroScadenze.Pagato}>Pagate</Select.Option>
                        <Select.Option value={FiltroScadenze.NonPagato}>Non pagate</Select.Option>
                        <Select.Option value={FiltroScadenze.Tutto}>Tutte</Select.Option>
                    </TTSelect>
                </Form.Item>
                <TTList
                    dataSource={this.state.List}
                    rowKey={it => it.Id}
                    itemLayout="horizontal"
                    pagination={{ pageSize: 10 }}
                    loading={this.state.IsLoading}
                    renderItem={item => (
                        <List.Item key={item.Id} extra={formatEuro(item.Amount)} >
                            <List.Item.Meta
                                avatar={<TTAvatar tticon="scadenze" />}
                                title={item.Title}
                                description={`${moment(item.Date).format('LL')} - ${this.renderPagato(item.Paid)}`} />
                        </List.Item>
                    )}
                />
            </>
        )
    }
}
export const PageScadenze = withRouter(TTComponentBoxed(PageScadenzeComp, 'Scadenze', SERVICE_IDENTIFIER.APP_SCADENZE_LISTA_SERVICE))