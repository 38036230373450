import { Guid } from "../Services/Shared/Guid";

export class AdempimentoActivity {
    public Id: string;
    public Titolo: string;
    public Descrizione: string;

    constructor() {
        this.Id = Guid.newGuid();
    }
}


export interface IAdempimentoActivities {
    activities: Array<AdempimentoActivity>
}