import { injectable } from "inversify";

import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { AdempimentoState } from "../app/Contents/Adempimenti/PageAdempimentoEdit";
import * as Api from "../Constants/ApiUrlConstants";

export interface IAdempimentoCustomerService extends IBaseComponentService {
    GetAdemimento(adempimentoId: string): Promise<AdempimentoState>;
}

@injectable()
export class AdempimentoCustomerService extends BaseComponentService implements IAdempimentoCustomerService {

    RenderApi: string = '';
    _apiService: IApiService;

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.ADEMPIMENTOCUSTOMER;
    }

    async GetAdemimento(adempimentoId: string): Promise<AdempimentoState> {
        const resp = await this._apiService.getAsync<any>(Api.RenderEditAdempimenti, { Id: adempimentoId })
        return resp
    }
}