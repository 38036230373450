import * as React from 'react'
import { PageHeader, Table, Empty, List, Button } from 'antd'
import moment from 'moment'

const { Column } = Table

import './FS.css'

import { FilesystemVirtualeStateEditModel } from '../../../Models/FilesystemVirtualeModels'

import { TTSingleSelectTable } from '../TTSingleSelectTable'
import { FSAvatar } from './FSAvatar'
import { TTEmpty } from '../TTEmpty'

class BaseFileSystemState {
    Nome: string
    ParentId: string
    Figli: Array<FilesystemVirtualeStateEditModel>

    SelectedItem: FilesystemVirtualeStateEditModel
}

export interface BaseFileSystemProps {
    data: FilesystemVirtualeStateEditModel
    onBack: (parentId: string) => void
    onOpenFile: (item: FilesystemVirtualeStateEditModel) => void
    onOpenFolder: (item: FilesystemVirtualeStateEditModel) => void

    // Per aggiungere eventuali altri pulsanti extra alle opzioni del file/cartella
    extraItemOptions?: React.ReactNode

    // Gestione opzionale dell'elemento selezionato
    onSelectedItem?: (item: FilesystemVirtualeStateEditModel) => void
    selectedItem?: FilesystemVirtualeStateEditModel
}

/*
 * Componente di base per la gestione di un file system in sola lettura
 */
export class BaseFileSystem extends React.Component<BaseFileSystemProps, BaseFileSystemState> {
    constructor(props) {
        super(props)

        this.state = new BaseFileSystemState()
    }

    static defaultProps = {
        extraItemOptions: undefined,
        onSelectedItem: null,
        selectedItem: null
    }

    componentDidMount() {
        if (this.props.data != null)
            this.updateFolderData(this.props.data)

        if (this.props.selectedItem != null)
            this.setState({ SelectedItem: this.props.selectedItem })
    }

    componentDidUpdate(prevProps: BaseFileSystemProps, prevState: BaseFileSystemState) {
        if (this.props.data != prevProps.data)
            this.updateFolderData(this.props.data)

        if (this.props.selectedItem != prevProps.selectedItem)
            this.setState({ SelectedItem: this.props.selectedItem })
    }

    updateFolderData = (item: FilesystemVirtualeStateEditModel) => {
        this.setState({
            Figli: item.Figli,
            ParentId: item.Parent_Id,
            Nome: item.Code
        })
    }

    // Apertura di un elemento al doppio click
    openItem = (item: FilesystemVirtualeStateEditModel) => {
        if (item.IsDirectory)
            this.openFolder(item)
    }

    selectedItem = (item: FilesystemVirtualeStateEditModel) => {
        this.setState({ SelectedItem: item })

        if (this.props.onSelectedItem != null)
            this.props.onSelectedItem(item)
    }

    onBack = () => {
        // Notificare l'assenza di un elemento selezionato
        this.selectedItem(null)

        this.props.onBack(this.state.ParentId)
    }

    openFile = (item: FilesystemVirtualeStateEditModel) => {
        this.props.onOpenFile(item)
    }

    openFolder = (item: FilesystemVirtualeStateEditModel) => {
        // Notificare l'assenza di un elemento selezionato
        this.selectedItem(null)

        this.props.onOpenFolder(item)
    }

    onOpenFile = () => {
        this.openFile(this.state.SelectedItem)
    }

    onOpenFolder = () => {
        this.openFolder(this.state.SelectedItem)
    }

    getPageHeaderButtons = () => {
        let arr = []

        if (this.props.extraItemOptions != null)
            arr.push(this.props.extraItemOptions)

        if (this.state.SelectedItem.IsDirectory) {
            arr.push(<Button type="primary" key="buttonOpen" onClick={() => this.onOpenFolder()}>Apri</Button>)
        } else {
            arr.push(<Button type="primary" key="buttonDown" onClick={() => this.onOpenFile()}>Scarica</Button>)
        }

        return arr
    }

    formatDate = (item: FilesystemVirtualeStateEditModel) => {
        return item.IsDirectory || item.CreationDate == null ? "" : moment(item.CreationDate).format("LLL")
    }

    render() {
        return (
            <>
                <PageHeader
                    title={this.state.Nome}
                    onBack={this.state.ParentId && this.props.onBack ? () => this.onBack() : undefined}
                    extra={this.state.SelectedItem != null ? this.getPageHeaderButtons() : undefined}>
                    {this.props.children}
                </PageHeader>

                <TTSingleSelectTable className="fs-list-bg"
                    dataSource={this.state.Figli}
                    onClick={e => this.selectedItem(e)}
                    onDoubleClick={e => this.openItem(e)}
                    rowKey={it => it.Id}
                    locale={{
                        emptyText: <TTEmpty description="Nessun documento o cartella" />
                    }}
                    pagination={false}
                    showHeader={false}>

                    <Column title='Name' dataIndex='Code' render={(_, item: FilesystemVirtualeStateEditModel) =>
                        <List.Item.Meta
                            avatar={<FSAvatar directory={item.IsDirectory} />}
                            description={this.formatDate(item)}
                            title={item.Code} />}
                    />

                </TTSingleSelectTable>
            </>
        )
    }
}