import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { ProjectionPagedModel } from "../Models/ProjectionPageModel";
import { CircolareProjection } from "../Models/CircolareProjection";
import { SearcherBaseModel } from './../Models/SearcherBaseModel'
import { ModelBase } from './../Models/ModelBase'
import { CircolareInput } from "../Models/CircolareInput";
import { IApiServiceExt } from "./Shared/ApiServiceExt";

export interface ICircolariService extends IBaseComponentService {
    GetCircolari(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<CircolareProjection>>
    DeleteCircolare(id: string): Promise<ModelBase>
}

@injectable()
export class CircolariService extends BaseComponentService implements ICircolariService {

    RenderApi: string = Api.RenderCircolari
    _apiService: IApiServiceExt

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.CIRCOLARI;
    }

    async GetCircolari(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<CircolareProjection>> {
        const resp = await this._apiService.getAsync<any>(Api.GetCircolari, searcher)
        return resp
    }

    async DeleteCircolare(id: string): Promise<ModelBase> {

        let input: CircolareInput =
        {
            Id: id
        }

        const resp = await this._apiService.postAsync<any>(Api.DeleteCircolare, input, "Eliminazione avvenuta correttamente")
        return resp
    }
}