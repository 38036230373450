import * as React from 'react';
import { Card, Tabs, Icon } from 'antd';

import { AdempimentoCustomerActivityDetails } from './AdempimentoCustomerActivityDetails';
import { IAdempimentoService } from '../../../Services/AdempimentoService';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { RouteComponentProps } from 'react-router';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import { IClienteService } from '../../../Services/ClienteService';
import { ClienteProjection } from '../../../Models/ClienteProjection';
import { TTIcon } from '../TTIcon';

const TabPane = Tabs.TabPane;

export interface IReloadComponent {
    onReload(): void;
}

export class AttivitaClienteLogDataModel {
    Id: string;
    Titolo: string;
    Descrizione: string;
    StatoAttivita: string;
    Completato: boolean;
    Adempimento_Id: string;
    Cliente_Id: string;
    Logs: Array<LogModel>;
    Richieste: Array<RichiesteModel>;
}

export class LogModel {
    Id: string;
    Stato: string;
}

export class RichiesteModel {
    Id: string;
    Descrizione: string;
    Note: string;
    Risposta: string;
}

export class AdempimentoCustomerActivitiesState {
    Data: Array<AttivitaClienteLogDataModel>;
    Cliente: ClienteProjection;
    ActiveTabIndex: string

    constructor() {
        this.Cliente = new ClienteProjection();
    }
}

export class AdempimentoCustomerActivities extends React.Component<any & IStoreModel & RouteComponentProps & ITTProps, AdempimentoCustomerActivitiesState> {

    private _service: IAdempimentoService;
    private _serviceCliente: IClienteService;

    constructor(props) {
        super(props);
        this._service = IoCContainer.get(SERVICE_IDENTIFIER.ADEMPIMENTO_SERVICE);
        this._serviceCliente = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE);

        this.state = new AdempimentoCustomerActivitiesState();
    }

    componentDidMount() {
        this.onReload()
        this.loadCliente()
    }

    componentDidUpdate(prevProps, prevState) {
        // Aggiorno se arriv da drawer notifiche
        if (this.props.match.params.IdCliente != prevProps.match.params.IdCliente) {
            this.loadCliente()
        }

        // Aggiorno se arriv da drawer notifiche
        if (this.props.match.params.IdCliente != prevProps.match.params.IdCliente ||
            this.props.match.params.IdAdempimento != prevProps.match.params.IdAdempimento) {
            this.onReload()
        }
    }

    loadCliente = () => {
        this._serviceCliente.GetCliente(this.props.match.params.IdCliente).then(resp => {
            this.setState({ Cliente: resp })
        });
    }

    onReload = () => {
        this._service.GetAttivitaDetails(this.props.match.params.IdCliente, this.props.match.params.IdAdempimento).then(resp => {
            let idx = this.calcDefaultTabIndex(resp)
            this.setState({ Data: resp, ActiveTabIndex: String(idx) })
        });
    }

    calcDefaultTabIndex = (array: any[]): number => {
        let idx = null
        array.forEach((item, index) => {
            if (idx == null && item.Completato == false) { idx = index }
        });
        if (idx == null) idx = 0
        return idx
    }

    getTabTitle(item: AttivitaClienteLogDataModel) {

        let icon = <TTIcon icon={item.Completato ? "fatto" : "da_fare"} ttstyle="stati" />

        return (
            <span>
                {icon} {item.Titolo}
            </span>
        );
    }

    render() {
        if (this.state == null) return (<React.Fragment />);

        if (this.state.Data == null) return (<React.Fragment />);

        let tabs = this.state.Data.map((item, index) =>
            <TabPane tab={this.getTabTitle(item)} key={String(index)}>
                <AdempimentoCustomerActivityDetails {...this.props}  {...item} onReload={this.onReload} />
            </TabPane>
        );

        return (
            <Card bordered={false} title={"Attività per " + this.state.Cliente.Descrizione} className="last-card">
                <Tabs activeKey={this.state.ActiveTabIndex} tabPosition="left" onChange={key => this.setState({ ActiveTabIndex: key })}>
                    {tabs}
                </Tabs>
            </Card>
        );
    }
}