import * as React from 'react';
import { Card, List } from 'antd';

import { BaseFormReactComponent } from '../BaseReactComponent';
import { NuovaAttivita } from './FormNuovaAttivita';
import { TTModal } from '../TTModal';
import { TTIconButton } from '../TTIconButton';
import { TTClientPagedSearchList } from '../TTClientPagedSearchList';
import { AdempimentoActivity } from '../../../Models/IAdempimentoActivities';
import * as Constants from '../../../Constants/AppConstants'

export class EditAttivitaState {
    isModalVisible: boolean
    activities: Array<AdempimentoActivity>
    selectedActivity: AdempimentoActivity
}

export interface IAttivitaEdit {
    attivita: Array<AdempimentoActivity>
    updateAttivita: (obj: AdempimentoActivity[]) => void
}

export class AdempimentoEditAttivita extends BaseFormReactComponent<IAttivitaEdit, EditAttivitaState> {

    public RigheLista = Constants.ListSizePage

    constructor(props) {
        super(props);

        this.state = {
            isModalVisible: false,
            selectedActivity: null,
            activities: this.props.attivita || []
        };
    }

    updateAttivita = (list: Array<AdempimentoActivity>) => {
        this.props.updateAttivita(list)
        this.setState({ activities: list })
    }

    onDelete = item => {
        TTModal.confirm({
            title: 'Attenzione',
            content: 'Sei sicuro di voler cancellare l\'attività?',
            okText: 'Si',
            cancelText: 'No',
            onOk: () => {
                var activities = [...this.state.activities]
                var index = activities.findIndex(c => c.Id == item.Id)
                if (index != -1)
                    activities.splice(index,1);

                this.updateAttivita(activities)
            }
        });
    }


    openEdit = (item: AdempimentoActivity) => {
        this.setState({
            isModalVisible: true,
            selectedActivity: item
        });
    };

    closeEdit = () => {
        this.setState({ isModalVisible : false });
    }

    salvaAttivita = (model: AdempimentoActivity) => {
        var items = [...this.state.activities]

        var existingItem = items.find(c => c.Id == model.Id);
        if (existingItem != null) {
            existingItem.Descrizione = model.Descrizione;
            existingItem.Titolo = model.Titolo;
        } else {
            items.push(model);
        }

        this.updateAttivita(items)
    }

    render() {
        const { activities } = this.state
        return (
            <Card bordered={false} title="Attività" extra={<TTIconButton tticon="add" onClick={() => this.openEdit(new AdempimentoActivity())} />}>
                <TTClientPagedSearchList
                    itemLayout="horizontal"
                    dataSource={activities}
                    pagination={{
                        total: activities == null ? 0 : activities.length,
                        showTotal: (total) => `${total} elementi`,
                        pageSize: this.RigheLista,
                    }}
                    searchProps={{
                        placeholder: "Cerca attività"
                    }}
                    searchItemProp="Titolo"
                    renderItem={item =>
                        <List.Item key={item.Id} actions={[
                            <TTIconButton tticon="modifica" onClick={() => this.openEdit(item)} />,
                            <TTIconButton tticon="elimina" onClick={() => this.onDelete(item)} />
                        ]}>
                            <List.Item.Meta
                                title={item.Titolo}
                                description={item.Descrizione} />
                        </List.Item>
                    }
                />

                <NuovaAttivita
                    visible={this.state.isModalVisible}
                    selectedActivity={this.state.selectedActivity}
                    closeModal={this.closeEdit}
                    do={this.salvaAttivita} />
            </Card>
        );
    }
}
