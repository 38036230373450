import { Layout, Drawer, notification } from 'antd';
import * as React from 'react';
import * as ReactRedux from "react-redux";
import { Route, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux'

import moment from 'moment'
import 'moment/locale/it'

import './../../src/styles/bootstrap.scss';
import 'antd/dist/antd.less';
import './../../src/styles/antd.less';
import 'react-vis/dist/style.css';

// custom
import './../../src/styles/layout.scss';
import './../../src/styles/theme.scss';
import './../../src/styles/ui.scss';
import './../../src/styles/app.scss';


import AppHeader from './../Shared/Layout/Header';
import AppFooter from './../Shared/Layout/Footer';
import AppSidebar from './../Shared/Layout/Sidebar';
import './../../src/styles/custom.scss';


import ErrorPage from '../Shared/ErrorPages/Error'
import { LoginForm } from '../Shared/Login/LoginTs';
import Logout from '../Shared/Login/LogoutTs';
import { TTRedirect } from './../Shared/Redirect/Redirect'

import Notification from './Notification/index';

import * as actions from '../Redux/Reducers/Actions/ActionsTS';
import * as Dispatcher from './Dispatcher';

import container from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../Wires/Identifiers';
import { TPContext, DispatcherContext } from '../Services/Shared/Dispatcher-Context';

import { IAuthService } from '../Services/Security/AuthServiceTs';
import { IAppLayoutService } from '../Services/Shared/AppLayout';
import { IFcmServiceHandler } from '../Services/Shared/FcmServiceHandler';
import { ISignalRServiceHandler } from '../Services/Shared/SignalRServiceHandler';
import { IMenu } from '../Services/ToolbarService/IMenuItems';

import { ToastMessageModel } from '../Models/SignalRResponseModel';

import { RichiesteDrawer } from './Contents/RichiesteDrawer';
import { ChangePasswordForm } from '../Shared/Login/ChangePassword'

const { Header, Footer, Content } = Layout;

const RenderRoute = (data: IMenu) => {
    const CP = Dispatcher[data.componentKey];
    if (CP == null)
        throw new Error('Creare l\'export per il componente ' + data.componentKey + ' in Dispatcher.Ts');

    return <CP />
}

const PrintRoute = (data: IMenu) => {
    if (data.menu != null && data.menu.length>0)
        return data.menu.map(PrintRoute)

    if (data.route != null)
        return <Route key={data.id} path={data.route} exact={data.route == "/"} render={() => RenderRoute(data)} />
}

class AppLayoutState {
    IsDrawerOpen: boolean = false
    menu: Array<IMenu> = []
    context: TPContext = new TPContext()
}

export class AppLayout extends React.Component<any, AppLayoutState> {

    service: IAppLayoutService;
    authService : IAuthService;
  
    signalRService: ISignalRServiceHandler;
    fcmService: IFcmServiceHandler;


    constructor(props:any) {
        super(props);
        this.service = container.get(SERVICE_IDENTIFIER.APPLAYOUT_SERVICE);  
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE);  

        let state = new AppLayoutState()
        state.context.ShowDrawer = this.showDrawer
        this.state = state;
    }

    showDrawer = (visible: boolean) => {
        this.setState({ IsDrawerOpen: visible });
    }

    setupMenu = async () => {
        let resp = await this.service.getMenuForRoute();
        this.setState({ menu: resp.menu });
    }

    setupDrawer = async () => {
        let resp = await this.service.hasDrawer()
        
        let context = { ...this.state.context }
        context.IsDrawerEnabled = resp.HasDrawer

        this.setState({ context });
    }

    async componentDidMount() {

        moment.locale('it')
        if (this.authService.isAuthenticated()) {

            this.setupMenu()
            this.setupDrawer()

            // Setup SignalR
            this.signalRService = container.get(SERVICE_IDENTIFIER.SIGNALR);
            this.signalRService.AddEventHandlerFor("SendToast", this.onToastRecieved)

            // Setup Firebase Cloud Messaging
            this.fcmService = container.get(SERVICE_IDENTIFIER.FCM);
            this.fcmService.requestNotifyPerms();
        }
    }

    componentWillUnmount() {
        this.signalRService.RemoveEventHandlerFor("SendToast", this.onToastRecieved)
    }

    onToastRecieved = (type: string, data: ToastMessageModel) => {
        notification.open({
            message: data.Titolo,
            description: data.Descrizione,
            placement: 'bottomRight',
            onClick: () => {
                this.props.history.push(data.Link)
            },
        });
    }

    renderDrawer = () => {
        if (this.state.context.IsDrawerEnabled == false)
            return <></>

        return (
            <Drawer visible={this.state.IsDrawerOpen}
                closable={false}
                width={400}
                onClose={() => this.showDrawer(false)}>
                <RichiesteDrawer />
            </Drawer>
        )
    }
  
    render() {

        if (this.props.error.isError)
            return (
                <Layout id="app-main-layout" className="ant-layout-has-sider">
                    <Layout>
                        <Layout>
                            <Content className="app-content">
                                <ErrorPage />
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            )

        return (
            this.authService.isAuthenticated() ?
                <DispatcherContext.Provider value={this.state.context}>
                    <div className="full-height fixed-header">
                        {this.renderDrawer()}
                        <Layout id="app-main-layout" className="ant-layout-has-sider">
                            <AppSidebar />
                            <Layout>
                                <Header className="app-header">
                                    <AppHeader />
                                </Header>
                                <Layout>
                                    <Content className="app-content">
                                        {this.state.menu.map(e => PrintRoute(e))}

                                        <Route path="/Redirect/:id" component={TTRedirect} />
                                        <Route path="/Notification" component={Notification} />
                                    </Content>
                                    <Footer className="app-footer"> <AppFooter /> </Footer>
                                </Layout>
                            </Layout>
                        </Layout>
                    </div>
                </DispatcherContext.Provider>
                :
                <>
                    <Layout id="app-main-layout" className="ant-layout-has-sider">
                        <Layout>
                            <Layout>
                                <Content className="app-content">
                                    <Route exact path="/" component={LoginForm} />
                                    <Route path="/ChangePassword/:token" component={ChangePasswordForm} />
                                    <Route path="/Redirect/:id" component={TTRedirect} />
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>
                </>
        );
    }
}
AppLayout.contextType = DispatcherContext;
const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => bindActionCreators(
  actions,
  dispatch,
);

export default withRouter(ReactRedux.connect(mapStateToProps, mapDispatchToProps)(AppLayout));