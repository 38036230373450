import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { UtenteStateModel } from "../app/Contents/Clienti/PageClienteUtenteEdit";

export interface IClienteUtenteEditService extends IBaseComponentService {
    Save(data: UtenteStateModel): Promise<UtenteStateModel>
}

@injectable()
export class ClienteUtenteEditService extends BaseComponentService implements IClienteUtenteEditService {

    RenderApi: string = Api.RenderClienteUtente;
    _apiServiceExt: IApiService;

    constructor() {
        super();
        this._apiServiceExt = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.CLIENTEUTENTEEDIT;
    }

    async Save(data: UtenteStateModel): Promise<UtenteStateModel> {
        var resp = await this._apiServiceExt.postAsync<any>(Api.SaveClienteUtente, data)
        return resp
    }
}