import * as React from 'react';
import { Row, Col, Card, Table, Input } from 'antd';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import TTComponentBoxed from '../../../Shared/BaseComponent/TTComponentTs';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { ClientiState, ClientiProjection } from '../../../Models/ClientiState';
import { ProjectionPagedModel } from '../../../Models/ProjectionPageModel';
import { SearcherBaseModel } from '../../../Models/SearcherBaseModel';
import { BaseReactComponent } from '../BaseReactComponent';
import { TTTable } from '../TTTable';
import * as Constants from './../../../Constants/AppConstants'

const Search = Input.Search;


const ComponentService: symbol = SERVICE_IDENTIFIER.CLIENTI_SERVICE;

export class PageClienti extends BaseReactComponent<IStoreModel & RouteComponentProps & ITTProps, ClientiState, ClientiProjection> {

    //private _service: IClientiService;
    private RigheLista = Constants.ListSizePage

    constructor(props) {
        super(props);
        this.state = this.props.componentData || new ClientiState();

        //this._service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTI_SERVICE);
    }

    protected getServiceIdentifier(): symbol {
        return SERVICE_IDENTIFIER.CLIENTI_SERVICE;
    }

    onCustomerClick = id => {
        this.props.history.push('Cliente/' + id);
    }

    onSearch = text => {
        this.setState({ Searcher: text });
        this.DoSearch(1);
    }

    onPageChange = page => {
        this.DoSearch(page)
    }

    private DoSearch(page: any) {
        const searcher: SearcherBaseModel = {
            Pagina: page,
            FullText: this.state.Searcher,
            Righe: this.RigheLista
        };

        this.service.Search(searcher).then(resp => this.setState({ Data: resp, Page: page }));
    }

    render() {
        const columns = [
            {
                title: 'Nome',
                dataIndex: 'Descrizione',
                render: (text, row, index) => 
                    <a onClick={event => this.onCustomerClick(row.Id)}><b>{text}</b></a>
            },
            {
                title: 'Codice Fiscale',
                dataIndex: 'CodiceFiscale'
            },
            {
                title: 'Partita IVA',
                dataIndex: 'PartitaIva'
            },
            {
                title: 'Provincia',
                dataIndex: 'Citta'
            },
            {
                title: 'Tipo Contabilità',
                dataIndex: 'TipoContabilita'
            },
            {
                title: 'Liquidazione',
                dataIndex: 'Liquidazione'
            }
        ];

        let list: ProjectionPagedModel<ClientiProjection> = this.state.Data;
        return (
            <div>
                <Card bordered={false}>
                    <Row>
                        <Col offset={1} span={22} >
                            <Search placeholder="Cerca cliente" onChange={this.onChangeText} name="Searcher"  onSearch={value => this.onSearch(value)} value={this.state.Searcher} enterButton />
                        </Col>
                    </Row>
                    <br />
                    <TTTable columns={columns} dataSource={list.Data} rowKey="Id"
                        pagination={{
                            onChange: (page) => this.onPageChange(page),
                            pageSize: this.RigheLista,
                            total: list.Total,
                            showTotal: (total) => `${total} elementi`,
                            current: this.state.Page
                        }} />
                </Card>
            </div>
        );
    }
}

const Clienti = withRouter(TTComponentBoxed(PageClienti, 'Elenco Clienti', ComponentService));
export default Clienti;