import * as React from 'react';
import { Col, Row, Button, List, Divider, Switch, Icon, Avatar, } from 'antd';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { IAdempimentoService } from '../../../Services/AdempimentoService';
import { ModelBase } from '../../../Models/ModelBase';
import moment from 'moment'
import { AvatarNotification } from '../AvatarNotification';

import * as Constants from './../../../Constants/AppConstants'
import { TTClientPagedList } from '../TTClientPagedList';

export class AdempimentoCustomerActivityDetails extends React.Component<any, any>
{
    private _service: IAdempimentoService;
    private RigheListe = Constants.ListSize.AdempimentoAttivitaCliente

    constructor(props) {
        super(props);

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.ADEMPIMENTO_SERVICE);
        this.onNotificationClick = this.onNotificationClick.bind(this);        
    }

    onNotificationClick = () => {
        debugger;
        this.props.Adempimento_Id;
        this.props.history.push('/AdempimentoNotification/' + this.props.match.params['IdCliente'] + '/' + this.props.match.params['IdAdempimento'] + '/' + this.props.Id);
    };

    onAttivitaChange = (value: boolean) => {
        this._service.CambiaStatoAttivita(this.props.Id, this.props.Cliente_Id, value).then((resp: ModelBase) => {
            if (resp.IsValid) this.props.onReload();
        });
    }

    formatData = (date: Date) => {
        return moment(date).format("LLL")
    }

    renderRichiestaTitle = item => {
        if (item.Risposta != null) {
            return `${item.Descrizione} - ${item.Risposta}`
        }
        return item.Descrizione
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <h2>{this.props.Titolo}</h2>
                        {this.props.Descrizione}
                    </Col>
                </Row>
                <br />
                <br />
                <Row gutter={8} type="flex" justify="space-around">
                    <Col>
                        <span>Attività completata </span>
                        <Switch checked={this.props.Completato}
                            onChange={this.onAttivitaChange}
                            checkedChildren={<Icon type="check" />}
                            unCheckedChildren={<Icon type="close" />} />
                    </Col>
                    <Col>
                        <Button type="primary" onClick={this.onNotificationClick}>Notifica Cliente</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col span={11}>
                                <Divider orientation="left">Log </Divider>
                                <TTClientPagedList
                                    itemLayout="horizontal"
                                    dataSource={this.props.Logs}
                                    style={{ height: '670px' }}
                                    pagination={{
                                        total: this.props.Logs == null ? 0 : this.props.Logs.length,
                                        showTotal: (total) => `${total} elementi`,
                                        pageSize: this.RigheListe,
                                    }}
                                    renderItem={(item:any) => (
                                        <List.Item key={item.Id}>
                                            <List.Item.Meta
                                                avatar={<Avatar size={40} icon="swap" />}
                                                title={`Cambiato in stato: ${item.Stato}`}
                                                description={this.formatData(item.Data)} />
                                        </List.Item>
                                    )} />
                            </Col>
                            <Col offset={2} span={11}>
                                <Divider orientation="left">Notifiche</Divider>
                                <TTClientPagedList
                                    dataSource={this.props.Richieste}
                                    style={{ height: '670px' }}
                                    pagination={{
                                        total: this.props.Richieste == null ? 0 : this.props.Richieste.length,
                                        showTotal: (total) => `${total} elementi`,
                                        pageSize: this.RigheListe,
                                    }}
                                    renderItem={(item: any) => (
                                        <List.Item key={item.Id}>
                                            <List.Item.Meta
                                                avatar={<AvatarNotification status={item.Risposta} />}
                                                title={this.renderRichiestaTitle(item)}
                                                description={this.formatData(item.Data)} />
                                        </List.Item>
                                    )} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            );
    }
}