import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import SERVICE_IDENTIFIER from '../../Wires/Identifiers'
import container from '../../Wires/Bootstrapper'
import { IAuthService } from '../../Services/Security/AuthServiceTs'
import { IDeepLinkService } from '../../Services/DeepLinkService'


interface RedirectParams {
    id: string
}

export class TTRedirectComp extends React.Component<RouteComponentProps<RedirectParams>, any> {

    service: IDeepLinkService
    authService: IAuthService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.DEEP_LINK)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
    }

    componentDidMount() {
        var token = this.props.match.params.id
        this.service.GetDataByToken(token).then(it => {
            debugger
            if (it.IsValid) {
                this.authService.setToken(it.Token)
                location.href = it.Url
            } else {
                location.href = '/'
            }
        })
    }

    render() {
        return (<React.Fragment />)
    }
}

export const TTRedirect = withRouter(TTRedirectComp)
