import * as React from 'react'

import { TTVisHint } from './TTVisHint'
import { ChartHintState } from './../../Models/ChartHintState';

export abstract class TTChartBaseComponent<TProps extends any, TState extends ChartHintState<any>> extends React.Component<TProps, TState> {

    constructor(props) {
        super(props)
    }

    onShowHint = data => {
        this.setState({ hint: data })
    }

    onHideHint = () => {
        this.setState({ hint: null })
    }

    buildHint = () => {
        const { hint } = this.state
        return (
            <TTVisHint value={hint}>
                {this.getHintLayout(hint)}
            </TTVisHint>
        )
    }

    abstract getHintLayout(data: any)
}