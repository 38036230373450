import { inject, injectable,named } from "inversify";
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import { IToolbarService } from '../ToolbarService/ToolbarServiceTs';
import container from "../../Wires/Bootstrapper";
import { debug } from "util";
import { SearcherBaseModel } from "../../Models/SearcherBaseModel";
import { ProjectionPagedModel } from "../../Models/ProjectionPageModel";


export interface IBaseComponentService {
  getToolbar(): IToolbarService ;    
    RenderApi: string;
    getProviderName(): string;
}
@injectable()
export abstract class BaseComponentService implements IBaseComponentService {
    protected _toolBarService : IToolbarService = null;
    //chiave di registrazione del servizio specifico
    abstract getProviderName(): string;
    abstract RenderApi: string;

    constructor() {
        if (container.isBoundNamed(SERVICE_IDENTIFIER.TOOLBAR, this.getProviderName())) {
            this._toolBarService = container.get<IToolbarService>(SERVICE_IDENTIFIER.TOOLBAR)
            this._toolBarService.Identifier = this.getProviderName();
        }
    }

    getToolbar(): IToolbarService {
        
        return this._toolBarService;        
    }    
}