import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { ComponentService, IComponentService } from "./Shared/ComponentService";
import { IApiService } from "./Shared/ApiService";

import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { UtentiBOModel } from "../app/Contents/Utenti/PageUtentiBORicerca";
import { ModelBase } from "../Models/ModelBase";

export interface IUtentiService extends IComponentService<UtentiBOModel> {   
    DeleteUtenteBO(model: UtentiBOModel): Promise<ModelBase>
}

@injectable()
export class UtentiService extends ComponentService<UtentiBOModel> implements IUtentiService {
    protected SearchUrl: string = Api.GetUtentiBO;

    RenderApi: string = Api.RenderBO;    

    constructor() {
        super();        
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.UTENTIBOSERVICE;
    }

    async DeleteUtenteBO(model: UtentiBOModel): Promise<ModelBase> {
        const resp = await this._apiService.postAsync<UtentiBOModel>(Api.DeleteUtenteBO, model);
        return resp;
    }

    
}