import * as React from 'react'

export interface IHandlerDelegate {
    (data: any): void;
}


export class TPContext {
    IsDrawerEnabled: boolean = false
    ShowDrawer: (visible: boolean) => void
}

export const DispatcherContext = React.createContext(new TPContext());