import { injectable } from 'inversify'

import * as Api from "../Constants/ApiUrlConstants"

import container from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers"

import { IApiService } from "./Shared/ApiService";
import { ModelBase } from "../Models/ModelBase";

export interface IFileService {
    GetFile(FileId: string)
    GetFileRaw(code: string) 
}

@injectable()
export class FileService implements IFileService {

    apiService: IApiService

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
    }

    async GetFile(FileId: string) {
        var resp = await this.apiService.getAsync<ModelBase>(Api.PrepareFile, { FileId })
        this.GetFileRaw(resp.Code)
    }

    GetFileRaw(code: string) {
        var url = process.env.REACT_APP_BASEURL + Api.GetFile + "?Code=" + code
        window.open(url)
    }
}