import { Modal, message } from 'antd';
import { injectable } from 'inversify';
import * as React from 'react';
import { TTModal } from '../../app/Contents/TTModal';
export interface INotifyService {  
    error(title: string, content: string): void;
    validation(title: string, content: string): void;
    success(msg: string): void;
}

@injectable()
export class NotifyService implements INotifyService {
    OKTEXT = 'Chiudi';
    success(msg: string): void {
        message.success(msg)
    }

    error(title, content) {
        TTModal.error(
        {
            title,
            content,
            okText: this.OKTEXT
        });
    }

    validation(title, content) {
        TTModal.warning(
        {
            title,
            content,
            okText: this.OKTEXT
        });
    }
};

