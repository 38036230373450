import { IError, Error } from '../Models/ErrorStoreModel';

import { Reducer } from 'redux'
import container from '../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import { IErrorHandlerAction } from './Actions/ErrorHandlerAction';

  const reducer: Reducer<IError> = (state: IError = new Error(), action) => {
    const service =container.get(SERVICE_IDENTIFIER.ERROR_HANDLER_ACTION) as IErrorHandlerAction;
    return service.Eval(state,action);
  };
  
  export default reducer;