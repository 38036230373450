import * as React from 'react'
import { Form, Icon, Input, Button } from 'antd'
import { RouteComponentProps, withRouter } from "react-router"
import { FormComponentProps } from 'antd/lib/form'

import SERVICE_IDENTIFIER from '../../Wires/Identifiers'
import IoCContainer from '../../Wires/Bootstrapper'

import { IAuthService } from '../../Services/Security/AuthServiceTs'
import { IAccountService } from '../../Services/Security/AccountService'
import { RouteParamToken } from './RouteParamToken'

import logo from "./../../assets/images/Tpocket_Marchio.png"
import { LoginModel } from '../../Models/LoginModel'

export class ChangePassword extends React.Component<RouteComponentProps<RouteParamToken> & FormComponentProps, any>
{
    private service: IAccountService
    private authService: IAuthService

    constructor(props) {
        super(props)
        this.service = IoCContainer.get(SERVICE_IDENTIFIER.ACCOUNT_SERVICE)
        this.authService = IoCContainer.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
    }

    login = async (newPassword: string) => {
        let token = this.props.match.params.token
        let resp = await this.service.ChangePassword(newPassword, token)
        debugger
        if (resp.IsValid == true) {

            let model = new LoginModel()
            model.Token = token
            this.authService.signInAndReload(model)
        }
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props
        if (value && value !== form.getFieldValue('NewPassword')) {
            callback('Le password inserite non sono uguali!')
        } else {
            callback()
        }
    };

    doLogin = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) this.login(values['NewPassword'])
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form

        return (
            <div className="page-login">
                <div className="main-body">
                    <div className="body-inner">
                        <div className="box box-default">
                            <div className="box-body">

                                <section className="logo text-center">
                                    <img src={logo} width='70%' height='70%' />
                                </section>
                                <br />
                                <Form className="login-form">
                                    <Form.Item hasFeedback>
                                        {getFieldDecorator('NewPassword', {
                                            rules: [{ required: true, message: 'Inserisci la nuova password' }],
                                        })(
                                            <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="Password" placeholder="Nuova Password" />
                                        )}
                                    </Form.Item>
                                    <Form.Item hasFeedback>
                                        {getFieldDecorator('Password', {
                                            rules: [
                                                { required: true, message: 'Inserisci password' },
                                                { validator: this.compareToFirstPassword, }
                                            ],
                                        })(
                                            <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="Password" placeholder="Password" />
                                        )}
                                    </Form.Item>
                                    <br />
                                    <Button type="primary" htmlType="submit" className="submit-button" onClick={this.doLogin}>Cambia Password</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

}

export const ChangePasswordForm = withRouter(Form.create<RouteComponentProps & FormComponentProps>()(ChangePassword))