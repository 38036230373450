import * as React from 'react'
import { Card, Col, Input, List, Row } from 'antd'

const Search = Input.Search

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';

import { TTAvatar } from '../TTAvatar';
import { TTList } from '../TTList';

import { IClienteService } from '../../../Services/ClienteService';
import { GetClientiByGruppiSearcher } from '../../../Models/GetClientiByGruppoSearcher';
import { CustomerState } from '../Gruppi/GruppoCustomers';
import * as Constants from './../../../Constants/AppConstants'

export interface ListaCustomerProps {
    groups: Array<string>
}

export class ListaCustomers extends React.Component<ListaCustomerProps, CustomerState> {

    private _service: IClienteService
    private RigheLista: number = Constants.ListSizeClientiInForm;

    constructor(props) {
        super(props);

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE);
        this.state = new CustomerState();
    }

    onSearchTextChange = e => {
        this.setState({ FullText: e.target.value })       
    }

    onSearch = value => {
        this.Search(1)
    }

    Search = (page: number) => {
        
        let searcher: GetClientiByGruppiSearcher = {
            Gruppi: this.props.groups,
            Righe: this.RigheLista,
            Pagina: page
        }

        if (this.state.FullText != null && this.state.FullText != "") {
            
            searcher.FullText = this.state.FullText
        }

        this._service.GetClientiByGruppi(searcher).then(resp => this.setState({ ...this.state, Data: resp, Page: page }))
    }

    componentDidMount() {
        this.Search(1)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.groups.length != this.props.groups.length) {
            this.Search(1)
        }
    }

    onPageChange = page => {
        this.Search(page);
    }

    render() {
        return (
            <>
                <Row>
                    <Col offset={1} span={22} >
                        <Search placeholder="Cerca cliente" onChange={this.onSearchTextChange} onSearch={this.onSearch} enterButton value={this.state.FullText} />
                    </Col>
                </Row>
                <br />
                <TTList
                    itemLayout="horizontal"
                    dataSource={this.state.Data.Data || []}
                    pagination={{
                        onChange: (page) => this.onPageChange(page),
                        pageSize: this.RigheLista,
                        total: this.state.Data.Total,
                        showTotal: (total) => `${total} elementi`,
                        current: this.state.Page
                    }}
                    renderItem={item => (
                        <List.Item key={item.Id}>
                            <List.Item.Meta
                                avatar={<TTAvatar tticon="user" />}
                                title={item.Descrizione}
                            />
                        </List.Item>
                    )}
                        />
            </>
        );
    }
}