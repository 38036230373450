import * as React from 'react'
import { Card, Button } from 'antd';

import { FileSystemWindow } from '../FS/FileSystem'
import { FilesystemVirtualeStateEditModel } from '../../../Models/FilesystemVirtualeModels';
import { SignButton } from './SignButton';
import { TTModal } from '../TTModal'
import { StatoFirma, CambioStatoFirmaInput } from '../../../Models/FirmaModels';
import { ModalSetupSignature, SignaturePrepareData } from './ModalSetupSignature'

import * as SignState from "../../../Constants/SignConstants";
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';

import { IFirmaService } from '../../../Services/FirmaService';
import { IFSVirtualeService } from '../../../Services/FSVirtualeService';

export class SignFSWrapperState {
    public data: StatoFirma
    public selectedFile: FilesystemVirtualeStateEditModel
    public modalVisible: boolean
}

export interface ISignFSWrapperProps {
    clienteId: string
}

export class SignFSWrapper extends React.Component<ISignFSWrapperProps, SignFSWrapperState> {

    service: IFirmaService

    constructor(props) {
        super(props)

        this.service = IoCContainer.get(SERVICE_IDENTIFIER.FIRMA_SERVICE)
        var state = new SignFSWrapperState()
        state.data = new StatoFirma()

        this.state = state
    }


    handleSelectChange = (item: FilesystemVirtualeStateEditModel) => {
        if (item == null || item.IsDirectory) {
            this.setState({ selectedFile: null })
            return
        }

        this.setState({ selectedFile: item })
        this.service.GetStatoByFile(item.Id).then(resp => this.setState({ data: resp }))
    }

    restartProcess = () => {
        TTModal.confirm(
        {
            title: 'Attenzione',
            content: 'La pratica verrà ripristinata, vuoi continuare?',
            okText: 'Si',
            cancelText: 'No',
            onOk: async () => {
                let fileId = this.state.selectedFile.Id

                let resp = await this.service.RiavviaProceduraFirma(fileId)
                if (resp.IsValid) {
                    this.setState({ data: resp });
                    this.handlePostChangeState();
                }
            }
        })
    }

    changeState = () => {
        var firma = this.state.data
        if (firma.Stato == SignState.InizioProcesso) {
            this.toggleModal(true)
            return
        } else if (firma.Stato == SignState.Annullata) {
            this.restartProcess()
            return
        } else if (firma.UrlBozza) {
            window.open(firma.UrlBozza)
            this.clearUrlBozza()
            return
        }

        this.callCambioStato()
    }

    clearUrlBozza = () => {
        var firma = { ...this.state.data }
        firma.UrlBozza = ""
        this.setState({ data: firma })
    }

    toggleModal = (visible: boolean) => {
        this.setState({ modalVisible: visible })
    }

    cancelState = async () => {
        let fileId = this.state.selectedFile.Id

        let resp = await this.service.AnnullaFirma(fileId)
        if (resp.IsValid)
            this.setState({ data: resp })
    }

    callCambioStato = async (data: SignaturePrepareData = null) => {
        let input = new CambioStatoFirmaInput()
        input.FileId = this.state.selectedFile.Id

        if (data != null) {
            input.Users = data.users,
            input.TemplateId = data.templateId
        }

        let resp = await this.service.CambiaStato(input)
        if (resp.IsValid) {
            this.setState({ data: resp });
            this.handlePostChangeState();
        }
    }

    handlePostChangeState = () => {
        var firma = this.state.data

        console.log("ufirma", firma)
        if (firma.Stato == SignState.Bozza) {
            if (firma.UrlBozza) {
                console.log("url", firma.UrlBozza)
                window.open(firma.UrlBozza)
                this.clearUrlBozza()
                return
            }
        } else if (firma.Stato == SignState.Firmata && firma.File_Id != "") {
            let fsService: IFSVirtualeService = IoCContainer.get(SERVICE_IDENTIFIER.FSVIRTUALE_SERVICE);
            fsService.GetFile(firma.FilesystemVirtuale_Id);
            return
        }
    }

    okModal = (data: SignaturePrepareData) => {
        this.callCambioStato(data)
        this.toggleModal(false)
    }

    getButtons = () => {
        let signButton = <SignButton type="primary" key="signButton" state={this.state.data.Stato}
            onClick={() => this.changeState()}>{this.state.data.LabelTasto}</SignButton>

        let cancelButton = <Button onClick={() => this.cancelState()} key="cancelButton">Annulla</Button>

        var firma = this.state.data
        if (firma.Stato == SignState.Annullata || firma.Stato == SignState.InizioProcesso) {
            return [signButton]
        }

        return [signButton, cancelButton]
    }

    render() {
        const { clienteId } = this.props;

        let signLayout = <div />
        if (this.state.selectedFile != null) {
            signLayout = <Card bordered={false} title="Firma Digitale" extra={this.getButtons()} />
        }

        return (
            <div>
                {this.state.modalVisible ?
                    <ModalSetupSignature
                        visible={this.state.modalVisible}
                        closeModal={() => this.toggleModal(false)}
                        do={this.okModal}
                        clienteId={clienteId} /> : <></>}

                <FileSystemWindow clienteId={clienteId} selectedItem={this.handleSelectChange} />
                {signLayout}
                <br />
            </div>
        )
    }
}
