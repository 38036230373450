
// Configurazione specifica tPocket
export const firebaseConfig = {
    apiKey: "AIzaSyAA2qLPWi-4ipuHwLILiXM7t0CxWsLooHI",
    authDomain: "app-tpocket.firebaseapp.com",
    databaseURL: "https://app-tpocket.firebaseio.com",
    projectId: "app-tpocket",
    storageBucket: "",
    messagingSenderId: "12305949029",
    appId: "1:12305949029:web:3b667a4b8296ac113f018c"
};

// Voluntary Application Server Identification sempre specifico di tPocket
export const publicKey = 'BMeMjLKaywHWCfFgVftSy_gSka-FrLluvQEuC_MLYkzdlDjjm1XUT_CCwhkMPabfvIPfm-pvsmtD_8N-KkkTUgQ'
