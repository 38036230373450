import { ModelBase } from "./ModelBase"

export class TasksStatus extends ModelBase
{
    ActivitiesCount: number
    ScadenzeCount: number
    CircolariCount: number
    InformativeCount: number
    Fatturato: FatturatoDrawModel
}

export class FatturatoDrawModel {
    Current: Array<FatturatoTrendModel>
    Previous: Array<FatturatoTrendModel>
}

export class FatturatoTrendModel {
    Label: string
    Value: number
    FillColor: string
}

export abstract class TaskBaseItem extends ModelBase
{
    Date: Date
    Title: string
    Description: string
    ResourceId: string
    ResourceName: string
    TipoRecord: string
}
