import * as React from 'react'
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';

import { ButtonsItem } from '../../Services/ToolbarService/IToolbarModel';

interface ToolbarItemProps  {
    onButtonClick: (obj: any) => void
}

export const ToolbarItem: React.FC<ToolbarItemProps & ButtonsItem> = props => {

    const { name, description, isGroup, buttons, enabled, visible, onButtonClick, tipo, icon } = props;

    if (name) {
        var type = tipo ? tipo as ButtonType : "primary";
        return visible == true ? (
            <Button
                type={type}
                key={name}
                name={name}
                icon={icon}
                onClick={() => onButtonClick(props)}
                disabled={!enabled}>
                {description}
            </Button>
        )
            : null;
    }

    if (isGroup) {
        return (
            <Button.Group>
                {buttons.map(item => <ToolbarItem {...item} onButtonClick={e => props.onButtonClick(e)} />)}
            </Button.Group>
        );
    }

    return null;
}