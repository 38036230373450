import * as React from 'react'
import { PageHeader } from 'antd';
import { ToolbarItem } from './ToolbarItem';
import { ButtonsItem } from '../../Services/ToolbarService/IToolbarModel';
import { TTIcon } from '../../app/Contents/TTIcon';

interface PageHeaderToolbarProps {
    onButtonClick: (obj: any) => void
    onBackClick: () => void
    toolbarData: { [id: string]: ButtonsItem }
    title: string
}

export const PageHeaderToolbar: React.FC<PageHeaderToolbarProps> = props => {

    const { toolbarData } = props;
    let buttonDataArray: ButtonsItem[] = []

    // Trasformazione da dictionary ad array
    for (var key in toolbarData) {
        if (toolbarData.hasOwnProperty(key)) {
            buttonDataArray.push(toolbarData[key]);
        }
    }

    var buttons = buttonDataArray.map(data => <ToolbarItem {...data} onButtonClick={e => props.onButtonClick(e)} />)

    return (
        <PageHeader
            backIcon={<TTIcon icon="indietro" />}
            onBack={props.onBackClick}
            title={props.title}
            extra={buttons} />
    )
}