import { injectable } from 'inversify'
import { PROVIDERS_REGISTRATION } from '../Constants/IocConstants'
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService"

export interface IAppDocumentiService extends IBaseComponentService {

}

@injectable()
export class AppDocumentiService extends BaseComponentService implements IAppDocumentiService {
    RenderApi: string

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.APP_DOCUMENTI
    }
}