import * as React from 'react'
import { Select } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select';

export class TTSelect extends React.Component<SelectProps<SelectValue>, any> {

    constructor(props) {
        super(props)
    }

    render() {
        // optionFilterProp: children -> cerca sulla descrizione delle opzioni anzichè sulla chiave
        return (<Select optionFilterProp="children" {...this.props} />)
    }
}