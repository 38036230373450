import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import TTComponentBoxed from '../../../Shared/BaseComponent/TTComponentTs';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { SignFSWrapper } from '../FirmaDigitale/SignFSWrapper';
import { IFirmaService } from '../../../Services/FirmaService';

const ComponentService: symbol = SERVICE_IDENTIFIER.FIRMA_SERVICE;

export class PageFileSystem extends React.Component<any & IStoreModel & RouteComponentProps & ITTProps, any> {

    service: IFirmaService

    constructor(props) {
        super(props)

        this.service = IoCContainer.get(SERVICE_IDENTIFIER.FIRMA_SERVICE)
        this.service.getToolbar().addBackClick(() => this.handleBackClick())
    }

    handleBackClick() {
        const { match } = this.props;
        this.props.history.push("/Cliente/" + match.params.IdCliente)
    }

    render() {

        const { match } = this.props;
        return (
            <SignFSWrapper clienteId={match.params.IdCliente} />
        )
        //return (
        //    <FileSystemWindow clienteId={match.params.IdCliente} selectionMode={false} />
        //)
    }
}

const FileSystem = withRouter(TTComponentBoxed(PageFileSystem, 'Documenti Cliente', ComponentService));
export default FileSystem;