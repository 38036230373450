import { injectable } from "inversify";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import { ModelBase } from "../Models/ModelBase";
import { BackofficeResponseModel } from "../Models/SignalRResponseModel";

export interface IDrawerService extends IBaseComponentService {
    GetRisposte(): Promise<Array<BackofficeResponseModel>>
    CancellaRisposte(id: string): Promise<ModelBase>
}

@injectable()
export class DrawerService extends BaseComponentService implements IDrawerService {

    RenderApi = ''
    _apiService: IApiService;

    constructor() {
        super()
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE)
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.DRAWER;
    }

    async GetRisposte(): Promise<Array<BackofficeResponseModel>> {
        const resp = await this._apiService.getAsync<any>(Api.GetRisposte, {})
        return resp
    }

    async CancellaRisposte(id: string): Promise<ModelBase> {
        let data = id == null ? null : { IdRisposta: id }
        const resp = await this._apiService.getAsync<any>(Api.CancellaRisposte, data)
        return resp
    }
}