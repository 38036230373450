import * as React from 'react'
import { Modal, Form } from 'antd'

import { TTSelect } from '../TTSelect';
import { UserPicker } from './UserPicker';
import { IModalProps } from '../TTModal'

import { KeyValuePair } from '../../../Models/KeyValuePair'
import { renderSelectKeyValue } from '../SelectOptionRenderUtils'
import { IFirmaService } from '../../../Services/FirmaService';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import container from '../../../Wires/Bootstrapper'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    }
}

export interface ModalSetupSignatureProps {
    visible: boolean
    clienteId: string
}

export class SignaturePrepareData {
    public users: Array<string>
    public templateId: string
}

class ModalSetupSignatureState extends SignaturePrepareData {
    public TemplateList: Array<KeyValuePair>
}

export class ModalSetupSignature extends React.Component<IModalProps & ModalSetupSignatureProps, ModalSetupSignatureState> {

    service: IFirmaService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.FIRMA_SERVICE)
        this.state = new ModalSetupSignatureState()
    }

    componentDidMount() {
        this.load()
    }

    load = async () => {
        let resp = await this.service.GetTemplateList()
        if (resp.IsValid)
            this.setState({ TemplateList: resp.Template })
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                okText="Ok"
                cancelText="Annulla"
                title="Preparazione file per firma"
                onCancel={() => this.props.closeModal()}
                onOk={() => this.props.do(this.state)}>

                <h5>Seleziona template di firma (opzionale)</h5>
                <Form.Item>
                    <TTSelect
                        style={{ width: '100%' }} allowClear showSearch
                        value={this.state.templateId}
                        onChange={e => this.setState({ templateId: e as string })}>
                        {renderSelectKeyValue(this.state.TemplateList)}
                    </TTSelect>
                </Form.Item>
                <br />
                <br />
                <h5>Seleziona utenti firmatari</h5>
                <UserPicker clienteId={this.props.clienteId} selectedUsers={(users) => this.setState({ users })} />
            </Modal>
        )
    }
}