import { Reducer, Action } from 'redux';
import { IActionModel } from './Actions/ActionBase';
import container from '../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import { ApiLoaderStoreModel } from '../Models/ApiLoaderStoreModel';
import { IApiLoaderAction } from './Actions/ApiLoaderAction';
  
  const reducer: Reducer<ApiLoaderStoreModel> = (state: ApiLoaderStoreModel = new ApiLoaderStoreModel(), action) => {
    const service =container.get(SERVICE_IDENTIFIER.API_LOADER_ACTION) as IApiLoaderAction;
    return service.Eval(state,action);
  };
  
  export default reducer;