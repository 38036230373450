import { injectable } from 'inversify'
import { PROVIDERS_REGISTRATION } from '../Constants/IocConstants'
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService"

export interface IAppInfoAssistenzaService extends IBaseComponentService {

}

@injectable()
export class AppInfoAssistenzaService extends BaseComponentService implements IAppInfoAssistenzaService {
    RenderApi: string

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.APP_INFO_ASSISTENZA
    }
}