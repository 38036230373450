import * as React from 'react';
import { DatePicker, Row, Col, Input, List, Progress, Avatar, Button } from 'antd';

import { RouteComponentProps, withRouter } from 'react-router';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import { IAdempimentiService } from '../../../Services/AdempimentiService';
import { TTComponentBoxed } from '../../../Shared/BaseComponent/TTComponentTs';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { PagedBaseState } from '../../../Models/PagedBaseState';
import { ProjectionPagedModel } from '../../../Models/ProjectionPageModel';
import { AdempimentiProjection, AdempimentiSearcher } from '../../../Models/AdempimentiModels';
import { BaseFormReactComponent } from '../BaseReactComponent';
import { TTAvatar } from '../TTAvatar';
import { TTList } from '../TTList';
import * as Constants from './../../../Constants/AppConstants'

const Search = Input.Search;
const { MonthPicker } = DatePicker;

const ComponentService: symbol = SERVICE_IDENTIFIER.ADEMPIMENTI_SERVICE;

export class AdempimentiState extends PagedBaseState<AdempimentiProjection> {
    public Scadenza?: Date
}

export class PageAdempimenti extends BaseFormReactComponent<IStoreModel & RouteComponentProps & ITTProps, AdempimentiState> {

    private _service: IAdempimentiService;
    private RigheLista = Constants.ListSizePage;

    constructor(props) {
        super(props);
        this.state = this.props.componentData;

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.ADEMPIMENTI_SERVICE);
        this._service.getToolbar().addButtonClick("NuovoAdempimento", this.addAdempimento);
    }

    addAdempimento = () => {
        this.props.history.push('/AdempimentoEdit');
    }

    onMonthPicked = (date, dateString) => {
        if (date == null) {
            this.setState({ Scadenza: null })
            this.Search(1, null);
        } else {
            this.onChangeData(date, dateString, 'Scadenza')
            this.Search(1, this.state.Scadenza);
        }
    }

    onAdempimentoClick = item => {
        console.log(item)
        this.props.history.push('/Adempimento/' + item.Id);
    }

    Search = (page: number, scadenza: Date) => {
        let searcher: AdempimentiSearcher = {
            Righe: this.RigheLista,
            Pagina: page,
            FullText: this.state.Searcher,
            Scadenza: scadenza
        }

        this._service.Search(searcher).then(resp => this.setState({ Data: resp, Page: page }))
    }

    onSearch = value => {
        this.Search(1, this.state.Scadenza);
    }

    onPageChange = page => {
        this.Search(page, this.state.Scadenza);
    }

    render() {
        let list: ProjectionPagedModel<AdempimentiProjection> = this.state.Data;
        return (
            <div>
                <Row>
                    <Col offset={1} span={22}>
                        <Search placeholder="Cerca adempimento" onChange={this.onChangeText} onSearch={value => this.onSearch(value)} value={this.state.Searcher} name="Searcher" enterButton />
                    </Col>
                </Row>
                <br />
                <Row type="flex" justify="space-around">
                    <Col>
                        <MonthPicker onChange={this.onMonthPicked} format="MM-YYYY" placeholder="Seleziona mese" name="Scadenza" />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <TTList
                            itemLayout="horizontal"
                            dataSource={list.Data}
                            pagination={{
                                onChange: (page) => this.onPageChange(page),
                                pageSize: this.RigheLista,
                                total: this.state.Data.Total,
                                showTotal: (total) => `${total} elementi`,
                                current: this.state.Page
                            }}
                            renderItem={item => (
                                <List.Item
                                    key={item.Id}
                                    actions={[<Progress type="circle" percent={item.Percentuale} width={45} />]}>
                                    <List.Item.Meta
                                        avatar={<TTAvatar tticon="adempimenti" />}
                                        title={<a onClick={(event) => this.onAdempimentoClick(item)}>{item.Descrizione}</a>}
                                        description={item.Dettaglio}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const Adempimenti = withRouter(TTComponentBoxed(PageAdempimenti, 'Lista Adempimenti', ComponentService));
export default Adempimenti;