import { Reducer } from 'redux';
import { Actions } from './Actions/ActionTypesTs';
import container from '../../Wires/Bootstrapper'
import { IToolbarService, ToolbarProxy } from '../../Services/ToolbarService/ToolbarServiceTs';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
  
  const reducer: Reducer<ToolbarProxy> = (state: ToolbarProxy = null , action) => {
    let  _toolBarService : IToolbarService;
     
    switch ((action as Actions).type) {
        case 'REFRESH_TOOLBAR':
            return { ...action.payload.data }

        case 'ENABLEBUTTON_TOOLBAR':
            _toolBarService = container.getNamed<IToolbarService>(SERVICE_IDENTIFIER.TOOLBAR, action.payload.provider)
            return _toolBarService.Load();

        case 'RENDERBUTTON_TOOLBAR':
            _toolBarService = container.getNamed<IToolbarService>(SERVICE_IDENTIFIER.TOOLBAR, action.payload.provider)
            return _toolBarService.Load();
        
      default:
        return state;
    }
  };
  
  export default reducer;