
import { inject, injectable } from "inversify";
import { IToolbarModel, ButtonsItem } from "./IToolbarModel";
import SERVICE_IDENTIFIER from "../../Wires/Identifiers";
import IoCContainer from '../../Wires/Bootstrapper';
import { RefreshToolbar } from "../../Redux/Reducers/Actions/ActionsTS";
import { IApiService } from "../Shared/ApiService";
import * as Api from "../../Constants/ApiUrlConstants";
import { debug } from "util";

export type ToolbarProxy = { [id: string] : ButtonsItem; }

export interface IToolbarService {
    _generateProxy(toolbar: IToolbarModel): {};
    Load(): Promise<ToolbarProxy>;
    enableButton(name: string, enable: boolean): ToolbarProxy;
    renderButton(name: string, visible: boolean): ToolbarProxy;
    buttonClicked(name: string): void;
    addButtonClick(name: string, delegate: any): void;
    addBackClick( delegate: any): void;
    backClicked(): void;
    isBackEnabled: boolean;
    Identifier: string
}

@injectable() 
export class ToolbarService implements IToolbarService {

    public Identifier: string

    public proxy: ToolbarProxy;
    backCallback: () => any;
    isBackEnabled: boolean;
    store : any
    apiService: IApiService

    actionDelegates: { [id: string]: any; } = {};
    renderDelegates: { [id: string]: any; } = {};
    
    constructor() { 
        console.log('ToolbarService constructor');
        this.proxy = {};
        this.store = IoCContainer.get(SERVICE_IDENTIFIER.STORE);
        this.apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    async getData(): Promise<IToolbarModel>{
        return await this.apiService.getAsync<IToolbarModel>(Api.GetToolbar, { componentKey: this.Identifier }); 
    }

    _generateProxy(toolbar : IToolbarModel) {        
        let prx: ToolbarProxy = {}; 
        this.isBackEnabled = toolbar.isBackEnabled || false;
        if (toolbar.left != null) { 
          toolbar.left.buttons.forEach((btn : ButtonsItem) => {
            if (btn.name != null) {
              prx[btn.name] = {...btn};
            }
            // if (btn.buttons != null) { 
            //   return this._generateProxy(btn.buttons);
            // }
            // return prx; 
          });
        } 
    
        if (toolbar.right != null && toolbar.right.buttons != null) {
          toolbar.right.buttons.map((btn) => {
            if (btn.name != null) {
              prx[btn.name] = {...btn};
            }
            return prx;
          });
        }
        
        return prx;
      }


    async Load(): Promise<ToolbarProxy> {
        let data = await this.getData();
        if (data == null) return null;
        this.proxy = this._generateProxy(data);
        for (let key in this.actionDelegates) {
            console.log('Load proxy : ' + JSON.stringify(this.proxy) + ' \n action ' + key + ' ' + this.actionDelegates[key])
            if (this.proxy[key] != null) this.proxy[key].action = this.actionDelegates[key];
            // Use `key` and `value`
        }

        for (let key in this.renderDelegates) {
            console.log('Load proxy : ' + JSON.stringify(this.proxy) + ' render ' + key + ' ' + this.renderDelegates[key])
            if (this.proxy[key] !=null) this.proxy[key].visible = this.renderDelegates[key];
            // Use `key` and `value`
        }

        this.actionDelegates = {};
        this.renderDelegates = {};
        console.log('Load proxy : ' + JSON.stringify(this.proxy))
         return this.proxy;
      }
    
    enableButton(name: string, enable: boolean) {
          this.proxy[name].enabled = enable;
          this.store.dispatch(RefreshToolbar(this.proxy))

        return this.proxy;
      }
    
    renderButton(name: string, visible: boolean) {

        if (!(name in this.proxy)) {
            this.renderDelegates[name] = !visible;
        }
        else {
            if (this.proxy[name] != null) {
                this.proxy[name].visible = !visible;
                this.store.dispatch(RefreshToolbar(this.proxy))
            }
        }

        return this.proxy;
      }
    
      buttonClicked(name: string) {
        this.proxy[name].action();
      }
    
      ///aggiunta evento a specifico pulsante della toolbar
    addButtonClick = (name: string, delegate: any)=> {
        console.log('addButtonClick proxy : ' + JSON.stringify(this.proxy) + ' name : ' + name)
        if (!(name in this.proxy)) {
            this.actionDelegates[name] = delegate;
        }
        else {
            if (this.proxy[name] != null)this.proxy[name].action = delegate;
        }
            
    }

    addBackClick( delegate: any) {
        this.backCallback = delegate;
    }

    backClicked(): void {
        if (this.backCallback != null) this.backCallback();
    }
};     

  
