import * as React from 'react'
import {
    FlexibleWidthXYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries
} from 'react-vis'

import { ChartHintState } from './../../../Models/ChartHintState'
import { TTChartBaseComponent } from './../TTChartBaseComponent'


export interface IAdempimentiChartProps {
    todoColor: string
    inProgressColor: string
    doneColor: string
    todo: Array<any>
    inProgress: Array<any>
    done: Array<any>
}

export class AdempimentiChart extends TTChartBaseComponent<IAdempimentiChartProps, ChartHintState<any>> {

    constructor(props) {
        super(props)

        this.state = new ChartHintState<any>()
    }

    getHintLayout(data: any) {
        return (<div>{data.Descrizione}: {data.y}</div>)
    }

    render() {
        return (
            <FlexibleWidthXYPlot margin={{ bottom: 50, left: 60 }} xType="ordinal" height={300}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis />
                <YAxis />
                <VerticalBarSeries
                    barWidth={undefined}
                    color={this.props.todoColor}
                    onValueMouseOver={value => this.onShowHint(value)}
                    onValueMouseOut={() => this.onHideHint()}
                    data={this.props.todo}
                />
                <VerticalBarSeries
                    barWidth={undefined}
                    color={this.props.inProgressColor}
                    onValueMouseOver={value => this.onShowHint(value)}
                    onValueMouseOut={() => this.onHideHint()}
                    data={this.props.inProgress}
                />
                <VerticalBarSeries
                    barWidth={undefined}
                    color={this.props.doneColor}
                    onValueMouseOver={value => this.onShowHint(value)}
                    onValueMouseOut={() => this.onHideHint()}
                    data={this.props.done}
                />
                {this.state.hint ? this.buildHint() : null}
            </FlexibleWidthXYPlot>
        )
    }
}