import * as React from 'react';
import { Row, Col, List, Input, Button } from 'antd';
import { withRouter, RouteComponentProps } from "react-router";
import { TTComponentBoxed } from "../../../Shared/BaseComponent/TTComponentTs";
import { FormComponentProps } from 'antd/lib/form';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { ProjectionPagedModel } from '../../../Models/ProjectionPageModel';
import { SearcherBaseModel } from '../../../Models/SearcherBaseModel';
import { BaseReactComponent } from '../BaseReactComponent';
import { TTAvatar } from '../TTAvatar';
import { TTList } from '../TTList';
import { TTIconButton } from '../TTIconButton';
import * as Constants from './../../../Constants/AppConstants'
import { PagedBaseState } from '../../../Models/PagedBaseState';
import { ModelBase } from '../../../Models/ModelBase';
import { IUtentiService } from '../../../Services/UtentiService';
import { TTModal } from '../TTModal';

const Search = Input.Search;
const ComponentService: symbol = SERVICE_IDENTIFIER.UTENTI_SERVICE;


export class UtentiBOModel extends ModelBase {
    Id: string
    UserName: string
    Password: string
    LastLogin: Date
    LastLogout: Date
    Role: string
    RoleList: { [key: string]: string; } = {}
    EditMode: boolean
}

export class UtentiBOModelState extends PagedBaseState<UtentiBOModel> {

}



export class PageUtentiBORicerca extends BaseReactComponent<IStoreModel & RouteComponentProps & ITTProps & FormComponentProps, UtentiBOModelState, UtentiBOModel> {
    protected getServiceIdentifier(): symbol {
        return SERVICE_IDENTIFIER.UTENTI_SERVICE;
    }

    //private _service: IGruppoService;
    private RigheLista = Constants.ListSizePage;

    constructor(props) {
        super(props);

        this.state = this.props.componentData || new UtentiBOModelState();

        //this._service = IoCContainer.get(SERVICE_IDENTIFIER.GRUPPO_SERVICE);
        this.service.getToolbar().addButtonClick("Nuovo", this.addUtente);
    }

    addUtente = () => {
        this.props.history.push('/UtenteBOEdit')
    }

    onSearch = text => {
        this.setState({ ...this.state, Searcher: text });
        this.DoSearch(this.state.Page);
    }

    onUtenteClick = (item: UtentiBOModel) => {
        console.log(item);
        this.props.history.push('UtenteBOEdit/' + item.Id);
    }

    onEliminaClick = (item: UtentiBOModel) => {
        console.log(item);
        TTModal.confirm(
            {
                title: 'Attenzione',
                content: 'L\'utente verrà eliminato, vuoi continuare?',
                okText: 'Si',
                cancelText: 'No',
                onOk: () => {
                    (this.service as IUtentiService).DeleteUtenteBO(item).then((r) => {
                        if (r.IsValid) this.DoSearch(1);
                    });
                }
            })
        
    }

    onPageChange = page => {
        this.DoSearch(page);
    }

    private DoSearch(page: any) {
        const searcher: SearcherBaseModel = {
            Righe: this.RigheLista,
            Pagina: page,
            FullText: this.state.Searcher
        };

        this.service.Search(searcher).then(resp => this.setState({ ...this.state, Data: resp, Page: page }));
    }


    render() {
        debugger;
        let list: ProjectionPagedModel<UtentiBOModel> = this.state.Data;

        return (
            <div>
                <Row>
                    <Col offset={1} span={22} >
                        <Search placeholder="Cerca Utente"
                            onSearch={value => this.onSearch(value)}
                            onChange={this.onChangeText}
                            name="Searcher"
                            value={this.state.Searcher}
                            enterButton />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <TTList
                            itemLayout="horizontal"
                            dataSource={list.Data}
                            pagination={{
                                onChange: (page) => this.onPageChange(page),
                                pageSize: this.RigheLista,
                                total: list.Total,
                                showTotal: (total) => `${total} elementi`,
                                current: this.state.Page
                            }}
                            renderItem={item => (
                                <List.Item
                                    actions={[<TTIconButton tticon="modifica" onClick={() => this.onUtenteClick(item)} />,
                                        <TTIconButton tticon="elimina" onClick={() => this.onEliminaClick(item)} />]}
                                    key={item.Id}>
                                    <List.Item.Meta
                                        avatar={<TTAvatar tticon="clienti" />}
                                        title={item.UserName}
                                        description={item.Role}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const UtentiBO = withRouter(TTComponentBoxed(PageUtentiBORicerca, 'Lista Utenti', ComponentService));
export default UtentiBO;