import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { DeviceTokenInputModel } from "../Models/DeviceTokenInputModel";
import { ModelBase } from "../Models/ModelBase";

export interface IDeviceService {
    PostDeviceInfo(data: DeviceTokenInputModel): Promise<ModelBase>
}

@injectable()
export class DeviceService implements IDeviceService {

    _apiService: IApiService;

    constructor() {
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    async PostDeviceInfo(data: DeviceTokenInputModel): Promise<ModelBase> {
        var resp = this._apiService.postAsync<ModelBase>(Api.PostDeviceInfo, data)
        return resp
    }
}