import * as React from 'react'
import { Select, Form, List } from 'antd'
import moment from 'moment'
import { withRouter, RouteComponentProps } from 'react-router'

import { ActivityItem } from '../../Models/AppActivityModels'

import { TTAvatar } from '../Contents/TTAvatar'
import { TTSelect } from '../Contents/TTSelect'
import { TTList } from '../Contents/TTList'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    }
}

export enum FiltroAttivita {
    DaCompletare,
    Tutto
}

export interface TaskListComponentProps {
    list: Array<ActivityItem>
    filter: FiltroAttivita
    onFilterChange: (data: FiltroAttivita) => void
    formLabel: string
}

export class TaskListComponentComp extends React.Component<RouteComponentProps & TaskListComponentProps, any> {
    constructor(props) {
        super(props)
    }

    navigateToAttivita = (id: string) => {
        this.props.history.push(`/Attivita/${id}`)
    }

    render() {
        return (
            <>
                <Form.Item {...formItemLayout} label={this.props.formLabel}>
                    <TTSelect value={this.props.filter} onChange={e => this.props.onFilterChange(e as FiltroAttivita)} style={{ width: '100%' }}>
                        <Select.Option value={FiltroAttivita.DaCompletare}>Solo da completare</Select.Option>
                        <Select.Option value={FiltroAttivita.Tutto}>Tutto</Select.Option>
                    </TTSelect>
                </Form.Item>
                <TTList
                    dataSource={this.props.list}
                    rowKey={it => it.Id}
                    itemLayout="horizontal"
                    pagination={{ pageSize: 10 }}
                    renderItem={item => (
                        <List.Item key={item.Id} >
                            <List.Item.Meta
                                avatar={<TTAvatar tticon="attivita" />}
                                title={<a onClick={() => this.navigateToAttivita(item.Id)}>{item.Title}</a>}
                                description={moment(item.Date).format('LL')} />
                        </List.Item>
                    )}
                />
            </>
        )
    }
}
export const TaskListComponent = withRouter(TaskListComponentComp)