import * as React from 'react'
import { Card, List } from 'antd';
import { AvatarNotification } from '../AvatarNotification';

export const ClienteUtenteNotification: React.FC<any> = props => {


    const notifications = [
        {
            status: 'success',
            title: 'Caricami la fattura',
            description: '20 Mag 2019 17:44',
        },
        {
            status: 'warning',
            title: 'Caricami la fattura',
            description: '20 Mag 2019 17:44',
        },
        {
            status: 'error',
            title: 'Caricami la fattura',
            description: '20 Mag 2019 17:44',
        },
    ];

    return (
        <Card bordered={false} title="Notifiche">
            <List
                itemLayout="horizontal"
                dataSource={notifications}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<AvatarNotification />}
                            title={item.title}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
}