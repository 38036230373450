import { inject, injectable } from "inversify";
import { IApiService } from './Shared/ApiService';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import * as Api from "../Constants/ApiUrlConstants";
import { ProjectionPagedModel } from '../Models/ProjectionPageModel';
import { IBaseComponentService, BaseComponentService } from './Shared/BaseComponentService';
import IoCContainer from '../Wires/Bootstrapper';
import { CrAssUserViewModel } from "../Models/UserViewModel";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";

export interface IUserServiceEdit extends IBaseComponentService
{
    GetInitialModelForComponent(id:string) : Promise<CrAssUserViewModel>;
    SaveUser(model: CrAssUserViewModel) : Promise<CrAssUserViewModel>;    
}

const provider = PROVIDERS_REGISTRATION.EDITUTENTI;

@injectable()
export class UserServiceEdit extends BaseComponentService implements IUserServiceEdit {
    getProviderName(): string {
        return provider;
    }
    RenderApi: string = Api.RenderUserEdit;
    _apiService:IApiService;
    constructor()
    {        
        super();
        this._apiService=IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
        
    }
    
    async GetInitialModelForComponent(id:string) : Promise<CrAssUserViewModel>
    {
        const resp = await this._apiService.getAsync<CrAssUserViewModel>(Api.UserEdit, null);
        return resp;
    }

    async SaveUser(model: CrAssUserViewModel) : Promise<CrAssUserViewModel>
    {
        const resp = await this._apiService.postAsync<CrAssUserViewModel>(Api.SaveUser, model);
        return resp;
    }
}
