import * as React from 'react'
import { Card, List } from 'antd'

import moment from 'moment'
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'
import IoCContainer from '../../../Wires/Bootstrapper'

import { TTPagedList } from '../TTPagedList'
import { AvatarNotification } from './../AvatarNotification'
import { IClienteService } from '../../../Services/ClienteService'
import { PagedBaseState } from '../../../Models/PagedBaseState'
import { RichiesteProjection } from '../../../Models/RichiesteModels'
import { ClienteSearcher } from '../../../Models/UtentiSearcher'
import { IClienteBaseProps } from '../../../Models/IClienteBaseProps'

import * as Constants from './../../../Constants/AppConstants'

export class ClienteNotificationsState extends PagedBaseState<RichiesteProjection> { }

export class ClienteNotifications extends React.Component<IClienteBaseProps, ClienteNotificationsState> {

    private service: IClienteService;
    private RigheLista = Constants.ListSize.ClienteRichieste

    constructor(props) {
        super(props)
        this.service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE)
        this.state = new ClienteNotificationsState()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.clienteId != this.props.clienteId) {
            this.onPageChange(1)
        }
    }

    onPageChange = (page: number) => {
        var searcher: ClienteSearcher = {
            ClienteId: this.props.clienteId,
            Pagina: page,
            Righe: this.RigheLista
        }

        this.service.GetClienteRichieste(searcher).then(resp => this.setState({ Data: resp, Page: page }))
    }

    formatDate = (item: RichiesteProjection) => {
        return item.CreationDate == null ? 'Data sconosciuta' : moment(item.CreationDate).format('LLL')
    }

    render() {
        return (
            <Card bordered={false} title="Notifiche Recenti" className="card-full-height">
                <TTPagedList
                    itemLayout="horizontal"
                    dataSource={this.state.Data.Data}
                    style={{ height: '350px' }}
                    pagination={{
                        onChange: (page) => this.onPageChange(page),
                        pageSize: this.RigheLista,
                        total: this.state.Data.Total,
                        showTotal: (total) => `${total} elementi`,
                        current: this.state.Page
                    }}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<AvatarNotification />}
                                title={item.Descrizione}
                                description={this.formatDate(item)}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        )
    }
}