import * as React from 'react'
import { Card, List, Avatar } from 'antd'
import { IClienteService } from '../../../Services/ClienteService';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { PagedBaseState } from '../../../Models/PagedBaseState';
import { UtentiProjection } from '../../../Models/UtentiProjection';
import { ClienteSearcher } from '../../../Models/UtentiSearcher';
import moment from 'moment'
import { TTAvatar } from '../TTAvatar';
import { TTPagedList } from '../TTPagedList';
import * as Constants from './../../../Constants/AppConstants'

export interface IClienteUtentiProps {
    Cliente: string
}

export class ClienteUtentiState extends PagedBaseState<UtentiProjection> {}

export class ClienteUtenti extends React.Component<any & IClienteUtentiProps, ClienteUtentiState> {

    private _service: IClienteService;
    private RigheLista = Constants.ListSize.ClienteUtenti

    constructor(props) {
        super(props)

        this.state = new ClienteUtentiState()
        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.Cliente != this.props.Cliente) {
            this.onPageChange(1)
        }
    }

    onUserClick = (idUtente) => {
        this.props.history.push('/ClienteUtente/' + this.props.Cliente + '/' + idUtente)
    }

    onPageChange = (page: number) => {
        var searcher: ClienteSearcher = {
            ClienteId: this.props.Cliente,
            Pagina: page,
            Righe: this.RigheLista
        }

        this._service.GetClienteUtenti(searcher).then(resp => this.setState({ Data: resp, Page: page }))
    }

    formatDate = (item: UtentiProjection) => {
        return item.UltimoAccesso == null ? 'Sconosciuto' : moment(item.UltimoAccesso, 'DD/MM/YYYY hh:mm').format('LLL')
    }

    render() {
        return (
            <Card bordered={false} title="Utenti" className="card-full-height">
                <TTPagedList
                    itemLayout="horizontal"
                    dataSource={this.state.Data.Data}
                    style={{ height: '325px' }}
                    pagination={{
                        onChange: (page) => this.onPageChange(page),
                        pageSize: this.RigheLista,
                        total: this.state.Data.Total,
                        showTotal: (total) => `${total} elementi`,
                        current: this.state.Page
                    }}
                    renderItem={item => (
                        <List.Item key={item.Id}>
                            <List.Item.Meta
                                avatar={<TTAvatar tticon="user" />}
                                title={<a onClick={() => this.onUserClick(item.Id)}>{item.FirstName} {item.LastName}  ({item.UserName})</a>}
                                description={"Ultimo Accesso: " + this.formatDate(item)}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        )
    }
}