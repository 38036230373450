import { IActionBase, ActionBase } from "./ActionBase";
import { injectable } from "inversify";
import { IError } from "../../Models/ErrorStoreModel";
import { IErrorServer } from "../../../Models/ServerErrorModel";

export interface IErrorHandlerAction extends IActionBase<IError> {
    ShowError(data: IErrorServer);
    RemoveError();
}

@injectable()
export class ErrorHandlerActions extends ActionBase<IError> implements IErrorHandlerAction {
    ERROR_SHOW_TYPE = 'ERROR_SHOW';
    ERROR_REMOVE_TYPE = 'ERROR_REMOVE';


    ShowError(data: IErrorServer) {
        // Mostro lo stacktrace solo su console
        console.log("Errore non gestito: ", data)

        // Notifico la ui
        this.Dispatch({ isError: true, message: data }, this.ERROR_SHOW_TYPE);
    };

    ERROR_SHOW(): any {

        return {
            ...this.state,
            ...this.action.payload
        };
    }

    RemoveError() {
        this.Dispatch({ isError: false }, this.ERROR_REMOVE_TYPE);
    };

    ERROR_REMOVE(): any {
        return {
            ...this.state,
            ...this.action.payload
        };
    }

}