import { IActionBase, ActionBase } from "./ActionBase";
import { HeaderModel } from "../HeaderTs";
import { Action } from "redux";
import { injectable } from "inversify";

export interface IHeadRefreshAction extends IActionBase<HeaderModel>{
    RefreshHead(model:HeaderModel);
}

@injectable()
export class HeadRefreshAction extends ActionBase<HeaderModel> implements IHeadRefreshAction
{
    REFRESH_HEAD_TYPE ='REFRESH_HEAD';    

    RefreshHead(model:HeaderModel){
        this.Dispatch(model,this.REFRESH_HEAD_TYPE);
    }

    //metodo invocato dal reducer usando il type
    //il suo nome deve battere pari con la costante passata al metodo dispatch
    REFRESH_HEAD():any
    {        
        return {...this.state,
                ...this.action.payload};
    }   
}