import * as React from 'react';
import { Col, Row } from 'antd';

import { IStoreModel } from "../../../Redux/Reducers/IStoreModel";
import { ITTProps } from "../../../Shared/BaseComponent/ITTProps";
import {RouteComponentProps} from "react-router";
import { withRouter } from 'react-router-dom';
import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import IoCContainer from '../../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IHomeService } from '../../../Services/HomeService';

import { RichiesteInCorso } from './RichiesteInCorso'
import { AdempimentiComponent } from './AdempimentiComponent';
import { DispatcherContext } from '../../../Services/Shared/Dispatcher-Context';
import { ISignalRServiceHandler } from '../../../Services/Shared/SignalRServiceHandler';


const ComponentService: symbol = SERVICE_IDENTIFIER.HOME_SERVICE;

export class PageHome extends React.Component<IStoreModel & RouteComponentProps & ITTProps , any>                        
{       
    

    constructor(props)
    {
        super(props); 
        
    }

  

    render() {
        return (
            <div>
                <Row>
                    <Col><AdempimentiComponent /></Col>
                </Row>
                <br />
                <Row>
                    <Col><RichiesteInCorso /></Col>
                </Row>
                <br />
            </div>
        );
        
    }
    

}

PageHome.contextType = DispatcherContext;
const Home = withRouter(TTComponent(PageHome,'Home', ComponentService));
export default Home;