import * as React from 'react'
import { Button, Collapse } from 'antd';

import SERVICE_IDENTIFIER from "../../../Wires/Identifiers";
import IoCContainer from '../../../Wires/Bootstrapper';
import { IFSVirtualeService } from '../../../Services/FSVirtualeService';

import { FilesystemVirtualeStateEditModel, FilesystemVirtualeUploadModel } from './../../../Models/FilesystemVirtualeModels'

import { BaseFileSystem } from './BaseFileSystem';
import { TTUploadLayout } from '../TTUploadLayout';
import { TTModal } from '../TTModal';
import { ModalRenameFile } from './ModalRenameFile';
import { ModalMoveFile } from './ModalMoveFile';
import { TTMultiUpload } from '../TTMultiUpload'

export class FileSystemState {
    public Id: string
    public Data: FilesystemVirtualeStateEditModel

    public Uploading: boolean
    public Files: Array<File> = []

    public SelectedItem: FilesystemVirtualeStateEditModel

    public RenameModal: boolean
    public MoveModal: boolean
}

export interface IFileSystemProps {
    clienteId: string
    selectionMode?: boolean
    selectedItem?: (item: FilesystemVirtualeStateEditModel) => void
    showUploader?: boolean
    foldersOnly?: boolean
}

export class FileSystemWindow extends React.Component<IFileSystemProps, FileSystemState> {

    _service: IFSVirtualeService

    static defaultProps = {
        selectionMode: false,
        showUploader: true,
        foldersOnly: false
    }

    constructor(props) {
        super(props)

        this.state = new FileSystemState()
        this._service = IoCContainer.get(SERVICE_IDENTIFIER.FSVIRTUALE_SERVICE)
    }

    async componentDidMount() {
        let resp = await this._service.GetRootFolder(this.props.clienteId)
        this.loadFolder(resp.Id)
    }

    loadFolder = async (id: string) => {
        let resp = await this._service.GetById(id, this.props.foldersOnly)
        this.setState({ Id: resp.Id, Data: resp });
    }

    selectItem = (item: FilesystemVirtualeStateEditModel) => {
        this.setState({ SelectedItem: item })
        this.props.selectedItem(item)
    }

    openFolder = (item: FilesystemVirtualeStateEditModel) => {
        this.loadFolder(item.Id)
    }

    openFile = (item: FilesystemVirtualeStateEditModel) => {
        this._service.GetFile(item.Id)
    }

    reloadFolder = () => {
        this.loadFolder(this.state.Id)
    }

    onBack = (parentId: string) => {
        this.loadFolder(parentId)
    }

    /*** Upload ***/
    onUpdateUploadList = (fileList: Array<File>) => {
        this.setState({ Files: fileList })
    }

    startUpload = async () => {
        this.setState({
            Uploading: true,
        })

        const { Id, Files } = this.state
        var model: FilesystemVirtualeUploadModel = {
            Parent_Id: Id,
            Files,
            Cliente_Id: this.props.clienteId
        }

        await this._service.Upload(model)
        this.setState({
            Uploading: false,
            Files: []
        });
        this.reloadFolder()
    }

    /**** Delete Modal ****/
    onFileDelete = () => {
        TTModal.confirm({
            title: "Eliminare il file?",
            content: "Non sarà possibile annullare l'operazione",
            onOk: async () => {
                let resp = await this._service.Delete(this.state.SelectedItem.Id)
                if (resp.IsValid) {
                    // Annullo la selezione del documento in quanto non più esistente
                    this.selectItem(null)
                    this.reloadFolder()
                }
            }
        })
    }

    /*** Rename Modal ***/
    toggleRenameModal = (visible: boolean) => {
        this.setState({ RenameModal: visible })
    }

    RenameCallback = async (data: string) => {
        const itm = { ...this.state.SelectedItem };
        itm.Code = data

        let resp = await this._service.Rename(itm)
        if (resp.IsValid) {
            this.reloadFolder()
        }
    }

    /*** Move Modal ***/
    toggleMoveModal = (visible: boolean) => {
        this.setState({ MoveModal: visible })
    }

    MoveCallback = async (parentFolderId: string) => {
        const itm = { ...this.state.SelectedItem };
        itm.Parent_Id = parentFolderId

        let resp = await this._service.Move(itm)
        if (resp.IsValid) {
            // Annullo la selezione del documento in quanto non più presente
            this.selectItem(null)
            this.reloadFolder()
        }
    }

    getPageHeaderButtons = () => {
        if (!this.state.SelectedItem) return []

        let arr = []
        if (this.state.SelectedItem.IsDirectory == false && this.props.selectionMode == false) {
            arr.push([
                <Button type="primary" key="buttonDel" onClick={this.onFileDelete}>Elimina</Button>,
                <Button type="primary" key="buttonRen" onClick={() => this.toggleRenameModal(true)}>Rinomina</Button>,
                <Button type="primary" key="buttonMove" onClick={() => this.toggleMoveModal(true)}>Sposta</Button>
            ])
        }

        return arr
    }

    renderUpload = () => {
        if (this.props.showUploader == false)
            return <></>

        const { Uploading, Files } = this.state

        return (
            <Collapse>
                <Collapse.Panel header="Caricamento File" key="1">
                    <TTMultiUpload type="drag"
                        updateList={this.onUpdateUploadList}
                        files={Files} >
                        <TTUploadLayout />
                    </TTMultiUpload>
                    <br />
                    <Button type="primary" onClick={() => this.startUpload()} disabled={Files.length == 0} loading={Uploading}>
                        Inizia caricamento
                    </Button>
                </Collapse.Panel>
            </Collapse>
        )
    }

    render() {
        return (
            <>
                <BaseFileSystem
                    data={this.state.Data}
                    extraItemOptions={this.getPageHeaderButtons()}
                    onBack={e => this.onBack(e)}
                    onOpenFolder={e => this.openFolder(e)}
                    onOpenFile={e => this.openFile(e)}
                    onSelectedItem={e => this.selectItem(e)}
                    selectedItem={this.state.SelectedItem}>
                    {this.renderUpload()}
                </BaseFileSystem>

                <ModalMoveFile
                    clienteId={this.props.clienteId}
                    visible={this.state.MoveModal}
                    closeModal={() => this.toggleMoveModal(false)}
                    do={this.MoveCallback} />

                <ModalRenameFile
                    currentFile={this.state.SelectedItem}
                    visible={this.state.RenameModal}
                    closeModal={() => this.toggleRenameModal(false)}
                    do={this.RenameCallback} />
            </>
        );
    }
}
