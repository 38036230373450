import { injectable } from 'inversify'

import * as Api from "../Constants/AppApiUrlConstants";

import { ModelBase } from '../Models/ModelBase';
import { FilesystemVirtualeStateEditModel } from '../Models/FilesystemVirtualeModels';

import { IFSVirtualeServiceBase, FSVirtualeServiceBase } from './FSVirtualeServiceBase'

export interface IAppFSVirtualeService extends IFSVirtualeServiceBase {
    GetFolder(Id: string): Promise<FilesystemVirtualeStateEditModel>
    Save(file: File, folderId: string, clienteId: string)
}

@injectable()
export class AppFSVirtualeService extends FSVirtualeServiceBase implements IAppFSVirtualeService {

    constructor() {
        super()
    }

    async GetFolder(Id: string): Promise<FilesystemVirtualeStateEditModel> {
        return await this._apiService.getAsync<FilesystemVirtualeStateEditModel>(Api.GetFolder, { Id })
    }

    async Save(file: File, folderId: string, clienteId: string) {
        let formData = new FormData()
        formData.append("Id", folderId)
        formData.append("Cliente_Id", clienteId)
        formData.append("FileCaricato", file)

        return await this._apiService.postAsync<ModelBase>(Api.SaveFile, formData)
    }
}