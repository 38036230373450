import * as React from 'react'
import { Hint } from 'react-vis'

import { ITTVisHintProps } from './../../Models/ITTVisHintProps'

export const TTVisHint: React.FC<ITTVisHintProps> = props => {
    return (
        <Hint {...props}>
            <div className="rv-hint__content">
                {props.children}
            </div>
        </Hint>
    )
}