import * as React from 'react'
import { List } from 'antd'
import moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router'

import { IAppTaskService } from '../../../Services/AppTaskService'
import container from '../../../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'

import { CircolariList } from '../../../Models/AppCircolariModels'

import { TTList } from '../../Contents/TTList'
import { TTAvatar } from '../../Contents/TTAvatar'
import { TTComponentBoxed } from '../../../Shared/BaseComponent/TTComponentTs'
import { IAppCircolariService } from '../../../Services/AppCircolariService'

class PageCircolariState extends CircolariList {
    IsLoading: boolean
}

export class PageCircolariComp extends React.Component<RouteComponentProps, PageCircolariState> {

    service: IAppTaskService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.APP_TASK_SERVICE)
        this.state = new PageCircolariState()

        let toolbar = container.get<IAppCircolariService>(SERVICE_IDENTIFIER.APP_CIRCOLARI_LISTA_SERVICE).getToolbar()
        toolbar.addBackClick(() => this.props.history.push("/"))
    }

    async componentDidMount() {
        this.setState({ IsLoading: true })

        let resp = await this.service.GetCircolari()
        if (resp.IsValid)
            this.setState(resp)

        this.setState({ IsLoading: false })
    }

    navigateToCircolare = (id: string) => {
        this.props.history.push(`/Circolare/${id}`)
    }

    render() {
        return (
            <TTList
                dataSource={this.state.Circolari}
                rowKey={it => it.Id}
                itemLayout="horizontal"
                pagination={{ pageSize: 10 }}
                loading={this.state.IsLoading}
                renderItem={item => (
                    <List.Item key={item.Id} >
                        <List.Item.Meta
                            avatar={<TTAvatar tticon="circolari" />}
                            title={<a onClick={() => this.navigateToCircolare(item.Id)}>{item.Title}</a>}
                            description={moment(item.Date).format('LL')} />
                    </List.Item>
                )}
            />
        )
    }
}

export const PageCircolari = withRouter(TTComponentBoxed(PageCircolariComp, 'Circolari', SERVICE_IDENTIFIER.APP_CIRCOLARI_LISTA_SERVICE))