import * as React from 'react'
import { Input, Form, Modal } from 'antd'
import { IModalProps } from '../TTModal'
import { ModalProps } from 'antd/lib/modal'
import { FormComponentProps } from 'antd/lib/form'
import { BaseFormReactComponent } from '../BaseReactComponent'


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    }
}

class ModalEmailState {
    Email: string
}

export class ModalEmailComp extends BaseFormReactComponent<IModalProps & ModalProps & FormComponentProps, ModalEmailState> {

    static EmailKey: string = "email"

    constructor(props) {
        super(props)
    }

    closeEdit = () => {
        this.props.form.resetFields()
        this.props.closeModal()
    }

    onCreate = () => {
        this.props.form.validateFields((error, values) => {
            if (error == null) {
                this.props.do(values);
                this.closeEdit()
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Modal visible={this.props.visible} onCancel={this.closeEdit} title="Indirizzo E-Mail richiesto" onOk={() => this.onCreate()} okText="Abilita">
                Inserisci l'indirizzo e-mail a cui mandare l'url per il pagamento e le eventuali notifiche di rinnovo
                <Form>
                    <br />
                    <Form.Item {...formItemLayout} label="E-Mail">
                        {getFieldDecorator(ModalEmailComp.EmailKey, {
                        rules: [
                            {
                                type: 'email',
                                message: 'Non è un indirizzo e-mail valido',
                            },
                            {
                                required: true,
                                message: 'Indirizzo email vuoto',
                            },
                        ],
                    })(<Input />)}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export const ModalEmail = Form.create<ModalProps & IModalProps & FormComponentProps>()(ModalEmailComp);
