import * as React from 'react'
import { Col, Row, Statistic, Icon } from 'antd'
import { TTIcon } from '../../Contents/TTIcon'

interface TileProps {
    tticon: string
    title: string
    value: number
    onClick: () => void
}

export const Tile: React.FC<TileProps> = (props) => {
    return (
        <Row type="flex" align="middle" style={{ marginTop: '8px', marginBottom: '8px'}}>
            <Col md={2} lg={4} xl={6} span={4}><TTIcon style={{ fontSize: 45 }} icon={props.tticon} ttstyle="accent" /></Col>
            <Col md={20} lg={20} xl={18} span={18}><Statistic title={props.title} value={props.value} suffix={<a onClick={() => props.onClick()}><Icon type="arrow-right" /></a>} /></Col>
        </Row>
    )
}