import React from 'react';
import { connect } from 'react-redux';
import { TTIcon } from '../../../app/Contents/TTIcon';
import { 
  toggleCollapsedNav,
  toggleOffCanvasNav
} from '../../../Actions/Actions';


class NavLeft extends React.Component {

  onToggleCollapsedNav = () => {
    const { handleToggleCollapsedNav, collapsedNav } = this.props;
    handleToggleCollapsedNav(!collapsedNav);
  }

  onToggleOffCanvasNav = () => {
    const { handleToggleOffCanvasNav, offCanvasNav } = this.props;
    handleToggleOffCanvasNav(!offCanvasNav);
  }

    render() {
        const { /*collapsedNav,*/ offCanvasNav } = this.props;
        //let collapse =
        //    <li className="list-inline-item hidden-md-down">
        //        <TTIcon
        //            className="header-icon app-sidebar-toggler"
        //            icon="chiudiMenu"
        //            rotate={collapsedNav ? 180 : 0}
        //            onClick={this.onToggleCollapsedNav} />
        //    </li>

        return (
            <ul className="header-list list-unstyled list-inline">
                <li className="list-inline-item">
                    <TTIcon
                        className="header-icon app-sidebar-toggler"
                        icon="chiudiMenu"
                        style={{ fontSize: 20 }}
                        rotate={offCanvasNav ? 180 : 0}
                        onClick={this.onToggleOffCanvasNav} />
                </li>
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    collapsedNav: state.settings.collapsedNav,
    offCanvasNav: state.settings.offCanvasNav,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    handleToggleCollapsedNav: (isCollapsedNav) => {
      dispatch( toggleCollapsedNav(isCollapsedNav) );
    },
    handleToggleOffCanvasNav: (isOffCanvasNav) => {
      dispatch( toggleOffCanvasNav(isOffCanvasNav) );
    },
  }
}

export default  connect(
  mapStateToProps,
  mapDispatchToProps
)(NavLeft);
