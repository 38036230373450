import * as React from 'react';
import { Row, Button, Col } from 'antd';
import { SingleFileUpload } from '../SingleFileUpload';

import { TTComponentBoxed } from '../../../Shared/BaseComponent/TTComponentTs';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { RouteComponentProps, withRouter } from 'react-router';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import IoCContainer from '../../../Wires/Bootstrapper';
import { IImportFatturatoService } from '../../../Services/ImportFatturatoService';
import { ImportBaseState } from '../../../Models/ImportBaseState';

const ComponentService: symbol = SERVICE_IDENTIFIER.IMPORT_FATTURATO_SERVICE;

class PageImportFatturato extends React.Component<IStoreModel & RouteComponentProps & ITTProps, ImportBaseState> {

    private _service: IImportFatturatoService

    constructor(props) {
        super(props)

        this.state = this.props.componentData || new ImportBaseState()
        this._service = IoCContainer.get(SERVICE_IDENTIFIER.IMPORT_FATTURATO_SERVICE)
    }

    startUpload = () => {
        this._service.Upload(this.state).then(() => {
            this.setState({ file: null });
        });
    }

    handleAttachmentChange = (item: File) => {
        this.setState({ file: item })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <SingleFileUpload SelectedItem={this.handleAttachmentChange} file={this.state.file} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Button type="primary" onClick={() => this.startUpload()} disabled={this.state.file == null}>Importa</Button>
                    </Col>
                </Row>
            </div>
        )
    }
}


const ImportFatturato = withRouter(TTComponentBoxed(PageImportFatturato, 'Importazione Fatturato', ComponentService));
export default ImportFatturato;