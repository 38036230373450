import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';

import { Card, Row, Col, Form, Input, Select, Radio } from 'antd';

import { AttachmentSelection } from './../AttachmentSelection'

import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';

import { FormComponentProps } from 'antd/lib/form';
import { BaseReactComponent } from '../BaseReactComponent';
import { ModelBase } from '../../../Models/ModelBase';
import { IAdempimentoNotificationService } from '../../../Services/AdempimentoNotificationService';
import { FilesystemVirtualeStateEditModel } from '../../../Models/FilesystemVirtualeModels';
import { TTSelect } from '../TTSelect';

const TextArea = Input.TextArea;

const ComponentService: symbol = SERVICE_IDENTIFIER.ADEMPIMENTO_NOTIFICATION_SERVICE;


export class RichiesteStateModel extends ModelBase {
    Note: string;
    Attivita: string;
    Adempimento: string;
    Cliente: string;
    TipoRisposta: string;
    TipoRispostaSource: { [key: string]: string; } = {};
    Destinatario: string[];
    DestinatarioSource: { [key: string]: string; } = {};
    Firmata: boolean;
    Allegato: string;
    Descrizione: string;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    }
}

export class PageAdempimentoNotification extends BaseReactComponent<IStoreModel & RouteComponentProps & ITTProps & FormComponentProps & any, RichiesteStateModel,any> {

    constructor(props) {
        super(props);

        this.saveActivity = this.saveActivity.bind(this);

        this.service.getToolbar().addBackClick(() => this.props.history.push("/Adempimenti"));
        this.service.getToolbar().addButtonClick("Salva", this.saveActivity);

        this.state = new RichiesteStateModel();
    }

    protected getServiceIdentifier(): symbol {
        return SERVICE_IDENTIFIER.ADEMPIMENTO_NOTIFICATION_SERVICE;
    }

    componentDidMount() {
        const { match } = this.props;
        (this.service as IAdempimentoNotificationService).GetByCliente(this.props.match.params.IdCliente).then(resp => {
            resp.Adempimento = match.params.IdAdempimento;
            resp.Cliente = match.params.IdCliente;
            resp.Attivita = match.params.IdAttivita;
            this.setState(resp);
        });
    }

    saveActivity = () => {
        const { match } = this.props;
        this.validateAndSaveExtra(() => (this.service as IAdempimentoNotificationService).SaveRichiesta(this.state),
            (resp => {
                if (resp.IsValid == true)
                    this.props.history.push(`/AdempimentoCustomer/${match.params.IdCliente}/${match.params.IdAdempimento}`)
            })
        )
    }

    handleWhoChange = who => {
        debugger;
        this.setState({ Destinatario : who})
    }

    handleRespType = respType => {
        this.setState({ TipoRisposta: respType.target.value })
    }

    handleAttachmentChange = (item: FilesystemVirtualeStateEditModel) => {
        this.setState({ Allegato: item.Id })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <Card bordered={false} title="Nuova Attività Cliente">
                            <Row>
                                <Col span={24}>
                                    <Form>
                                        <Form.Item  {...formItemLayout} label="Descrizione">
                                            {this.getRequiredField(<Input name="Descrizione" size="small" onChange={this.onChangeText} />)}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Note">
                                            <TextArea name="Note" defaultValue={this.state.Note} onChange={this.onChangeText} autosize={{ minRows: 5 }} />
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Tipo Risposta">
                                            {this.getRequiredFieldInit(null,
                                                <Radio.Group onChange={this.handleRespType} name="TipoRisposta">
                                                    {this.renderRadioButton(this.state.TipoRispostaSource)}
                                                </Radio.Group>
                                            )}
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Destinatario">
                                            {this.getRequiredFieldFull("Destinatario", [],
                                                <TTSelect onChange={this.handleWhoChange} mode="multiple">
                                                    {this.renderSelectDictionary(this.state.DestinatarioSource)}
                                                </TTSelect>)
                                            }
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Card bordered={false} title="Allegato">
                            <AttachmentSelection userId={this.state.Cliente} selectedItem={this.handleAttachmentChange} />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const pageForm = Form.create()(PageAdempimentoNotification)
const AdempimentoNotification = withRouter(TTComponent(pageForm, 'Notifica Cliente', ComponentService));
export default AdempimentoNotification;