import { injectable } from "inversify";

import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import * as Api from "../Constants/ApiUrlConstants";
import { SearcherBaseModel } from "../Models/SearcherBaseModel";
import { AdempimentiProjection, AdempimentiSearcher } from "../Models/AdempimentiModels";
import { ProjectionPagedModel } from "../Models/ProjectionPageModel";

export interface IAdempimentiService extends IBaseComponentService {
    Search(model: SearcherBaseModel): Promise<ProjectionPagedModel<AdempimentiProjection>>
}

@injectable()
export class AdempimentiService extends BaseComponentService implements IAdempimentiService {

    RenderApi: string = Api.Render; 
;   
    _apiService: IApiService;

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.ADEMPIMENTI;
    }

    async Search(model: AdempimentiSearcher): Promise<ProjectionPagedModel<AdempimentiProjection>> {
        return await this._apiService.postAsync<any>(Api.SearchAdempimenti, model);
    }
}