import { ModelBase } from "./ModelBase";

export interface ILoginModel
{
    //UserName: string;
    //PassWord: string;
    Token: string;
}

export class LoginModel extends ModelBase implements ILoginModel
{
    //UserName: string;    
    //PassWord: string; 
    Token: string; 
    IsTemporanyPassword: boolean;
}

export class ChangePasswordModel {
    NewPassword: string;
    PassWord: string;
}
