import * as React from 'react'
import { Card, List, Avatar, Row, Col } from 'antd'
import { TTList } from '../TTList'
import TTComponent from '../../../Shared/BaseComponent/TTComponentTs'
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import { ILicenzeService } from '../../../Services/LicenzeService';
import IoCContainer from '../../../Wires/Bootstrapper';
import { PagedBaseState } from '../../../Models/PagedBaseState';
import { LicenzeProjection } from '../../../Models/LicenzeModels';
import * as Constants from './../../../Constants/AppConstants'
import moment from 'moment';
import { SearcherBaseModel } from '../../../Models/SearcherBaseModel';
import { ISignalRServiceHandler } from '../../../Services/Shared/SignalRServiceHandler';
import { ToastMessageModel } from '../../../Models/SignalRResponseModel';
import { TTAvatarLicenza } from './TTAvatarLicenza'
import Search from 'antd/lib/input/Search';

const ComponentService: symbol = SERVICE_IDENTIFIER.LICENZE_SERVICE;

export class PageLicenze extends React.Component<RouteComponentProps & ITTProps, PagedBaseState<LicenzeProjection>> {

    private RigheLista = Constants.ListSizePage;
    service: ILicenzeService
    signalRService: ISignalRServiceHandler;

    constructor(props) {
        super(props)
        this.state = this.props.componentData

        this.service = IoCContainer.get(SERVICE_IDENTIFIER.LICENZE_SERVICE);
        this.signalRService = IoCContainer.get(SERVICE_IDENTIFIER.SIGNALR);

        this.service.getToolbar().addButtonClick("Buy", this.buy);
    }

    componentDidMount() {
        this.signalRService.AddEventHandlerFor('LicenseRecieved', this.onLicenseReceived);
    }

    componentWillUnmount() {
        this.signalRService.RemoveEventHandlerFor('LicenseRecieved', this.onLicenseReceived);
    }

    onLicenseReceived = () => {
        this.service.Render().then(resp => {
            this.setState(resp)
        })
    }

    buy = () => {
        this.service.CompraLicenza().then(it => {
            if (it.IsValid) {
                debugger
                window.open(it.UrlPagamento)
            }
        })
    }

    navigate = (item: LicenzeProjection) => {
        this.props.history.push(`/LicenzaClienti/${item.LicenzaId}`)
    }

    search = (page: number) => {
        let searcher: SearcherBaseModel = {
            Righe: this.RigheLista,
            Pagina: page,
            FullText: this.state.Searcher
        }
        this.service.Search(searcher).then(it => this.setState({ Data: it, Page: page }))
    }

    render() {
        const { Data } = this.state
        return (
            <Card bordered={false} title="Seleziona una licenza per continuare">
                <Row>
                    <Col offset={1} span={22}>
                        <Search
                            placeholder="Cerca licenza per chiave"
                            onChange={e => this.setState({ Searcher: e.target.value })}
                            onSearch={value => this.search(1)}
                            value={this.state.Searcher}
                            enterButton />
                    </Col>
                </Row>
                <br />
                <TTList
                    dataSource={Data.Data}
                    pagination={{
                        onChange: (page) => this.search(page),
                        pageSize: this.RigheLista,
                        total: this.state.Data.Total,
                        showTotal: (total) => `${total} elementi`,
                        current: this.state.Page
                    }}
                    renderItem={item => (
                        <List.Item key={item.Id}>
                            <List.Item.Meta
                                avatar={<TTAvatarLicenza clientiRimanenti={item.ClientiRimanenti} stato={item.Stato} />}
                                title={<a onClick={() => this.navigate(item)}>{`Licenza per ${item.NumeroClienti} clienti`}</a>}
                                description={`Scadenza ${moment(item.Scadenza).format('L')} - ${item.ClientiRimanenti} disponibili`}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        )
    }
}

const Licenze = withRouter(TTComponent(PageLicenze, 'Licenze', ComponentService))
export default Licenze