import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import container from '../../../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'

import { ActivityItem } from '../../../Models/AppActivityModels'

import { IAppTaskService } from '../../../Services/AppTaskService'

import { FiltroAttivita, TaskListComponent } from '../TaskListComponent'
import { TTComponentBoxed } from '../../../Shared/BaseComponent/TTComponentTs'
import { IAppInformativeListaService } from '../../../Services/AppInformativeService'

class PageInformativeState {
    List: Array<ActivityItem>
    Filtro: FiltroAttivita
}

export class PageInformativeComp extends React.Component<RouteComponentProps, PageInformativeState> {

    service: IAppTaskService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.APP_TASK_SERVICE)
        this.state = new PageInformativeState()

        let toolbar = container.get<IAppInformativeListaService>(SERVICE_IDENTIFIER.APP_INFORMATIVE_LISTA_SERVICE).getToolbar()
        toolbar.addBackClick(() => this.props.history.push("/"))
    }

    componentDidMount() {
        this.loadAttivita(FiltroAttivita.DaCompletare)
    }

    loadAttivita = async (filtro: FiltroAttivita) => {
        this.setState({ Filtro: filtro })
        let resp = await this.service.GetInfoActivities(filtro == FiltroAttivita.DaCompletare)
        if (resp.IsValid)
            this.setState({ List: resp.Activities })
    }

    onFilterChange = (filtro: FiltroAttivita) => {
        this.loadAttivita(filtro)
    }

    render() {
        return (
            <TaskListComponent
                formLabel="Visualizza informative"
                list={this.state.List}
                filter={this.state.Filtro}
                onFilterChange={e => this.onFilterChange(e)} />
        )
    }
}
export const PageInformative = withRouter(TTComponentBoxed(PageInformativeComp, 'Informative', SERVICE_IDENTIFIER.APP_INFORMATIVE_LISTA_SERVICE))