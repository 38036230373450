import { injectable } from "inversify"

import container from "../../Wires/Bootstrapper";
import SERVICE_IDENTIFIER from "../../Wires/Identifiers"

import * as Api from "../../Constants/ApiUrlConstants";

import { IApiService } from "./../Shared/ApiService";
import { IApiServiceExt } from "./../Shared/ApiServiceExt";

import { LoginModel } from "../../Models/LoginModel";
import { ModelBase } from "../../Models/ModelBase";

export interface IAccountService {
    Login(UserName: string, Password: string): Promise<LoginModel>
    ChangePassword(NewPassword: string, token: string): Promise<ModelBase>
    Logout(): Promise<any>
}

@injectable()
export class AccountService implements IAccountService {

    apiService: IApiService
    apiServiceExt: IApiServiceExt

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
        this.apiServiceExt = container.get(SERVICE_IDENTIFIER.API_SERVICE_EXT)
    }

    async Login(UserName: string, Password: string): Promise<LoginModel> {
        return this.apiService.postAsync<LoginModel>(Api.Login, { UserName, Password })
    }

    async ChangePassword(NewPassword: string, token: string): Promise<ModelBase> {
        return this.apiService.postTokenAsync<ModelBase>(Api.RestorePassword, { NewPassword }, token)
    }

    async Logout(): Promise<any> {
        return this.apiService.getAsync<any>(Api.Logout, {})
    }
}