import { ModelBase } from "./ModelBase"

export class OfficeData extends ModelBase
{
    Name: string
    PhoneNumber: string
    Address: string
    Latitude: number
    Longitude: number
    Orario: Array<OrarioUfficio>
}

export class OrarioUfficio
{
    OrarioMattina: string
    OrarioPomeriggio: string
    Giorno: string
}

export class SupportoAppModel {
    Telefono: string
    Mail: string
    OrariUfficio: Array<string>
    LinkPrivacy: string
    LinkCondizioni: string
}
