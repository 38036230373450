import * as React from 'react';
import { Row, Col } from 'antd';
import { RouteComponentProps } from "react-router";
import { withRouter } from 'react-router';

import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { IAdempimentoCustomerService } from '../../../Services/AdempimentoCustomerService';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';

import { AdempimentoCover } from './AdempimentoCover';
import { AdempimentoCustomerActivities } from './AdempimentoCustomerActivities';
import { AdempimentoState } from './PageAdempimentoEdit';

const ComponentService: symbol = SERVICE_IDENTIFIER.ADEMPIMENTO_CUSTOMER_SERVICE;

export class PageAdempimentoCustomer extends React.Component<any & IStoreModel & RouteComponentProps & ITTProps, AdempimentoState> {

    private _service: IAdempimentoCustomerService;

    constructor(props) {
        super(props);
        this.state = new AdempimentoState();

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.ADEMPIMENTO_CUSTOMER_SERVICE);
        this._service.getToolbar().addBackClick(() => this.props.history.push("/Adempimenti"));
    }

    componentDidMount() {
        this.loadAdempimento()
    }

    componentDidUpdate(prevProps, prevState) {
        // Aggiorno se arriv da drawer notifiche
        if (this.props.match.params.IdAdempimento != prevProps.match.params.IdAdempimento) {
            this.loadAdempimento()
        }
    }

    loadAdempimento = () => {
        this._service.GetAdemimento(this.props.match.params.IdAdempimento).then((resp: AdempimentoState) => {
            this.setState(resp);
        });
    }


    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <AdempimentoCover
                            title={this.state.Descrizione}
                            description={this.state.Note}
                            date={this.state.Scadenza} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <AdempimentoCustomerActivities {...this.props}  />
                    </Col>
                </Row>
                <br />
            </div>
        );
    }
}

const AdempimentoCustomer = withRouter(TTComponent(PageAdempimentoCustomer, 'Adempimento per il cliente', ComponentService));
export default AdempimentoCustomer;