import * as React from 'react'
import { Card, List } from 'antd'
import moment from 'moment'

import { TTAvatar } from '../TTAvatar'
import { TTPagedList } from '../TTPagedList'
import * as Constants from './../../../Constants/AppConstants'

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'
import IoCContainer from '../../../Wires/Bootstrapper'
import { PagedBaseState } from '../../../Models/PagedBaseState'
import { ScadenzeProjection } from '../../../Models/ScadenzeProjection'
import { IClienteBaseProps } from '../../../Models/IClienteBaseProps'
import { ClienteSearcher } from '../../../Models/UtentiSearcher'
import { IClienteService } from '../../../Services/ClienteService'

export class ClienteScadenzeState extends PagedBaseState<ScadenzeProjection> { }

export class ClienteScadenze extends React.Component<IClienteBaseProps, ClienteScadenzeState> {

    private service: IClienteService;
    private RigheLista = Constants.ListSize.ClienteScadenze

    constructor(props) {
        super(props)
        this.service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE)
        this.state = new ClienteScadenzeState()
    }

    componentDidUpdate(prevProps, PrevState) {
        if (prevProps.clienteId != this.props.clienteId) {
            this.onPageChange(1)
        }
    }

    onPageChange = (page: number) => {
        var searcher: ClienteSearcher = {
            ClienteId: this.props.clienteId,
            Pagina: page,
            Righe: this.RigheLista
        }

        this.service.GetClienteScadenze(searcher).then(resp => this.setState({ Data: resp, Page: page }))
    }

    formatDate = (item: ScadenzeProjection) => {
        return item.Scadenza == null ? 'Scadenza sconosciuta' : moment(item.Scadenza).format('LL')
    }

    formatEuro = value => {
        let formatter = new Intl.NumberFormat("it-IT", {
            style: "currency",
            currency: "EUR"
        });
        return formatter.format(value)
    }

    render() {
        return (
            <Card bordered={false} title="Scadenze" className="card-full-height">
                <TTPagedList
                    itemLayout="horizontal"
                    dataSource={this.state.Data.Data}
                    pagination={{
                        onChange: (page) => this.onPageChange(page),
                        pageSize: this.RigheLista,
                        total: this.state.Data.Total,
                        showTotal: (total) => `${total} elementi`,
                        current: this.state.Page
                    }}
                    style={{ height: '350px'}}
                    renderItem={item => (
                        <List.Item
                            key={item.Id}>
                            <List.Item.Meta
                                avatar={<TTAvatar tticon="scadenze" />}
                                title={item.Descrizione}
                                description={this.formatDate(item) + " - " + this.formatEuro(item.Ammontare)} />
                        </List.Item>
                    )}
                />
            </Card>
        )
    }
}