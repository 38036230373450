import * as React from 'react'
import { Row, Col, Input, Button, message, Descriptions, Card } from 'antd';
import { withRouter } from 'react-router';

import IoCContainer from '../../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { TTComponent } from '../../../Shared/BaseComponent/TTComponentTs';

import { PageSupportModel } from '../../../Models/PageSupportModel'
import { ISupportService } from '../../../Services/SupportService';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import { TPDescriptions } from '../TPDescriptions';

class PageSupportState extends PageSupportModel {
    UserMessage: string
}

const ComponentService: symbol = SERVICE_IDENTIFIER.SUPPORT_SERVICE;
export class PageSupportComp extends React.Component<ITTProps, PageSupportState>{

    _service: ISupportService;

    constructor(props) {
        super(props)
        this.state = this.props.componentData || new PageSupportState();
        this._service = IoCContainer.get(SERVICE_IDENTIFIER.SUPPORT_SERVICE);
    }

    onSend = async () => {
        let resp = await this._service.InviaRichiesta(this.state.UserMessage)
        if (resp.IsValid) {
            message.success("Richiesta di supporto inviata con successo. Riceverai una email di risposta entro qualche giorno", 5)
            this.setState({ UserMessage: "" })
        }
    }

    render()
    {
        return (
            <>
                <Row type="flex" gutter={24}>

                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                        <Card bordered={false} title="Tootech" >
                            <TPDescriptions column={2}>
                                <Descriptions.Item label="Numero Di Telefono">
                                    <a target="_blank" href={`tel:${this.state.Telefono}`}>
                                        {this.state.Telefono}
                                    </a>
                                </Descriptions.Item>
                                <Descriptions.Item label="Orari Apertura Uffici">{
                                    this.state.OrariUfficio.map((orari, i) => <span>{orari}<br /></span>)
                                }</Descriptions.Item>
                            </TPDescriptions>
                        </Card>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                        <Card bordered={false} title="Manuale e condizioni d'uso di tPocket" className="card-full-height">
                            Per consultare il manuale d'uso di tPocket&nbsp;
                            <a target="_blank" rel="noopener noreferrer" href={this.state.LinkManuale}>clicca qui</a>.<br />
                            Per consultare le condizioni d'uso di tPocket&nbsp;
                            <a target="_blank" rel="noopener noreferrer" href={this.state.LinkCondizioni}>clicca qui</a>.<br />
                            Non hai trovato quello che cercavi? Contattaci utilizzando la form sottostante!
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row type="flex" justify="space-around" align="middle">
                    <Col span={24}>
                        <Card bordered={false} title="Richiesta di supporto" className="card-full-height">
                            <p>Inserisci la tua richiesta di supporto nel campo di testo qui sotto e clicca "Invia" per inviarla a Tootech.<br />
                                Il nostro team di help desk ti risponderà entro qualche giorno.
                            </p>
                            <Row type="flex">
                                <Col span={24}>
                                    <Input.TextArea value={this.state.UserMessage} onChange={e => this.setState({ UserMessage: e.target.value })} rows={10} />
                                </Col>
                            </Row>
                            <br />
                            <Row type="flex" justify="end">
                                <Col><Button type="primary" onClick={() => this.onSend()}>Invia</Button></Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}
export const PageSupport = withRouter(TTComponent(PageSupportComp, 'Help & Support', ComponentService));