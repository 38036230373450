import { injectable } from 'inversify'
import { PROVIDERS_REGISTRATION } from '../Constants/IocConstants'
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService"

export interface IAppScadenzeListaService extends IBaseComponentService { }

@injectable()
export class AppScadenzeListaService extends BaseComponentService implements IAppScadenzeListaService {
    RenderApi: string

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.APP_SCADENZE_LIST
    }

}