import { IActionModel } from "../Reducers/Actions/ActionBase";

export interface IApiLoaderStoreModel extends IActionModel {
    isLoading: boolean;
    counter: number
  }

export class ApiLoaderStoreModel implements IApiLoaderStoreModel{
    isLoading: boolean;    
    counter: number;
    constructor()
    {
        this.isLoading = false;
        this.counter = 0;
    }
}