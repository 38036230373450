import * as React from 'react';
import { RadialChart } from 'react-vis';
import { ChartHintState } from './../../../Models/ChartHintState';
import { AdempimentiStatoModel } from '../../../Models/AdempimentiClienteModels';
import { TTChartBaseComponent } from '../TTChartBaseComponent';

export interface IAdempimentiDonutChart {
    onClickFilter(id: string);
    data: Array<AdempimentiStatoModel>
}

export class AdempimentiDonutChart extends TTChartBaseComponent<IAdempimentiDonutChart, ChartHintState<AdempimentiStatoModel>> {
    
    constructor(props) {
        super(props);

        this.state = new ChartHintState<AdempimentiStatoModel>()
    }

    getHintLayout(hint: any) {
        return (<div>{hint.Description}: {hint.Count}</div>)
    }

    render() {
        return (
            <RadialChart
                innerRadius={100}
                radius={140}
                getAngle={item => item.Count}
                colorType="literal"
                data={this.props.data}
                onValueClick={(event) => this.props.onClickFilter(event.Code)}
                onValueMouseOver={v => this.onShowHint(v)}
                onSeriesMouseOut={() => this.onHideHint()}
                width={300}
                height={300}
                padAngle={0.02} >
                {this.state.hint ? this.buildHint() : null}
            </RadialChart>
        );
    }
}