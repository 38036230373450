import * as React from 'react'
import { Card, Table } from 'antd';
import { SearcherBaseModel } from '../../../Models/SearcherBaseModel';
import IoCContainer from '../../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IHomeService } from '../../../Services/HomeService';
import { RichiesteDashboardModel } from '../../../Models/DashboardModels';
import { ProjectionPagedModel } from '../../../Models/ProjectionPageModel';
import { PagedBaseState } from '../../../Models/PagedBaseState';
import moment from 'moment'
import { TTTable } from '../TTTable';
import { ISignalRServiceHandler } from '../../../Services/Shared/SignalRServiceHandler';
import * as Constants from './../../../Constants/AppConstants'

const columns = [
    {
        title: 'Richiesta',
        dataIndex: 'Richiesta',
        render: (text, row, index) => text
            //<a onClick={event => null/*this.onCustomerClick(row.Id)*/}>{text}</a>
    },
    {
        title: 'Ragione Sociale',
        dataIndex: 'RagioneSociale'
    },
    {
        title: 'Adempimento',
        dataIndex: 'Adempimento'
    },
    {
        title: 'Attività',
        dataIndex: 'Attivita'
    },
    {
        title: 'Data apertura richiesta',
        dataIndex: 'DataRichiesta',
        render: (item: Date) => moment(item).format('LLL')
    }
];

export class RichiesteInCorso extends React.Component<any, PagedBaseState<RichiesteDashboardModel>> {

    private service: IHomeService
    signalRService: ISignalRServiceHandler;
    private RigheLista = Constants.ListSize.HomeRichieste

    constructor(props) {
        super(props)
        this.service = IoCContainer.get(SERVICE_IDENTIFIER.HOME_SERVICE);
        this.signalRService = IoCContainer.get(SERVICE_IDENTIFIER.SIGNALR);

        this.state = new PagedBaseState<RichiesteDashboardModel>();
    }

    onResponse = (data) => {
        this.onPageChange(1)
    }

    componentDidMount() {
        debugger

        this.onPageChange(1)
        this.signalRService.AddEventHandlerFor('ResponseRecieved', this.onResponse);
    }

    componentWillUnmount() {
        this.signalRService.RemoveEventHandlerFor('ResponseRecieved', this.onResponse);
    }


    onPageChange = page => {
        const searcher: SearcherBaseModel = {
            Pagina: page,
            Righe: this.RigheLista
        };

        this.service.searchRichieste(searcher).then(resp => this.setState({ Data: resp, Page: page }));
    }

    render() {
        let list: ProjectionPagedModel<RichiesteDashboardModel> = this.state.Data;
        return (
            <Card bordered={false} title="Richieste in corso">
                <TTTable columns={columns} dataSource={list.Data} rowKey="Id"
                    pagination={{
                        onChange: (page) => this.onPageChange(page),
                        pageSize: this.RigheLista,
                        total: list.Total,
                        showTotal: (total) => `${total} elementi`,
                        current: this.state.Page
                    }} />
            </Card>
        )
    }
}