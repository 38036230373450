import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { DeepLinkOutputModel } from "../Models/DeepLinkModels";

export interface IDeepLinkService {
    GetDataByToken(token: string): Promise<DeepLinkOutputModel>
}

@injectable()
export class DeepLinkService implements IDeepLinkService {

    _apiService: IApiService;

    constructor() {
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    async GetDataByToken(token: string): Promise<DeepLinkOutputModel> {
        var resp = this._apiService.getAsync<DeepLinkOutputModel>(Api.GetDataByToken, { Token: token })
        return resp
    }
}