import * as React from 'react'
import { Card, Row, Col, Descriptions } from 'antd'
import { Marker } from 'react-leaflet'


import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'
import container from '../../../Wires/Bootstrapper'

import { IAppOfficeService } from '../../../Services/AppOfficeService'
import { OfficeData } from '../../../Models/AppOfficeModels'

import { OpenStreetMap } from '../../Contents/OpenStreetMap'

class CardContattiState extends OfficeData {
    IsLoading: boolean
}

export class CardContatti extends React.Component<any, CardContattiState> {

    service: IAppOfficeService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.APP_OFFICE_SERVICE)
        this.state = new CardContattiState()
    }

    async componentDidMount() {
        this.setState({ IsLoading: true })

        let resp = await this.service.GetOfficeData()
        this.setState(resp)

        this.setState({ IsLoading: false })
    }

    renderMap = () => {
        return (
            <OpenStreetMap center={[this.state.Latitude, this.state.Longitude]} zoom={14} style={{ height: '350px' }}>
                <Marker position={[this.state.Latitude, this.state.Longitude]} />
            </OpenStreetMap>
        )
    }

    openTel = () => {
        window.open(`tel:${this.state.PhoneNumber}`)
    }

    openMap = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${this.state.Latitude},${this.state.Longitude}`)
    }

    renderInfo = () => {
        return (
            <>
                <Descriptions title={<h1>{this.state.Name}</h1>} column={1}>
                    <Descriptions.Item label="Telefono"><a onClick={() => this.openTel()}>{this.state.PhoneNumber}</a></Descriptions.Item>
                    <Descriptions.Item label="Indirizzo"><a onClick={() => this.openMap()}>{this.state.Address}</a></Descriptions.Item>
                </Descriptions>
                <br />
                <Descriptions title="Orari" column={1}>
                    {this.state.Orario.map(e => <Descriptions.Item key={e.Giorno} label={e.Giorno}>{`${e.OrarioMattina}  ${e.OrarioPomeriggio}`}</Descriptions.Item>)}
                </Descriptions>
            </>
        )
    }

    render() {
        return (
            <Card bordered={false} title="Contatti" loading={this.state.IsLoading}>
                <Row gutter={30}>
                    <Col xl={16} md={12} span={24}>
                        {this.state.Latitude ? this.renderMap() : <></>}
                    </Col>
                    <Col xl={8} md={12} span={24}>
                        {this.state.Name ? this.renderInfo() : <></>}
                    </Col>
                </Row>
            </Card>
        )
    }
}