import { injectable } from 'inversify'
import { PROVIDERS_REGISTRATION } from '../Constants/IocConstants'
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService"

export interface IAppCircolariService extends IBaseComponentService { }

@injectable()
export class AppCircolariService extends BaseComponentService implements IAppCircolariService {
    RenderApi: string

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.APP_CIRCOLARI_LIST
    }
}

export interface IAppCircolareService extends IBaseComponentService { }

@injectable()
export class AppCircolareService extends BaseComponentService implements IAppCircolareService {
    RenderApi: string

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.APP_CIRCOLARE
    }
}