import * as React from 'react'
import { List, Pagination } from 'antd'
import { ListProps } from 'antd/lib/list';

// Nato per poter fissare l'altezza della lista con pagination a fondo pagina
export class TTPagedList<T> extends React.Component<ListProps<T>, any> {
    render() {
        const { pagination, ...other } = this.props

        return (
            <div>
                <List  {...other} />

                <div style={{ textAlign: 'right' }}>
                    <Pagination {...pagination} size="small" />
                </div>
            </div>
        )
    }
}