import * as React from 'react';
import QueueAnim from 'rc-queue-anim';
import * as ReactRedux from "react-redux";
import { bindActionCreators } from 'redux';
import * as actions from '../../Redux/Reducers/Actions/ActionsTS';
import { IErrorServer } from '../../Models/ServerErrorModel';
import { withRouter } from 'react-router-dom';
import IoCContainer from '../../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import { IErrorHandlerAction } from '../../Redux/Reducers/Actions/ErrorHandlerAction';
import { Button } from 'antd';
import logo from "./../../assets/images/Tpocket_Marchio.png"

const Error400 = (props) => {

    let serverMessage: IErrorServer = props.message;

    const redirect = () => {
        props.history.push('/')
        let _errorservice = IoCContainer.get<IErrorHandlerAction>(SERVICE_IDENTIFIER.ERROR_HANDLER_ACTION);
        _errorservice.RemoveError();
    }

    return (
        <div className="page-login">
            <div className="main-body">
                <div className="body-inner">
                    <div className="box box-default">
                        <div className="box-body">
                            <section className="logo text-center">
                                <img src={logo} width='70%' height='70%' />
                            </section>
                            <br />
                            <section className="text-center">
                                <h3>Si è verificato un errore dell'applicativo, abbiamo già provveduto a inviare una segnalazione a Tootech</h3>
                                <br />
                                <Button type="primary" onClick={redirect} >Vai alla Home Page</Button>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const Page = (props) => (
    <QueueAnim type="bottom" className="ui-animate">
        <div key="1">
            <Error400 message={props.message} {...props} />
        </div>
    </QueueAnim>
);

const mapStateToProps = state => ({
    ...state.error, ...state.history
});

const mapDispatchToProps = dispatch => bindActionCreators(
    actions,
    dispatch,
);


export default withRouter(ReactRedux.connect(mapStateToProps, mapDispatchToProps)(Page));