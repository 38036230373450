export const Violetto = "#8487ad"
export const VerdeAcido = "#d3d959"
export const RossoTenue = "#d4767a"

export const InProgress = Violetto
export const Done = VerdeAcido
export const Todo = RossoTenue

export const VerdeIstituzionale = "#6ab9af"
export const VerdeIstituzionaleMoltoScuro = "#327373"
export const GrigioMedioA = "#9a9d9f"