import * as React from 'react'
import { List } from 'antd'
import { ListProps } from 'antd/lib/list';
import { PaginationConfig } from 'antd/lib/pagination';

export class TTList<T> extends React.Component<ListProps<T>, any> {
    render() {
        const { pagination } = this.props

        const page = pagination as PaginationConfig
        page.size = "small"

        return (
            <List pagination={page} {...this.props}/>
        )
    }
}