import * as React from 'react'
import { Menu, List, Badge, Popover, } from 'antd'
import { RouteComponentProps } from "react-router"

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'
import container from '../../../Wires/Bootstrapper'

import { IAccountService } from '../../../Services/Security/AccountService'
import { IAuthService } from '../../../Services/Security/AuthServiceTs'
import { IHeadService } from '../../../Services/MenuService/HeadService'

import { bindActionCreators } from 'redux'
import * as ReactRedux from "react-redux"
import * as actions from '../../../Redux/Reducers/Actions/ActionsTS'
import { IHeader } from '../../../Redux/Reducers/HeaderTs'

import { DispatcherContext } from '../../../Services/Shared/Dispatcher-Context'
import { TTIcon } from '../../../app/Contents/TTIcon'

const { SubMenu } = Menu


const PrintRole = (data: any, click: (c: any) => void) => {
    if (data != null) {
        return <Menu.Item key={data.Key}><a onClick={() => click(data)}>{data.Value}</a></Menu.Item>
    }
}

export class NavRight extends React.Component<RouteComponentProps & IHeader, any>
{
    static contextType = DispatcherContext
    context: React.ContextType<typeof DispatcherContext>

    private headService: IHeadService
    private authService: IAuthService
    private accountService: IAccountService

    constructor(props) {
        super(props)

        this.headService = container.get(SERVICE_IDENTIFIER.HEADER)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
        this.accountService = container.get(SERVICE_IDENTIFIER.ACCOUNT_SERVICE)
    }

    getRoles() {
        let items: Array<any> = new Array<any>()

        if (this.props.roles != null &&
            this.props.roles.length > 0) {
            //console.log(this.props.roles.length);
            this.props.roles.map(c => items.push(PrintRole(c, () => { } /*this.ChangeRoleClick*/)))
            items.push(<Menu.Item key="sep"><hr /></Menu.Item>)
        }

        return items
    }

    componentDidMount() {
        this.headService.GetLoggedUser()
    }

    getNotification() {
        return (
            <List
                itemLayout="horizontal"
                dataSource={this.props.notification}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            title={item.title}
                            description={item.descrizione}
                        />
                    </List.Item>
                )}
            />
        )
    }

    getBadge() {
        const NotificheContainer = this.getNotification()

        const { notification } = this.props
        if (notification == null) return null
        const BadgeComp = (
            <span>
                <Badge count={notification.length}>
                    <TTIcon icon="notifica" />
                </Badge>
            </span>)
        return (
            <Popover
                placement="bottomRight"
                content={NotificheContainer}
                trigger="click"
                arrowPointAtCenter>
                {BadgeComp}
            </Popover>
        )
    }

    /*ChangeRoleClick = (item) => {

        const param = { Token: this.authService.getToken(), Role: item.Key }

        this.apiClient.post<any>(Api.ChangeRole, param, (resp) => {
            if (resp.IsValid) {
                let model = new LoginModel()
                model.Token = resp.Token
                this.authService.signOut(null)
                this.authService.signInAndReload(model)
            }
        }, null)
    }*/

    LogOut = async () => {
        await this.accountService.Logout()
        this.authService.signOutAndReload()
    }

    onNotificaClick = () => {
        this.context.ShowDrawer(true)
    }

    renderNotifica = () => {
        if (this.context.IsDrawerEnabled == false)
            return <></>

        return <SubMenu title={<span onClick={() => this.onNotificaClick()}>
                <TTIcon icon="notifica" style={{ fontSize: 20 }} />
            </span>} />
    }

    render() {
        return (
            <div className="header-right-container">
                {this.getBadge()}
                <Menu mode="horizontal">
                    <SubMenu title={<span>{this.props.user}&nbsp;&nbsp;<TTIcon icon="user" style={{ fontSize: 20 }} /></span>}>
                        {this.getRoles()}
                        <Menu.Item key="logout"><a onClick={this.LogOut}>LogOut</a></Menu.Item>
                    </SubMenu>
                    {this.renderNotifica()}
                </Menu>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ...state.header
})

const mapDispatchToProps = dispatch => bindActionCreators(
    actions,
    dispatch,
)

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(NavRight)