import { injectable } from "inversify";
import * as Api from "../Constants/ApiUrlConstants";

import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { RichiesteStateModel } from "../app/Contents/Adempimenti/PageAdempimentoNotification";
import { ModelBase } from "../Models/ModelBase";
import { ComponentService, IComponentService } from "./Shared/ComponentService";
import { IApiServiceExt } from "./Shared/ApiServiceExt";

export interface IAdempimentoNotificationService extends IComponentService<RichiesteStateModel> {
    SaveRichiesta(data: RichiesteStateModel): Promise<ModelBase>;
    GetByCliente(idcliente: string): Promise<RichiesteStateModel>
}

@injectable()
export class AdempimentoNotificationService extends ComponentService<RichiesteStateModel> implements IAdempimentoNotificationService {
    protected SearchUrl: string;

    RenderApi: string = '';
    _apiService: IApiService;
    _apiServiceExt: IApiServiceExt;

    constructor() {
        super();
        this._apiServiceExt = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.ADEMPIMENTONOTIFICATION;
    }

    async SaveRichiesta(data: RichiesteStateModel): Promise<ModelBase> {
        const resp = await this._apiServiceExt.postAsync<any>(Api.SaveRichiesta, data)
        return resp
    }


    async GetByCliente(idcliente: string): Promise<RichiesteStateModel> {
        const resp = await this._apiService.getAsync<any>(Api.GetByCliente, { idcliente: idcliente})
        return resp
    }
}