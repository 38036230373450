import { injectable } from "inversify";
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import * as Api from "../Constants/ApiUrlConstants";
import { IBaseComponentService, BaseComponentService } from './Shared/BaseComponentService';
import IoCContainer from '../Wires/Bootstrapper';
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { ModelBase } from "../Models/ModelBase";
import { AdempimentoState } from "../app/Contents/Adempimenti/PageAdempimentoEdit";
import { IApiServiceExt } from "./Shared/ApiServiceExt";

export interface IAdempimentoEditService extends IBaseComponentService {
    SaveAdempimento(data: AdempimentoState): Promise<AdempimentoState>
}

@injectable()
export class AdempimentoEditService extends BaseComponentService implements IAdempimentoEditService {

    RenderApi: string = Api.RenderEditAdempimenti; 
    _apiServiceExt: IApiServiceExt;

    constructor() {
        super();
        this._apiServiceExt = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
    }

    public getProviderName(): string {
        return PROVIDERS_REGISTRATION.ADEMPIMENTOEDIT;
    }

    async SaveAdempimento(data: AdempimentoState): Promise<AdempimentoState> {
        
        return await this._apiServiceExt.postAsync<any>(Api.SaveAdempimento, data, "Salvataggio avvenuto correttamente");        
    }
}
