import { injectable } from 'inversify'

import * as apis from '../../Constants/ApiUrlConstants'
import IoCContainer from '../../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../Wires/Identifiers'

import { IApiService } from '../Shared/ApiService'
import { IMenuModel } from '../ToolbarService/IMenuItems'

export interface IMenuService {
    getMenu(): Promise<IMenuModel>
}


@injectable()
export default class MenuService implements IMenuService {

    apiClient: IApiService

    public constructor() {
        this.apiClient = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE)
    }

    async getMenu(): Promise<IMenuModel> {
        let resp: IMenuModel = await this.apiClient.getAsync<IMenuModel>(apis.Menu, null)
        return resp
    }
}