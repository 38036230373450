import * as React from 'react'
import { Avatar } from 'antd'
import { AvatarProps } from 'antd/lib/avatar'
import { StatoLicenza } from "../../../Constants/StatoLicenza"
import { VerdeIstituzionale, VerdeIstituzionaleMoltoScuro, GrigioMedioA } from '../../../Constants/ColorConstants'

interface TTAvatarLicenzaProps extends AvatarProps {
    stato: StatoLicenza
    clientiRimanenti: number
}
export const TTAvatarLicenza: React.FC<TTAvatarLicenzaProps> = (props) => {
    // Licenza attiva
    let color = VerdeIstituzionale

    if (props.stato == StatoLicenza.NonAttiva) {
        // Licenza scaduta
        color = GrigioMedioA
    } else if (props.clientiRimanenti == 0) {
        // Licenza attiva ma non assegnabile
        color = VerdeIstituzionaleMoltoScuro
    }

    return <Avatar style={{ backgroundColor: color}} icon="account-book" size={40} {...props}></Avatar>
}