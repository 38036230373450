// Task
export const GetTasksStatus = '/Task/GetTasksStatus'
export const GetActivities = '/Task/GetActivities'
export const GetActivity = '/Task/GetActivity'
export const PostActivityUserFeedback = '/Task/PostActivityUserFeedback'
export const GetInfoActivities = '/Task/GetInfoActivities'
export const GetScadenze = '/Task/GetScadenze'
export const GetCircolari = '/Task/GetCircolari'
export const GetCircolare = '/Task/GetCircolare'
export const PostCircolareRead = '/Task/PostCircolareRead'
export const GetAdempimenti = '/Task/GetAdempimenti'

// Office
export const GetOfficeData = '/Office/GetOfficeData'
export const GetTootechData = '/Office/GetTootechData'

// FS Virtuale
export const SaveFile = '/FSVirtuale/Save'
export const GetFolder = '/FSVirtuale/GetFolder'
