
export const Menu = '/Security/GetMenu';
export const GetRoutes = '/Security/GetRoutes';
export const HasDrawer = '/Security/HasDrawer';
export const TpTestRender = '/Test/Render';
export const TpTestRandom = '/Test/GetRnd';
export const Login = '/Account/Login';
export const RestorePassword = '/Account/RestorePassword';
export const Logout = '/Account/Logout';
export const TpTestFail = '/Test/Fail';
export const GetLoggedUser = '/Security/GetLoggedUser';
export const GetControlloProjection = '/Controllo/GetControlloProjection';
export const ChangeControlloArchivio = '/Controllo/EditControlloArchivio';
export const RenderRicercaStipulati = '/Controllo/Render';
export const RenderRicercaUtenti = '/User/RenderGrid';
export const GetUtentiProjection = '/User/GetUtentiProjection';
export const ChangeRole = '/Security/ChangeRole';
export const RenderUserEdit = '/User/Render';
export const UserEdit = '/User/RenderEditUser';
export const SaveUser = '/User/SaveUser';

// Circolari
export const RenderCircolari = '/Circolari/Render'
export const GetCircolari = '/Circolari/GetCircolari'
export const DeleteCircolare = '/Circolari/DeleteCircolare'
export const SaveCircolare = '/Circolari/SaveCircolare'
export const RenderEditCircolari = '/Circolari/RenderEdit'
export const UploadDocCircolare = ''

// Gruppi
export const RenderGruppi = '/Gruppi/Render'
export const RenderEditGruppi = '/Gruppi/RenderEdit'
export const GetGruppi = '/Gruppi/GetGruppi'
export const SaveGruppo = '/Gruppi/Save'
export const GetGruppiClienti = '/Gruppi/GetClienti'

//Clienti
export const GetClientiiByGruppo = '/Clienti/GetClientiiByGruppo'
export const GetClientiByGruppi = '/Clienti/GetClientiByGruppi'
export const GetClientiByAdempimento = '/Clienti/GetClientiByAdempimento'
export const GetCliente = '/Clienti/GetCliente'
export const RenderClienti = '/Clienti/Render'
export const GetClienti = '/Clienti/GetClienti'
export const GetClienteFatturato = '/Clienti/GetClienteFatturato'
export const GetClienteAdempimenti = '/Clienti/GetClienteAdempimenti'

//Adempimenti
export const RenderEditAdempimenti = '/Adempimenti/RenderEdit'
export const SearchAdempimenti = '/Adempimenti/Search'
export const Render = '/Adempimenti/Render'
export const Copy = '/Adempimenti/Copy'
export const SaveAdempimento = '/Adempimenti/Save'
export const GetAttivitaDetails = '/Adempimenti/GetAttivitaDetails'
export const AttivitaCambioStato = '/Adempimenti/AttivitaCambioStato'

//Richieste
export const RenderRichiesta = '/Richieste/Render'
export const SaveRichiesta = '/Richieste/Save'
export const GetByCliente = '/Richieste/GetByCliente'
export const GetRichiesteByCliente = '/Richieste/GetByClienteProjection'

// Risposte
export const GetRisposte = '/Richieste/GetRisposte'
export const CancellaRisposte = '/Richieste/CancellaRisposte'

// FS Virtuale
export const GetRootFolder = '/FSVirtuale/GetRootFolder'
export const GetById = '/FSVirtuale/GetById'
export const UploadFile = '/FSVirtuale/Upload'
export const RenameFile = '/FSVirtuale/Rename'
export const MoveFile = '/FSVirtuale/Move'
export const DeleteFile = '/FSVirtuale/Delete'
export const FSVirtualePrepareFile = '/FSVirtuale/PrepareForDownload'
export const PrepareFile = '/File/PrepareForDownload'
export const GetFile = '/File/GetFile'

// Utenti
export const RenderClienteUtente = '/Utenti/RenderClienteUtente'
export const SaveClienteUtente = '/Utenti/SaveClienteUtente'
export const GetClienteUtenti = '/Utenti/GetClienteUtenti'
export const DeleteClienteUtente = '/Utenti/DeleteClienteUtente'
export const GetClienteUtenteAccessi = '/Utenti/GetClienteUtenteAccessi'
export const ResetPwdClienteUtente = '/Account/ResetPassword'

// Import
export const ImportClienti = '/Import/Clienti'
export const ImportFatturato = '/Import/Fatturato'
export const ImportScadenze = '/Import/Scadenze'

// Firma
export const GetStatoFirmaByFile = '/Firma/GetStatoByFile'
export const CambiaStatoFirma = '/Firma/ChangeStatus'
export const AnnullaFirma = '/Firma/CancelSignature'
export const RiavviaProceduraFirma = '/Firma/RestartSignature'
export const GetTemplateListFirma = '/Firma/GetTemplateList'

// Scadenze
export const GetScadenzeByCliente = '/Scadenze/GetByCliente'

//Toolbar
export const GetToolbar = '/Security/GetToolbar'

// Dashboard
export const SearchDashboardRichieste = '/Dashboard/SearchRichieste'
export const GetDashboardAdempimenti = '/Dashboard/GetAdempimenti'

//Utenti
export const RenderBO = '/Utenti/RenderBO'
export const GetUtentiBO = '/Utenti/GetUtentiBO'
export const RenderEditBOUtenti = '/Utenti/RenderBOEdit'
export const SaveUtenteBO = '/Utenti/SaveUtenteBO'
export const ResetPassword = '/Utenti/ResetPasswordBO'
export const DeleteUtenteBO = '/Utenti/DeleteUtenteBO'

//Super
export const RenderEditTenant = '/Tenant/RenderEdit'
export const SaveTenant = '/Tenant/SaveTenant'

// Device
export const PostDeviceInfo = '/Device/PostDeviceInfo'

// Deep Link
export const GetDataByToken = '/DeepLink/GetDataByToken'

// Licenza
export const CompraLicenzaTenant = '/Licenza/CompraLicenzaTenant'
export const CompraLicenzaCliente = '/Licenza/CompraLicenzaCliente'
export const RenderLicenze = '/Licenza/RenderLicenze'
export const SearchLicenze = '/Licenza/SearchLicenze'
export const RenderLicenzaClienti = '/Licenza/RenderLicenzaClienti'
export const AssegnaLicenzaClienti = '/Licenza/AssegnaClienti'
export const LicenzaCercaClienti = '/Licenza/CercaClientiSenzaLicenza'
export const CheckLicenseByCustomer = '/Licenza/CheckLicenseByCustomer'

// Supporto
export const RenderSupporto = '/Supporto/RenderSupporto'
export const InviaRichiestaSupporto = '/Supporto/InviaRichiesta'