import { inject, injectable,named } from "inversify";
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import { IToolbarService } from '../ToolbarService/ToolbarServiceTs';
import container from "../../Wires/Bootstrapper";
import { SearcherBaseModel } from "../../Models/SearcherBaseModel";
import { ProjectionPagedModel } from "../../Models/ProjectionPageModel";
import { IApiService } from "./ApiService";
import * as Api from "../../Constants/ApiUrlConstants";

export interface IComponentService<TP> {
    getToolbar(): IToolbarService ;    
    RenderApi: string;
    getProviderName(): string;
    Search(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<TP>>;
}
@injectable()
export abstract class ComponentService<TP> implements IComponentService<TP> {
    protected _toolBarService : IToolbarService = null;
    //chiave di registrazione del servizio specifico
    abstract getProviderName(): string;
    abstract RenderApi: string;
    protected _apiService: IApiService;

    constructor() {
        if (container.isBoundNamed(SERVICE_IDENTIFIER.TOOLBAR, this.getProviderName())) {
            this._toolBarService = container.getNamed<IToolbarService>(SERVICE_IDENTIFIER.TOOLBAR, this.getProviderName())
            this._toolBarService.Identifier = this.getProviderName();
        }
        this._apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getToolbar(): IToolbarService {
        
        return this._toolBarService;        
    }    

    async Search(searcher: SearcherBaseModel): Promise<ProjectionPagedModel<TP>> {
        
        return await this._apiService.getAsync<any>(this.SearchUrl, searcher);
    }

    protected abstract SearchUrl: string;
}