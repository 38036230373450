import * as React from 'react'
import { Card, List, Avatar } from 'antd'
import { ListaAccessiModel, LogAccessiModel } from '../../../Models/LogAccessiModels';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IClienteUtenteService } from '../../../Services/ClienteUtenteService';
import IoCContainer from '../../../Wires/Bootstrapper';
import moment from 'moment';

export interface IClienteUtenteLoginLogProps {
    userId: string
}

export class ClienteUtenteLoginLog extends React.Component<IClienteUtenteLoginLogProps, ListaAccessiModel> {

    service: IClienteUtenteService

    constructor(props) {
        super(props)

        var state = new ListaAccessiModel()
        state.ListaAccessi = []

        this.state = state
    }

    componentDidMount() {
        this.service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_UTENTE_SERVICE)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userId != this.props.userId) {
            this.service.GetAccessi(this.props.userId).then(resp => this.setState(resp))
        }
    }

    formatTitle = (item: LogAccessiModel) => {
        return item.LastLogin == null ? '' : moment(item.LastLogin).format('dddd')
    }

    formatDescription = (item: LogAccessiModel) => {
        return item.LastLogin == null ? '' : moment(item.LastLogin).format('LLL')
    }

    render() {
        return (
            <Card bordered={false} title="Ultimi Accessi">
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.ListaAccessi}
                    renderItem={item => (
                        <List.Item
                            key={item.Id}>
                            <List.Item.Meta
                                avatar={<Avatar icon="login" />}
                                title={this.formatTitle(item)}
                                description={this.formatDescription(item)}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        )
    }
}