import * as React from 'react'
import { Card, Descriptions, Badge, Popover } from 'antd'
import moment from 'moment';

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { IClienteService } from '../../../Services/ClienteService';

import { ClientiProjection } from '../../../Models/ClientiState';
import { IClienteBaseProps } from '../../../Models/IClienteBaseProps';
import { StatoLicenzaModel } from '../../../Models/LicenzeModels';
import { StatoLicenza } from './../../../Constants/StatoLicenza'

import { TPDescriptions } from './../TPDescriptions'

export class ClienteCoverState {
    public ClienteData: ClientiProjection
    public LicenseData: StatoLicenzaModel
}

interface IClienteCover extends IClienteBaseProps {
    onLicenseStatusRecived: (stato: StatoLicenza) => void
}

export class ClienteCover extends React.Component<IClienteCover, ClienteCoverState> {

    private _service: IClienteService;

    constructor(props) {
        super(props)

        let state: ClienteCoverState = {
            ClienteData: new ClientiProjection(),
            LicenseData: new StatoLicenzaModel()
        }

        this.state = state;
        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.clienteId != this.props.clienteId) {
            this._service.GetCliente(this.props.clienteId).then(resp => this.setState({ ClienteData: resp }))
            this._service.CheckLicenseByCliente(this.props.clienteId).then(
                resp => {
                    this.setState({ LicenseData: resp })
                    this.props.onLicenseStatusRecived(resp.Stato)
                })
        }
    }

    getBadge = (stato: number): 'success' | 'processing' | 'default' | 'error' | 'warning' => {
        switch (stato) {
            case StatoLicenza.Attiva:
                return 'success'
            case StatoLicenza.InScadenza:
                return 'warning'
            default:
                return 'error'
        }
    }

    getPopDescription = () => {
        const { LicenseData } = this.state
        return (
            <span>
                <b>Scadenza:</b> {moment(LicenseData.ProssimoRinnovo).format('L')} <br />
                <b>Licenza:</b> {LicenseData.LicenzaId}
            </span>
        )
    }

    render() {
        const { ClienteData, LicenseData } = this.state

        var badge = <span><Badge status={this.getBadge(LicenseData.Stato)} />{LicenseData.Descrizione}</span>
        var popOver =
            <Popover trigger="click" content={this.getPopDescription()} >
                <a>{badge}</a>
            </Popover>

        var license =
            <Descriptions.Item label="Stato Licenza">
                {LicenseData.Stato != StatoLicenza.NonTrovata ? popOver : badge}
            </Descriptions.Item>

        return (
            <Card bordered={false} title={ClienteData.Descrizione} className="card-full-height">
                <TPDescriptions column={2} title="Anagrafica">
                    <Descriptions.Item label="Codice Fiscale">{ClienteData.CodiceFiscale}</Descriptions.Item>
                    <Descriptions.Item label="Partita IVA">{ClienteData.PartitaIva}</Descriptions.Item>
                    <Descriptions.Item label="Provincia">{ClienteData.Citta}</Descriptions.Item>
                    <Descriptions.Item label="Tipo Cliente">{ClienteData.TipoCliente}</Descriptions.Item>
                    <Descriptions.Item label="Liquidazione">{ClienteData.Liquidazione}</Descriptions.Item>
                    <Descriptions.Item label="Tipo Contabilità">{ClienteData.TipoContabilita}</Descriptions.Item>
                    <Descriptions.Item label="Regime IVA">{ClienteData.TipoRegimeIva}</Descriptions.Item>
                    <Descriptions.Item label="Codice ISTAT attività economica">{ClienteData.CodiceIstat}</Descriptions.Item>
                    {license}
                </TPDescriptions>
            </Card>
        );
    }
}