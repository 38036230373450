import * as React from 'react'
import { Descriptions } from 'antd'
import { DescriptionsProps } from 'antd/lib/descriptions'

// Descriptions verticale con padding extra
export const TPDescriptions: React.FC<DescriptionsProps> = (props) => {
    return (
        <Descriptions layout="vertical" className="ant-descriptions-vertical" {...props}>
            {props.children}
        </Descriptions>
    )
}