import * as React from 'react'
import { Form, Icon, Input, Button } from 'antd'
import { RouteComponentProps, withRouter } from "react-router"
import { FormComponentProps } from 'antd/lib/form'

import SERVICE_IDENTIFIER from '../../Wires/Identifiers'
import IoCContainer from '../../Wires/Bootstrapper'

import { IAccountService } from '../../Services/Security/AccountService'
import { IAuthService } from '../../Services/Security/AuthServiceTs'

import logo from "./../../assets/images/Tpocket_Marchio.png"


export class Login extends React.Component<RouteComponentProps & FormComponentProps, any>
{
    private service: IAccountService
    private authService: IAuthService

    constructor(props) {
        super(props)
        this.service = IoCContainer.get(SERVICE_IDENTIFIER.ACCOUNT_SERVICE)
        this.authService = IoCContainer.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
    }

    login = async (username: string, password: string) => {
        let resp = await this.service.Login(username, password)
        if (resp.IsValid == true) {

            if (resp.IsTemporanyPassword) {
                this.props.history.push(`/ChangePassword/${resp.Token}`)
                return
            }

            this.authService.signInAndReload(resp)
        }
    }

    doLogin = () => {
        this.props.form.validateFields((err, values) => {
            if (!err)
                this.login(values['Username'], values['Password'])
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form

        return (
            <div className="page-login">
                <div className="main-body">
                    <div className="body-inner">
                        <div className="box box-default">
                            <div className="box-body">
                                <section className="logo text-center">
                                    <img src={logo} width='70%' height='70%' />
                                </section>
                                <br />
                                <Form className="login-form">
                                    <Form.Item>
                                        {getFieldDecorator('Username', {
                                            rules: [{ required: true, message: 'Inserisci username' }],
                                        })(
                                            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        {getFieldDecorator('Password', {
                                            rules: [{ required: true, message: 'Inserisci password' }],
                                        })(
                                            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="Password" placeholder="Password" />
                                        )}
                                    </Form.Item>
                                    <br />
                                    <Button type="primary" htmlType="submit" className="submit-button" onClick={this.doLogin}>Accedi</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

}

export const LoginForm = withRouter(Form.create<RouteComponentProps & FormComponentProps>()(Login))
