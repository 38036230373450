export const TOGGLE_BOXED_LAYOUT = 'TOGGLE_BOXED_LAYOUT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_OFFCANVAS_NAV = 'TOGGLE_OFFCANVAS_NAV';
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';
export const CHANGE_SIDEBAR_WIDTH = 'CHANGE_SIDEBAR_WIDTH';
export const CHANGE_COLOR_OPTION = 'CHANGE_COLOR_OPTION';
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_USER = 'CHANGE_USER';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const LOAD_NOTIFICATION = 'LOAD_NOTIFICATION';

export const CHANGE_LOGGED = 'CHANGE_LOGGED';

export const ERROR_SHOW = 'ERROR_SHOW';
export const ERROR_REMOVE = 'ERROR_REMOVE';