
export const APPCONFIG = {
  brand: 'Ant',
  user: 'Lisa',
  year :'2019',
  productLink: 'https://wrapbootstrap.com/theme/ant-reactjs-admin-web-app-WB034D136',
  AutoCloseMobileNav: true,
  noCustomizer: false,
  color: {
    primary: '#108ee9',
    success: '#3dbd7d',
    info: '#01BCD4',
    infoAlt: '#948aec',
    warning: '#ffce3d',
    danger: '#f46e65',
    text: '#3D4051',
    gray: '#EDF0F1'
  },
  settings: {
    boxedLayout: false, // boolean: true, false
    fixedHeader: true, // boolean: true, false
    collapsedNav: false, // boolean: true, false
    offCanvasNav: false, // boolean: true, false
    sidebarWidth: 240, // number
    colorOption: '22', // string: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    theme: 'dark', // (WIP) string: light, gray, dark
  }
};

