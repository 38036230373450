import { TaskBaseItem } from './AppTaskModels'
import { ModelBase } from './ModelBase'

export enum ActivityAction {
    Done,
    Yes,
    No,
    Read
}

export enum ActivityActionType {
    ReadOnly,
    Done,
    YesNo,
    Sign
}

export class ActivityItem extends TaskBaseItem {
    Action: ActivityActionType
    Todo: boolean
    SignUrl: string
}

export class ActivityList extends ModelBase
{
    Activities: Array<ActivityItem>
}