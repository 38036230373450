import { injectable } from "inversify";

import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";

export interface IServices extends IBaseComponentService {
}

@injectable()
export class Services extends BaseComponentService implements IServices {

    RenderApi: string = '';
    _apiService: IApiService;

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.SERVIZI;
    }
}