import { injectable } from 'inversify'
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService"
import { PROVIDERS_REGISTRATION } from '../Constants/IocConstants'

export interface IAppAdempimentiService extends IBaseComponentService { }

@injectable()
export class AppAdempimentiService extends BaseComponentService implements IAppAdempimentiService {
    RenderApi: string

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.APP_ADEMPIMENTI
    }
} 