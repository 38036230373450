import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { ClientiProjection } from "../Models/ClientiState";
import { ComponentService, IComponentService } from "./Shared/ComponentService";

export interface IClientiService extends IComponentService<ClientiProjection> {
}

@injectable()
export class ClientiService extends ComponentService<ClientiProjection> implements IClientiService {
    protected SearchUrl = Api.GetClienti;

    RenderApi: string = Api.RenderClienti;
    _apiService: IApiService;

    constructor() {
        super();
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.CLIENTI;
    }
}