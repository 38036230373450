import * as React from 'react'
import { Upload } from 'antd'
import { UploadProps } from 'antd/lib/upload'

class UploadState {
    Files: Array<File>
}

export interface ITTMultiUpload {
    updateList: (FileList: Array<File>) => void
    files: Array<File>
}

export class TTMultiUpload extends React.Component<UploadProps & ITTMultiUpload, UploadState> {

    constructor(props) {
        super(props)

        this.state = {
            Files: []
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Svuotare la lista nel componente padre dopo l'upload
        if (prevProps.files != this.props.files && this.props.files.length == 0) {
            this.setState({ Files: [] })
        }
    }

    onRemoveFile = file => {
        const index = this.state.Files.indexOf(file);
        const newFileList = this.state.Files.slice();
        newFileList.splice(index, 1);

        this.props.updateList(newFileList)
        this.setState({ Files: newFileList })
    }

    fileInQueue = (file): boolean => {
        var res = this.state.Files.find(it => it.name == file.name)
        return !!res
    }

    onBeforeUpload = (file, fileList) => {
        const { Files } = this.state
        var files: Array<File> = []

        // Con multiple = true (solo browser moderni) bisogna fare riferimento a fileList
        if (fileList.length > 0) {

            // Escludo eventuali file già presenti in lista
            var toUpload = fileList.filter(file => !this.fileInQueue(file));

            files = [...Files.concat(toUpload)]
        } else {
            // In caso di browser vecchio...

            // Il file è già in elenco
            if (this.fileInQueue(file.name)) return false

            files = [...Files, file]
        }

        this.props.updateList(files)
        this.setState({ Files: files })

        return false;
    }

    render() {
        const { onRemove, beforeUpload, multiple, fileList, ...other } = this.props
        var uploadFiles = this.state.Files as any

        return (
            <Upload
                onRemove={this.onRemoveFile}
                beforeUpload={this.onBeforeUpload}
                multiple={true}
                fileList={uploadFiles} {...other}>
                {this.props.children}
            </Upload>
        )
    }
}