import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';

import IoCContainer from '../../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';


import Form from 'antd/lib/form';
import { Card, Row, Col, Input, Select , message} from 'antd';

import { BaseFormReactComponent } from '../BaseReactComponent';
import { TTSelect } from '../TTSelect';
import { UtentiBOModel } from './PageUtentiBORicerca';
import { IUtentiBOEditService } from '../../../Services/UtentiEditService';

const ComponentService: symbol = SERVICE_IDENTIFIER.UTENTI_EDIT_SERVICE;



const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    }
}

export class PageUtenteBOEdit extends BaseFormReactComponent< any & IStoreModel & RouteComponentProps & ITTProps, UtentiBOModel> {


    private _service: IUtentiBOEditService;

    constructor(props) {
        super(props);

        this.saveUtente = this.saveUtente.bind(this);
        this.resetPassword = this.resetPassword.bind(this);

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.UTENTI_EDIT_SERVICE);
        this._service.getToolbar().addBackClick(() => this.props.history.push("/Utenti"));
        this._service.getToolbar().addButtonClick("Salva", this.saveUtente);
        this._service.getToolbar().addButtonClick("ResettaPassword", this.resetPassword);
        this._service.getToolbar().renderButton("ResettaPassword", this.props.match.params.id == null);

        this.state = this.props.componentData || new UtentiBOModel();
    }

    componentDidMount() {
        const { match } = this.props;
        debugger;
        this.setState({ EditMode: match.params.id != null })
    }

    saveUtente = () => {
        debugger;
        this.validateAndSaveExtra(() => this._service.SaveUtenti(this.state),
            (r => {
                if (r.IsValid)
                    r.EditMode = true
            })
        );
    }

    resetPassword = () => {
        debugger;
        this._service.ResetPassword(this.state).then(r => {
            if (r.IsValid)
                message.success('Password resettata, a tua password temporanea è ' + r.Password)
            this.setState(r)
        })
    }

    onTypeRole = value => {
        this.setState({ Role: value });
    }


    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <Card bordered={false} title="Modifica Utente">
                            <Row>
                                <Col span={24}>
                                    <Form>
                                        <Form.Item {...formItemLayout} label="UserName">
                                            {
                                                this.getRequiredField(<Input disabled={this.state.EditMode} name="UserName" size="small" onChange={this.onChangeText} />)
                                            }
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Password">
                                            {
                                            this.getRequiredField(<Input disabled={this.state.EditMode} name="Password" size="small" onChange={this.onChangeText} />)
                                        }
                                        </Form.Item>
                                        <Form.Item {...formItemLayout} label="Ruolo">
                                            {
                                                this.getRequiredFieldFull("Gruppi", this.state.Role,
                                                        <TTSelect allowClear={true} onChange={this.onTypeRole}>
                                                {this.renderSelectDictionary(this.state.RoleList)}
                                                    </TTSelect>)
                                            }
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const UtenteBOEdit = withRouter(TTComponent(Form.create()(PageUtenteBOEdit), 'Modifica Utente', ComponentService));
export default UtenteBOEdit;
