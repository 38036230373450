import * as React from 'react'
import { Icon } from 'antd';

import * as Icons from './../../Constants/Icons'
import * as IconsAccent from './../../Constants/IconsAccent'
import * as IconsStati from './../../Constants/IconsStati'
import { IconProps } from 'antd/lib/icon';

export interface ITTIconProps extends IconProps {
    icon: string
    ttstyle?: "default" | "accent" | "stati"
}

export const TTIcon: React.FC<ITTIconProps> = (props) => {
    const { icon, ttstyle } = props

    var res = null
    switch (ttstyle) {
        case "accent":
            res = IconsAccent[icon]
            break;
        case "stati":
            res = IconsStati[icon]
            break;
        default:
            res = Icons[icon]
            break;
    }
    return (<Icon component={res as any} {...props} />)
}