import { IProjectionPagedModel, ProjectionPagedModel } from "./ProjectionPageModel";

export class PagedBaseState<T> {
    Data: IProjectionPagedModel<T>;
    Searcher: string;
    Page: number;

    constructor() {
        this.Data = new ProjectionPagedModel<T>()
    }
} 