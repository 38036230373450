import React from 'react';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import $ from 'jquery';
//import 'jquery-slimscroll/jquery.slimscroll.min';
import {
    toggleCollapsedNav,
    toggleOffCanvasNav
} from '../../../Actions/Actions';
import AppMenu from './MenuTs';
import brandBig from '../../../assets/images/TpocketHeader.png'
import { withRouter } from 'react-router-dom';
import { TTIcon } from '../../../app/Contents/TTIcon'
import * as Constants from '../../../Constants/AppConstants'
import { Link } from 'react-router-dom';


const { Sider } = Layout;

class AppSider extends React.Component {

    componentDidMount = () => {
        this.autoToggleOffCanvasNav();
        window.addEventListener('resize', this.autoToggleOffCanvasNav);

        //
        //const { sidebarContent } = this.refs;
        //$(sidebarContent).slimscroll({
        //  height: 'calc(100vh - 104px)', // 60(Header) + 44(Footer)
        //  size: '7px'
        //});
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.autoToggleOffCanvasNav);
    }

    autoToggleOffCanvasNav = () => {
        const { handleToggleOffCanvasNav } = this.props;
        const width = $(window).width();
        //const breakpoint = 992;
        // console.log($(window).width())

        if (width >= 992) {
            handleToggleOffCanvasNav(false);
        } else {
            handleToggleOffCanvasNav(true);
        }
    }

    onLogoClick = () => {
        //window.location.href = '/';
    }

    render() {
        const { collapsedNav, offCanvasNav, sidebarWidth } = this.props;
        const collapsedWidth = offCanvasNav ? 0 : 80;

        return (
            <Sider
                collapsible
                collapsed={collapsedNav || offCanvasNav}
                collapsedWidth={collapsedWidth}
                trigger={null}
                width={sidebarWidth}>
                <div className="brand-wrapper" style={{ background: '#6ab9af' }}>
                    <img className="brand" alt="" src={brandBig} onClick={() => this.onLogoClick()} />
                </div>
                <AppMenu />
            </Sider>
        );
    }
}

const mapStateToProps = state => ({
    collapsedNav: state.settings.collapsedNav,
    offCanvasNav: state.settings.offCanvasNav,
    sidebarWidth: state.settings.sidebarWidth,
    colorOption: state.settings.colorOption
});

const mapDispatchToProps = dispatch => ({
    handleToggleCollapsedNav: (isCollapsedNav) => {
        dispatch(toggleCollapsedNav(isCollapsedNav));
    },
    handleToggleOffCanvasNav: (isOffCanvasNav) => {
        dispatch(toggleOffCanvasNav(isOffCanvasNav));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AppSider));