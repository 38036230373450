import { ITTProps, IToolbaractions } from "./ITTProps";
import QueueAnim from 'rc-queue-anim';
import * as React from 'react';
import { GridLoader } from 'react-spinners';
import { bindActionCreators } from 'redux';
import * as ReactRedux from "react-redux";
import IoCContainer from '../../Wires/Bootstrapper';
import * as actions from '../../Redux/Reducers/Actions/ActionsTS';
import ContainerLayout from './ContainerLayout';
// import ToolbarService from '../../Services/ToolbarService/ToolbarServiceTs';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import { IToolbarService, ToolbarProxy } from '../../Services/ToolbarService/ToolbarServiceTs';
import { ButtonsItem } from '../../Services/ToolbarService/IToolbarModel';
import { IBaseComponentService } from '../../Services/Shared/BaseComponentService';
import { IApiService } from '../../Services/Shared/ApiService';
import { IAuthService } from "../../Services/Security/AuthServiceTs";
import { Spin } from 'antd'
import * as Colors from '../../Constants/ColorConstants'


interface IBaseComponent {
  enableButton(name, enable) : void
  renderButton(name, visible): void
  addButtonClick(name, delegate) : void
}

interface ITTState{
  toolbar:{ [id: string] : ButtonsItem; }
  isLoading:boolean;
}


function TTComponentBox(WrappedComponent: React.ComponentType<any> | React.SFC<any>, Title: string, componentService: symbol, isBoxed: boolean): React.ComponentType<any> {
    class BaseClass extends React.Component<any & IToolbaractions, ITTState> {
        protected service: IBaseComponentService;
        private compData: any;
        private authService: IAuthService;

        state: ITTState;

        protected apiClient: IApiService

        protected toolbarService: IToolbarService

        constructor(props) {
            super(props);
            this.state = { toolbar: {}, isLoading: true };

            this.apiClient = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
            this.authService = IoCContainer.get(SERVICE_IDENTIFIER.AUTH_SERVICE);

            this.buttonClick = this.buttonClick.bind(this);
            this.addButtonClick = this.addButtonClick.bind(this);
            this.backClick = this.backClick.bind(this);
            
            this.service = componentService!= null ? IoCContainer.get(componentService) : null;

        }

        componentDidMount() {
            // // ToolbarService.getData = toolBarGetData;
            
            if (this.service != null) {
                let toolbardata: ToolbarProxy = {};
                this.toolbarService = this.service.getToolbar();
                this.toolbarService.Load().then(data => {
                        if (data != null)
                            this.props.RefreshToolbar(data);
                    })
                    

                const { match } = this.props;
                debugger;
                // chiamo l'api di render di default del componente
                if (this.service.RenderApi != null && this.service.RenderApi != '')
                    
                    this.apiClient.getAsync<any>(this.service.RenderApi, match.params == null ? null : { id: match.params.id })
                        .then(resp => {
                            this.compData = resp;
                            this.setState({ ...this.state, isLoading: false });
                            return resp;
                        });
                else this.setState({ ...this.state, isLoading: false });
            
            }
            else {
                this.setState({ ...this.state, isLoading: false });
            }
            
        }


        buttonClick(obj) {
            if (this.service != null) this.service.getToolbar().buttonClicked(obj.name);
        }

        addButtonClick(name, delegate) {
            if (this.service != null) this.service.getToolbar().addButtonClick(name, delegate);
        }

        backClick() {
            
            if (this.service != null) this.service.getToolbar().backClicked();
        }

        render() {


            if (!this.authService.isAuthenticated()) this.authService.restart();
            // console.log(`props : ${this.props}`);
            return (
                this.state.isLoading
                    ? <div className="parent">
                        <div className="spinner">
                            <GridLoader color={Colors.VerdeIstituzionale} size={20} loading={true} />
                        </div>
                    </div>
                    : <Spin spinning={this.props.apiloader.isLoading} >
                        <QueueAnim type="bottom" className="ui-animate">
                            <div key="1">
                                <ContainerLayout boxed={isBoxed} title={Title} toolbarData={this.props.toolbar}
                                    onButtonClick={this.buttonClick}
                                    onBackClick={this.toolbarService.isBackEnabled ? this.backClick : null}>
                                    <WrappedComponent componentData={this.compData} {...this.props} />
                                </ContainerLayout>
                            </div>
                        </QueueAnim>
                    </Spin>
            );
        }
    };

    const mapStateToProps = state => ({
        ...state
    });

    const mapDispatchToProps = dispatch => bindActionCreators(
        actions,
        dispatch,
    );

    return ReactRedux.connect(mapStateToProps, mapDispatchToProps)(BaseClass);
}


export function TTComponentBoxed(WrappedComponent: React.ComponentType<any> | React.SFC<any>, Title: string, componentService: symbol): React.ComponentType<any>
{
    return TTComponentBox(WrappedComponent, Title, componentService, true);
}

export function TTComponent(WrappedComponent: React.ComponentType<any> | React.SFC<any>, Title: string, componentService: symbol): React.ComponentType<any>
 {
    return TTComponentBox(WrappedComponent, Title, componentService, false);
}

export default TTComponent;