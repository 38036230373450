import { IActionBase, ActionBase } from "./ActionBase";
import { injectable } from "inversify";
import { ApiLoaderStoreModel } from "../../Models/ApiLoaderStoreModel";

export interface IApiLoaderAction extends IActionBase<ApiLoaderStoreModel>{
    SetIsLoading();
    SetIsFinished();
}

@injectable()
export class ApiLoaderActions extends ActionBase<ApiLoaderStoreModel>
{
    API_ISLOADING_TYPE ='API_ISLOADING';    
    API_FINISHED_TYPE ='API_FINISHED';    


    SetIsLoading(){
        this.Dispatch(null,this.API_ISLOADING_TYPE);
    };

    API_ISLOADING():any
    {
        return {...this.state,
                 isLoading:true };
    }   

    SetIsFinished(){
        this.Dispatch(null,this.API_FINISHED_TYPE);
    };

    API_FINISHED():any
    {
        return {...this.state,
                 isLoading:false };
    }

}