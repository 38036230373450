import { Reducer, Action } from 'redux';
import { IActionModel } from './Actions/ActionBase';
import container from '../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import { IHeadRefreshAction } from './Actions/HeadRefreshAction';

export interface KeyPair{
  Key:string;
  Value:string;
}
export interface IHeader extends IActionModel {
    role:string;
    user: string;
    roles: KeyPair[];
    notification?: (NotificationEntity)[] | null;
  }

export class HeaderModel implements IHeader{
  get type(): string{
      return 'REFRESH_HEAD';
  }
  role: string;
  user: string;
  roles: KeyPair[];
  notification?: NotificationEntity[];
}

export interface NotificationEntity {
    title: string;
    descrizione: string;
    tipo: number;
  }
  
  const reducer: Reducer<HeaderModel> = (state: HeaderModel = new HeaderModel(), action) => {
    const service =container.get(SERVICE_IDENTIFIER.HEADER_ACTION) as IHeadRefreshAction;
    return service.Eval(state,action);
    // switch ((action as Action).type) {      
    //   case 'REFRESH_HEAD':
    //     return {
    //       ...state,
    //       ...action.payload
    //     };
    //   default:
    //     return state;
    // }
  };
  
  export default reducer;