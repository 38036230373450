import { PagedBaseState } from './PagedBaseState'
import { ModelBase } from './ModelBase'


export class ClientiProjection extends ModelBase {
    public Id: string
    public Descrizione: string
    public CodiceFiscale: string
    public PartitaIva: string
    public Citta: string
    public TipoCliente: string
    public TipoContabilita: string
    public Liquidazione: string
    public TipoRegimeIva: string
    public CodiceIstat: string
}

export class ClientiState extends PagedBaseState<ClientiProjection> {

}