import * as React from 'react'
import { TTModal, IModalProps } from '../TTModal';
import { ModalProps } from 'antd/lib/modal';
import { FileSystemWindow } from './FileSystem';
import { FilesystemVirtualeStateEditModel } from '../../../Models/FilesystemVirtualeModels';

export interface IModalMoveProps {
    clienteId: string
}
export class ModalMoveState {
    public folderId: string
    public okButtonDisabled: boolean
}

export class ModalMoveFile extends React.Component<IModalProps & ModalProps & IModalMoveProps, ModalMoveState> {
    constructor(props) {
        super(props)

        this.state = {
            folderId: null,
            okButtonDisabled: true
        }
    }


    handleSelectChange = (item: FilesystemVirtualeStateEditModel) => {
        if (item != null && item.IsDirectory == true) {
            this.setState({ folderId: item.Id })
        }

        this.setState({ okButtonDisabled: item == null || item.IsDirectory == false })
    }

    confirmSelection = () => {
        this.props.do(this.state.folderId)
    }

    closeModal = () => {
        this.setState({ folderId: null, okButtonDisabled: true })
        this.props.closeModal()
    }

    render() {
        return (
            <TTModal title="Sposta file" visible={this.props.visible} closeModal={this.closeModal} do={this.confirmSelection}
                okButtonProps={{ disabled: this.state.okButtonDisabled }} destroyOnClose>
                <FileSystemWindow clienteId={this.props.clienteId}
                    selectionMode={true}
                    showUploader={false}
                    foldersOnly={true}
                    selectedItem={this.handleSelectChange} />
            </TTModal>
        )
    }
}