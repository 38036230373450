import * as React from 'react'
import { Spin, Calendar, DatePicker } from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router'

const { MonthPicker } = DatePicker;

import container from '../../../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'

import { IAppTaskService } from '../../../Services/AppTaskService'
import { AdempimentoModel } from '../../../Models/AppAdempimentiModels'

import { TTComponentBoxed } from '../../../Shared/BaseComponent/TTComponentTs'
import { ModalAdempimenti } from './ModalAdempimenti'
import { RenderHeader } from 'antd/lib/calendar/Header'
import { IAppAdempimentiService } from '../../../Services/AppAdempimentiService';

class PageAdempimentiState {
    IsLoading: boolean
    Adempimenti: { [key: string]: Array<AdempimentoModel> }

    ModalVisible: boolean
    SelectedData: Array<AdempimentoModel>
    SelectedMoment: moment.Moment
}

const keyDateFormat = 'YYYY-MM-DD'
export class PageAdempimentiComp extends React.Component<any, PageAdempimentiState> {

    service: IAppTaskService
    adempimenti: { [key: string]: Array<AdempimentoModel> } = { "": [] }


    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.APP_TASK_SERVICE)
        this.state = new PageAdempimentiState()

        let toolbar = container.get<IAppAdempimentiService>(SERVICE_IDENTIFIER.APP_ADEMPIMENTI_SERVICE).getToolbar()
        toolbar.addBackClick(() => this.props.history.push("/"))
    }

    async componentDidMount() {
        this.setState({ IsLoading: true })

        let resp = await this.service.GetAdempimenti()
        if (resp.IsValid) {
            resp.Adempimenti.forEach(e => this.mapDate(e))
            this.setState({ Adempimenti: this.adempimenti })
        }

        this.setState({ IsLoading: false })
    }

    mapDate = (item: AdempimentoModel) => {
        let strDate = moment(item.Scadenza).format(keyDateFormat)
        if (!this.adempimenti[strDate])
            this.adempimenti[strDate] = [item]
        else
            this.adempimenti[strDate].push(item)
    }

    openModal = (date: moment.Moment) => {
        let data = this.getFromDate(date)
        this.setState({
            ModalVisible: true,
            SelectedMoment: date,
            SelectedData: data
        })
    }

    closeModal = () => {
        this.setState({ ModalVisible: false, SelectedMoment: moment() })
    }

    getFromDate = (date: moment.Moment) => {
        let key = date.format(keyDateFormat)
        return this.state.Adempimenti[key]
    }

    renderDateCell = (date: moment.Moment) => {
        if (!this.state.Adempimenti) return

        let data = this.getFromDate(date)
        if (data)
            return (
                <ul>
                    {data.map(item => <li key={item.Id}>{item.Titolo}</li>)}
                </ul>
            )
    }

    renderHeader = (param: RenderHeader): React.ReactNode => {
        const monthFormat = 'MMMM YYYY';
        return (
            <div style={{ padding: 10, textAlign: 'center' }}>
                <MonthPicker
                    onChange={(e) => param.onChange(e)}
                    defaultValue={moment()}
                    value={param.value}
                    format={monthFormat}
                    allowClear={false}
                />
            </div>
        );
    }

    render() {
        return (
            <Spin spinning={this.state.IsLoading}>
                <Calendar
                    dateCellRender={e => this.renderDateCell(e)}
                    onSelect={e => this.openModal(e)}
                    headerRender={({ value, type, onChange, onTypeChange }) => this.renderHeader({ value, type, onChange, onTypeChange })} />
                {
                    this.state.ModalVisible
                        ? <ModalAdempimenti data={this.state.SelectedData} moment={this.state.SelectedMoment} closeModal={() => this.closeModal()} />
                        : <></>
                }
            </Spin>
        )
    }
}
export const PageAdempimenti = withRouter(TTComponentBoxed(PageAdempimentiComp, 'Calendario adempimenti', SERVICE_IDENTIFIER.APP_ADEMPIMENTI_SERVICE))