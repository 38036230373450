import { ToolbarService} from './ToolbarService/ToolbarServiceTs';
import { inject, injectable } from "inversify";
import { ToolbarModel } from './ToolbarService/ToolbarModelMockTs';

@injectable()
    export class Service extends ToolbarService{
        //getData(): string {
             
        //  return JSON.stringify(ToolbarModel);
        //}
        
    }
