export const PROVIDERS_REGISTRATION = {
    TEST: 'Test',
    RICERCASTIPULATI: 'RicercaStipulati',
    RICERCAUTENTI: 'RicercaUtenti',
    EDITUTENTI: 'EditUtenti',
    ADEMPIMENTI: 'Adempimenti',
    ADEMPIMENTO: 'Adempimento',
    ADEMPIMENTOCUSTOMER: 'AdempimentoCustomer',
    ADEMPIMENTOEDIT: 'AdempimentoEdit',
    ADEMPIMENTONOTIFICATION: 'AdempimentoNotification',
    GRUPPO: 'Gruppo',
    GRUPPOEDIT: 'GruppoEdit',
    CLIENTE: 'Cliente',
    SUPPORTO: 'Supporto',
    CLIENTEUTENTE: 'ClienteUtente',
    CLIENTEUTENTEEDIT: 'ClienteUtenteEdit',
    CLIENTI: 'Clienti',
    DRAWER: 'Drawer',
    HOME: 'Home',
    SERVIZI: 'Servizi',
    CIRCOLARI: 'Circolari',
    CIRCOLAREEDIT: 'CircolareEdit',
    TENANTEDIT: 'TenantEdit',
    IMPORTFATTURATO: 'ImportFatturato',
    IMPORTCLIENTI: 'ImportClienti',
    IMPORTSCADENZE: 'ImportScadenze',
    FIRMA: 'Firma',
    APISERVICEEXT: 'ApiServiceExt',
    APISERVICE: 'ApiService',
    UTENTIBOSERVICE: 'PageUtentiBORicerca',
    EDITUTENTIBO: 'UtentiBOEdit',
    LICENZE: 'Licenze',
    LICENZACLIENTI: 'LicenzaClienti',

    APP_DASHBOARD: 'AppDashboard',
    APP_ATTIVITA_LIST: 'AppAttivitaList',
    APP_ATTIVITA: 'AppAttivita',
    APP_INFORMATIVE_LIST: 'AppInformativeList',
    APP_SCADENZE_LIST: 'AppScadenzeList',
    APP_CIRCOLARI_LIST: 'AppCircolariList',
    APP_CIRCOLARE: 'AppCircolare',
    APP_ADEMPIMENTI: 'AppAdempimenti',
    APP_DOCUMENTI: 'AppDocumenti',
    APP_INFO_ASSISTENZA: 'AppInfoAssistenza',
};

