import { injectable } from "inversify"

import * as Api from "../Constants/AppApiUrlConstants"
import container from '../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from "../Wires/Identifiers"

import { IApiService } from "./Shared/ApiService"
import { IApiServiceExt } from "./Shared/ApiServiceExt"

import { OfficeData, SupportoAppModel } from "../Models/AppOfficeModels"

export interface IAppOfficeService {
    GetOfficeData(): Promise<OfficeData> 
    GetTootechData(): Promise<SupportoAppModel>
}

@injectable()
export class AppOfficeService implements IAppOfficeService {

    apiService: IApiService
    apiServiceExt: IApiServiceExt

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
        this.apiServiceExt = container.get(SERVICE_IDENTIFIER.API_SERVICE_EXT)
    }

    async GetOfficeData(): Promise<OfficeData> {
        return await this.apiService.getAsync<OfficeData>(Api.GetOfficeData, {})
    }

    async GetTootechData(): Promise<SupportoAppModel> {
        return await this.apiService.getAsync<SupportoAppModel>(Api.GetTootechData, {})
    }
}