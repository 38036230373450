import { injectable } from "inversify";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { IBaseComponentService, BaseComponentService } from "./Shared/BaseComponentService";
import { ModelBase } from "../Models/ModelBase";
import { IFormDataConverter } from "./Shared/FormDataConverter";
import { IApiServiceExt } from "./Shared/ApiServiceExt";

export interface IImportBaseService extends IBaseComponentService {
    Upload(file: any): Promise<ModelBase>
}

@injectable()
export abstract class ImportBaseService extends BaseComponentService implements IImportBaseService {

    RenderApi: string = ''
    _apiService: IApiServiceExt
    _formDataConverter: IFormDataConverter
    abstract UploadUrl: string

    constructor() {
        super()
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT)
        this._formDataConverter = IoCContainer.get(SERVICE_IDENTIFIER.FORMDATA_CONVERTER);
    }

    async Upload(file: any): Promise<ModelBase> {
        var input = this._formDataConverter.convert(file);
        return await this._apiService.postAsync<any>(this.UploadUrl, input, "Dati importati correttamente");
    }
}