import * as React from 'react';
import { RouteComponentProps } from "react-router";
import { Row, Col } from 'antd';
import { withRouter } from 'react-router';

import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import { AdempimentoCover } from './AdempimentoCover';
import { AdempimentoActivities } from './AdempimentoActivities';
import { AdempimentoCustomers } from './AdempimentoCustomers';

import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { AdempimentoState } from './PageAdempimentoEdit';
import { IAdempimentoEditService } from '../../../Services/AdempimentoEditService';

const ComponentService: symbol = SERVICE_IDENTIFIER.ADEMPIMENTO_EDIT_SERVICE;

export class PageAdempimento extends React.Component<IStoreModel & RouteComponentProps & ITTProps, AdempimentoState> {

    private _service: IAdempimentoEditService

    constructor(props) {
        super(props);

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.ADEMPIMENTO_EDIT_SERVICE);
        this._service.getToolbar().addBackClick(() => this.props.history.push("/Adempimenti"));
        this._service.getToolbar().addButtonClick("ModificaAdempimento", this.editAdempimento);
        this._service.getToolbar().addButtonClick("CopiaAdempimento", this.copyAdempimento);
        this._service.getToolbar().renderButton("Salva", true);

        this.state = this.props.componentData;

    }

    editAdempimento = () => {
        this.props.history.push(`/AdempimentoEdit/${this.state.Id}`);
    }

    copyAdempimento = () => {
        this.props.history.push(`/AdempimentoCopy/${this.state.Id}`);
    }

    render() {
        return (
            <div>
                <Row type="flex" gutter={24}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <AdempimentoCover
                            title={this.state.Descrizione}
                            description={this.state.Note}
                            date={this.state.Scadenza} />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <AdempimentoActivities activities={this.state.Attivita} />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col span={24}>
                        <AdempimentoCustomers id={this.state.Id} {...this.props} />
                    </Col>
                </Row>
            </div>
        );
    }

}

const Adempimento = withRouter(TTComponent(PageAdempimento, 'Adempimento', ComponentService));
export default Adempimento;