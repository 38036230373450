import { IToolbarService } from "./ToolbarService/ToolbarServiceTs";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";
import { injectable } from "inversify";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import * as Api from "../Constants/ApiUrlConstants";

export interface ITestService extends IBaseComponentService {
  
}
@injectable()
export default class TestService extends BaseComponentService implements ITestService
{
  RenderApi: string = Api.TpTestRender;
  getProviderName(): string {
    return PROVIDERS_REGISTRATION.TEST;
  }
  
  constructor(){  
     
    super();//chiama il costruttore di default    
  }  

  Event():void{
    
    console.log("evento");
  }
}
