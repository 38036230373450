import { injectable } from "inversify"

import * as Api from "../Constants/AppApiUrlConstants"
import container from '../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from "../Wires/Identifiers";

import { IApiService } from "./Shared/ApiService";
import { IApiServiceExt } from "./Shared/ApiServiceExt";

import { ModelBase } from "../Models/ModelBase";
import { TasksStatus } from "../Models/AppTaskModels"
import { ActivityList, ActivityItem, ActivityAction } from "../Models/AppActivityModels";
import { ScadenzeList } from "../Models/AppScadenzeModels"
import { CircolariList, CircolariItem } from "../Models/AppCircolariModels";
import { AdempimentiList } from "../Models/AppAdempimentiModels";

export interface IAppTaskService {
    GetTasksStatus(): Promise<TasksStatus>
    GetActivities(TodoOnly: boolean): Promise<ActivityList>
    GetActivity(RichiestaId: string): Promise<ActivityItem>
    PostActivityUserFeedback(RichiestaId: string, UserResponse: ActivityAction): Promise<ModelBase>
    GetInfoActivities(ToReadOnly: boolean): Promise<ActivityList>
    GetScadenze(EvaseOnly: boolean): Promise<ScadenzeList>
    GetCircolari(): Promise<CircolariList>
    GetCircolare(CircolareId: string): Promise<CircolariItem>
    PostCircolareRead(CircolareId: string): Promise<ModelBase>
    GetAdempimenti(): Promise<AdempimentiList>
}

@injectable()
export class AppTaskService implements IAppTaskService {

    apiService: IApiService
    apiServiceExt: IApiServiceExt

    constructor() {
        this.apiService = container.get<IApiService>(SERVICE_IDENTIFIER.API_SERVICE)
        this.apiServiceExt = container.get<IApiService>(SERVICE_IDENTIFIER.API_SERVICE_EXT)
    }

    async GetTasksStatus(): Promise<TasksStatus> {
        return await this.apiService.getAsync<TasksStatus>(Api.GetTasksStatus, {})
    }

    async GetActivities(TodoOnly: boolean): Promise<ActivityList> {
        return await this.apiService.getAsync<ActivityList>(Api.GetActivities, { TodoOnly });
    }

    async GetActivity(RichiestaId: string): Promise<ActivityItem> {
        return await this.apiService.getAsync<ActivityItem>(Api.GetActivity, { RichiestaId });
    }

    async PostActivityUserFeedback(RichiestaId: string, UserResponse: ActivityAction): Promise<ModelBase> {
        return await this.apiServiceExt.postAsync<ModelBase>(Api.PostActivityUserFeedback, { RichiestaId, UserResponse })
    }

    async GetInfoActivities(ToReadOnly: boolean): Promise<ActivityList> {
        return await this.apiService.getAsync<ActivityList>(Api.GetInfoActivities, { ToReadOnly });
    }

    async GetScadenze(EvaseOnly: boolean): Promise<ScadenzeList> {
        return await this.apiService.getAsync<ScadenzeList>(Api.GetScadenze, { EvaseOnly });
    }

    async GetCircolari(): Promise<CircolariList> {
        return await this.apiService.getAsync<CircolariList>(Api.GetCircolari, {});
    }

    async GetCircolare(CircolareId: string): Promise<CircolariItem> {
        return await this.apiService.getAsync<CircolariItem>(Api.GetCircolare, { CircolareId });
    }

    async PostCircolareRead(CircolareId: string): Promise<ModelBase> {
        return await this.apiService.postAsync<ModelBase>(Api.PostCircolareRead, { CircolareId });
    }

    async GetAdempimenti(): Promise<AdempimentiList> {
        return await this.apiService.getAsync<AdempimentiList>(Api.GetAdempimenti, {  });
    }
}