import * as React from 'react';
import { Row, Col, Card, List, Avatar } from 'antd';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'

import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IClienteService } from '../../../Services/ClienteService';
import IoCContainer from '../../../Wires/Bootstrapper';

import { ClienteCover } from './ClienteCover'
import { ClienteAdempimenti } from './ClienteAdempimenti'
import { ClienteFatturato } from './ClienteFatturato'
import { ClienteNotifications } from './ClienteNotifications'
import { ClienteScadenze } from './ClienteScadenze'
import { ClienteUtenti } from './ClienteUtenti'
import { ModalEmail } from './ModalEmail';
import { StatoLicenza } from '../../../Constants/StatoLicenza';
import { TTModal } from '../TTModal';

const ComponentService: symbol = SERVICE_IDENTIFIER.CLIENTE_SERVICE;

export class PageClienteState {
    public IdCliente: string
    EmailModalVisible: boolean
    StatoLicenza: StatoLicenza
}

export class PageCliente extends React.Component<any & IStoreModel & RouteComponentProps & ITTProps & FormComponentProps, PageClienteState> {

    private _service: IClienteService;

    constructor(props) {
        super(props);
        this.state = this.props.componentData || new PageClienteState();

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE);
        this._service.getToolbar().addBackClick(() => this.props.history.push("/Clienti"));
        this._service.getToolbar().addButtonClick("DocCliente", this.onDocumentsClick);
        this._service.getToolbar().addButtonClick("CreateUser", this.onCreateUserClick);
        this._service.getToolbar().addButtonClick("BuyLicense", this.onBuyLicense);

    }

    componentDidMount() {
        const { match } = this.props;

        this.setState({ IdCliente: match.params.id });
    }

    onBuyLicense = () => {
        if (this.state.StatoLicenza == StatoLicenza.InScadenza)
            TTModal.confirm({
                title: "Licenza già attiva per il cliente",
                content: <span>Il cliente ha già una licenza attiva.<br />
                    Continuare con l'acquisto di una nuova licenza?</span>,
                onOk: () => this.toggleEmailModal(true)
            })
        else
            this.toggleEmailModal(true)
    }

    toggleEmailModal = (visible: boolean) => {
        this.setState({ EmailModalVisible: visible })
    }

    onEmailReceived = (data: any) => {
        let email = data[ModalEmail.EmailKey]
        this._service.CompraLicenza(this.state.IdCliente, email);
    }

    onDocumentsClick = () => {
        this.props.history.push('/fs/' + this.state.IdCliente)
    }

    onCreateUserClick = () => {
        this.props.history.push('/ClienteUtenteEdit/' + this.state.IdCliente)
    }

    toggleBuyVisibility = (status: StatoLicenza) => {
        let visible = status == StatoLicenza.NonTrovata ||
            status == StatoLicenza.NonAttiva ||
            status == StatoLicenza.InScadenza

        this._service.getToolbar().renderButton("BuyLicense", !visible)
        this.setState({ StatoLicenza: status })
    }

    render() {
        return (
            <div>
                <Row type="flex" gutter={24}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }}>
                        <ClienteCover clienteId={this.state.IdCliente} {...this.props} onLicenseStatusRecived={this.toggleBuyVisibility} />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} >
                        <ClienteNotifications clienteId={this.state.IdCliente} />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                        <ClienteScadenze clienteId={this.state.IdCliente} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <ClienteAdempimenti clienteId={this.state.IdCliente} {...this.props} />
                    </Col>
                </Row>
                <br />
                <Row gutter={24}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                        <div className="last-card">
                            <ClienteFatturato clienteId={this.state.IdCliente} />
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                        <div className="last-card">
                            <ClienteUtenti Cliente={this.state.IdCliente} {...this.props} />
                        </div>
                    </Col>
                </Row>

                <ModalEmail visible={this.state.EmailModalVisible} do={this.onEmailReceived} closeModal={() => this.toggleEmailModal(false)} />
            </div>
        );
    }
}

const Cliente = withRouter(TTComponent(PageCliente, 'Cliente', ComponentService));
export default Cliente;
