import axios, { AxiosInstance } from 'axios';
import SERVICE_IDENTIFIER from '../../Wires/Identifiers';
import { INotifyService } from './NotifyService';
import { injectable } from "inversify";
import { IAuthService } from '../Security/AuthServiceTs';
import { Store } from 'redux';
import { IApiLoaderAction } from '../../Redux/Reducers/Actions/ApiLoaderAction';
import { IErrorHandlerAction } from '../../Redux/Reducers/Actions/ErrorHandlerAction';
import IoCContainer from '../../Wires/Bootstrapper';
import { IFormError } from './../../Models/FormValidationModels'

export interface IApiService {
    get<T>(url: string, params: any, success: (resp:T)=>void, error: (err:any)=>void): void;
    getAsync<T>(url:string, params:any) : Promise<T>
    getString(url: string, params: any, success: (resp:string)=>void, error: (err:any)=>void): void;
    post<T>(url: string, params: any, success: (resp: T) => void, error: (err: string) => void): void;
    postTokenAsync<T>(url: string, params: any, token: string): Promise<T>
    postString(url: string, params: any, success: (resp:string)=>void, error: (err:string)=>void): void;
    postAsync<T>(url:string, params:any): Promise<T>
}

@injectable()
export class ApiService implements IApiService {
    client: AxiosInstance=axios.create();
    _notifyService : INotifyService;    
    authService : IAuthService;
    _store:Store<any>;
    protected _apiLoaderAction : IApiLoaderAction;
    _errorHandlerAction: IErrorHandlerAction

    baseurl: string = process.env.REACT_APP_BASEURL;
    TITLE: string = 'Attenzione';

    constructor()
    {        
        this.client.interceptors.response.use(this.handleResponeSucces,this.handleResponeError);
        this.client.interceptors.request.use(this.handleRequest,this.handleRequestError);
        this._notifyService = IoCContainer.get(SERVICE_IDENTIFIER.NOTIFY);
        this.authService = IoCContainer.get(SERVICE_IDENTIFIER.AUTH_SERVICE);
        this._store = IoCContainer.get(SERVICE_IDENTIFIER.STORE);
        this._apiLoaderAction = IoCContainer.get(SERVICE_IDENTIFIER.API_LOADER_ACTION);
        this._errorHandlerAction = IoCContainer.get(SERVICE_IDENTIFIER.ERROR_HANDLER_ACTION);
    }

    protected handleResponeSucces = (response) => {        
        this._apiLoaderAction.SetIsFinished();
        if (response.data.IsValid) {            
            return response;
        }

        if (response.data.Notifies != null) {
            // NotifyType.Validation = 3
            var validation = response.data.Notifies.filter(it => it.Type == 3)
            if (validation.length > 0) {
                response.data.FormValidation = this.notifyToValidation(validation)
            }

            // Gestione altre notifiche
            var notifies = response.data.Notifies.filter(it => it.Type != 3)
            if (notifies.length > 0) {
                var messages = notifies.map(it => it.Message)
                var message = messages.join("\n")
                this._notifyService.error(this.TITLE, message);
            }
        }
        
        return response;
    }

    // Creazione oggetto per validazione unobtrusive delle form
    protected notifyToValidation(notifies: Array<any>) {
        var obj: IFormError = {}
        notifies.forEach(it => {
            var prop = it.Property

            // Creo voce in obj se non presente
            if (!obj.hasOwnProperty(prop)) {
                obj[prop] = {
                    errors: []
                }
            }

            // Accodo le notifiche per property specifica
            obj[prop].errors.push(new Error(it.Message))
        })

        return obj
    }
      
    protected handleResponeError = (err) => {                
        this._apiLoaderAction.SetIsFinished();

        if (err.response.status === 401 || //token invalido o scaduto
            err.response.status === 406) {            
            this.authService.signOutAndReload();
            return Promise.reject(err);
        }

        if (err.response.status == 402) {
            this._notifyService.error('Servizio non abilitato', "Non è stata trovata una licenza valida")
            return
        }

        
        if (err.response.status === 403) {
            this._notifyService.error('Autorizzazione Negata', err.response.data.Message);
            return;
        }

        this._errorHandlerAction.ShowError(err.response);
        //this._store.dispatch(ErrorShow({ isError:true, message : err.response}));
            // { type: 'ERROR_SHOW', payload : { isError:true, message : err.response.data.Message} });

        return Promise.reject(err);;
    };

    protected handleRequest = (config) => {
    
        const token = this.authService.getToken();

        if (token != null) {
        config.headers.Authorization = `Bearer ${token}`;
        }

        this._apiLoaderAction.SetIsLoading();
        return config;
    }

    protected handleRequestError=error =>{
        // Do something with request error
        this._apiLoaderAction.SetIsFinished();
        Promise.reject(error);
    }
        
    public get<T>(url:string, params:any, success:(resp:T)=>void, error:(err:any)=>void)
    {
        
        this.client.get(this.baseurl + url,params).then(resp=> {
            //let data = SerializationHelper.toInstance<T>(resp.data)
            this.handleSucces(resp,success);
        }).catch(err=>{  
            this.handleError(err,error);
            }
        );
    }

    private PrintStandardMessage(err: any): void{
        
        if (err.response.status === 401 || //token invalido o scaduto
            err.response.status === 406) 
            {
                this._notifyService.error(this.TITLE, 'Sessione scaduta');
                return;
            }

        this._notifyService.error("Errore",err.message);
    }

    public async getAsync<T>(url:string, params:any) : Promise<T>
    {
        return await this.client.get(this.baseurl + url,{params: {...params} } ).then((resp)=>this.handleSucces(resp,null)).catch((err)=>this.handleError(err,null));
        //let data = SerializationHelper.toInstance<T>(resp.data,type)
    }

    public getString(url:string, params:any, success:(resp:string)=>void, error:(err:any)=>void)
    {
        this.client.get(this.baseurl + url,{params: {...params} }).then(resp=> this.handleSucces(resp,success)).catch(err=>
            {
                this.handleError(err,error);
            });
    }

    public post<T>(url:string, params:any, success:(resp:T)=>void, error:(err:string)=>void)
    {        
        this.client.post(this.baseurl + url, params).then(resp=> {
           // let data = SerializationHelper.toInstance<T>(resp.data)
           this.handleSucces(resp,success)
        }).catch(err => {
            
            this.handleError(err,error);
        });
    }

    public async postTokenAsync<T>(url: string, params: any, token: string): Promise<T> {
        return await this.client.post(this.baseurl + url, params, { headers: { Authorization: `Bearer ${token}` } })
            .then(resp => this.handleSucces(resp, null))
            .catch(err => this.handleError(err, null));
    }

    public async postAsync<T>(url:string, params:any): Promise<T>
    {
        return await this.client.post(this.baseurl + url, params).then((resp)=>this.handleSucces(resp,null)).catch((err)=>this.handleError(err,null));
    }


    public postString(url:string, params:any, success:(resp:string)=>void, error:(err:string)=>void)
    {
        this.client.post(this.baseurl + url, params).then(resp=> this.handleSucces(resp,success)).catch(err=>
            {
                this.handleError(err,error);
            });
    }

    protected handleError(err, callback)
    {        
        if(callback!=null) 
            callback(err);
        else
            this.PrintStandardMessage(err);
    }

    protected handleSucces(resp, success):any
    {
        
        let ret = resp;
        if(resp==null) ret ={data :{isValid : false}};
        if(success!=null) {
            success(ret.data);
            return;
        }
        return ret.data;

    }

}

