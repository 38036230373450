import React from 'react';
import { Provider } from 'react-redux';
//import { ConnectedRouter as Router, routerMiddleware } from 'react-router-redux';
import { BrowserRouter } from 'react-router-dom';

import ReactDOM from 'react-dom';
//import {applyMiddleware, compose} from 'redux';
import { createStore } from 'redux';
import container from './Wires/Bootstrapper';
import registerServiceWorker from './registerServiceWorker';


import reducers from './Redux/Reducers/indexTs';

import App from './app/AppLayoutTs';
import SERVICE_IDENTIFIER from './Wires/Identifiers';

import { ConfigProvider } from 'antd';
import itIT from 'antd/es/locale/it_IT'
import { ttRenderEmpty } from './app/Contents/TTEmpty';

//const history = createHistory();
//const middleware = routerMiddleware(history);
///* eslint-disable no-underscore-dangle */
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

//container.bind(SERVICE_IDENTIFIER.STORE).toConstantValue(store);

const store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


container.bind(SERVICE_IDENTIFIER.STORE).toConstantValue(store);
/* eslint-enable */

ReactDOM.render(
    
    <Provider store={store}>
        <BrowserRouter>
            <ConfigProvider locale={itIT} renderEmpty={ttRenderEmpty}>
                    <App />
                </ConfigProvider>
                    {/* <Route path="/" component={App} exact /> */}
            </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);


export default store;



//ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
