import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { ComponentService, IComponentService } from "./Shared/ComponentService";
import { IApiService } from "./Shared/ApiService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { GruppoProjection } from "../Models/GruppoProjection";

export interface IGruppoService extends IComponentService<GruppoProjection> {   
    
}

@injectable()
export class GruppoService extends ComponentService<GruppoProjection> implements IGruppoService {
    protected SearchUrl: string = Api.GetGruppi;

    RenderApi: string = Api.RenderGruppi;    

    constructor() {
        super();        
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.GRUPPO;
    }       

    
}