import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import  settings  from './settings';
import header from './HeaderTs';
import toolbar from './Toolbar';
import error from './ErrorHandler'
import apiloader from './ApiLoader'

const reducers = {
  routing: routerReducer,
  settings,
  header,  
  toolbar,
  error,
  apiloader
};

export default combineReducers(reducers);
