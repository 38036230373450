import * as React from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button';
import * as SignState from "../../../Constants/SignConstants";

export interface ISignButtonProps {
    state: string
}

export const SignButton: React.FC<ISignButtonProps & ButtonProps> = (props) => {

    let icon = 'download'

    switch (props.state) {
        default:
            icon = 'team'
            break;
        case SignState.Bozza:
            icon = 'bell'
            break;
        case SignState.NotificaBozza:
            icon = 'bell'
            break;
        case SignState.DaFirmare:
            icon = 'bell'
            break;
        case SignState.Firmata:
            icon = 'bell'
            break;
        case SignState.Annullata:
            icon = 'bell'
    }

    return (
        <Button type="primary" icon={icon} {...props}>{props.children}</Button>
    )
}