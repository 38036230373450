import * as React from 'react'
import { Row, Col, Card } from 'antd'
import { withRouter } from 'react-router'

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'
import container from '../../../Wires/Bootstrapper'
import { IAppTaskService } from '../../../Services/AppTaskService'

import { TTComponent } from '../../../Shared/BaseComponent/TTComponentTs'
import { AxesChartFatturato, FatturatoBarSeriesPoint } from './../../Contents/Clienti/AxesChartFatturato'
import { TasksStatus, FatturatoTrendModel } from '../../../Models/AppTaskModels'
import { Tile } from './Tile'
import { CardContatti } from './CardContatti'

class PageDashboardState extends TasksStatus {
    FattCurrent: Array<FatturatoBarSeriesPoint>
    FattPrevious: Array<FatturatoBarSeriesPoint>
}

export class PageDashboardComp extends React.Component<any, PageDashboardState> {

    service: IAppTaskService

    constructor(props) {
        super(props)
        this.service = container.get(SERVICE_IDENTIFIER.APP_TASK_SERVICE)
        this.state = new PageDashboardState()
    }

    componentDidMount = async () => {
        let resp = await this.service.GetTasksStatus()
        if (resp.IsValid) {
            let currYear = new Date().getFullYear()
            let curr = resp.Fatturato.Current.map(e => this.mapBar(e, currYear))
            let prev = resp.Fatturato.Previous.map(e => this.mapBar(e, currYear - 1))

            this.setState({
                ActivitiesCount: resp.ActivitiesCount,
                ScadenzeCount: resp.ScadenzeCount,
                CircolariCount: resp.CircolariCount,
                InformativeCount: resp.InformativeCount,
                FattCurrent: curr,
                FattPrevious: prev
            })
        }
    }

    mapBar = (e: FatturatoTrendModel, year: number) => {
        let model: FatturatoBarSeriesPoint = {
            x: e.Label,
            y: e.Value,
            color: e.FillColor,
            year
        }

        return model
    }

    navigateTo = (page: string) => {
        this.props.history.push(`/${page}`)
    }

    render() {
        return (
            <>
                <Card bordered={false}>
                    <Row type="flex" justify="space-around">
                        <Col lg={12} xl={4} span={24}>
                            <Tile tticon="attivita" title="Attività da completare" value={this.state.ActivitiesCount} onClick={() => this.navigateTo('AttivitaList')} />
                        </Col>
                        <Col lg={12} xl={4} span={24}>
                            <Tile tticon="scadenze" title="Scadenze questo mese" value={this.state.ScadenzeCount} onClick={() => this.navigateTo('Scadenze')} />
                        </Col>
                        <Col lg={12} xl={4} span={24}>
                            <Tile tticon="circolari" title="Circolari da leggere" value={this.state.CircolariCount} onClick={() => this.navigateTo('Circolari')} />
                        </Col>
                        <Col lg={12} xl={4} span={24}>
                            <Tile tticon="informative" title="Informative da leggere" value={this.state.InformativeCount} onClick={() => this.navigateTo('Informative')} />
                        </Col>
                    </Row>
                </Card>
                <br />
                <Card title="Andamento" bordered={false}>
                    <AxesChartFatturato
                        tickXLabelAngle={0}
                        barWidth={0.5}
                        currentYear={this.state.FattCurrent}
                        previousYear={this.state.FattPrevious} />
                </Card>
                <br />
                <CardContatti />
                <br />
            </>
        )
    }
}

export const PageDashboard = withRouter(TTComponent(PageDashboardComp, 'Dashboard', SERVICE_IDENTIFIER.APP_DASHBOARD_SERVICE))