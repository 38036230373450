import * as React from 'react';
import { Card, Icon, Row, Col } from 'antd';
import moment from 'moment';

import { IAdempimentoCover } from './../../../Models/IAdempimentoCover'
import { TTAvatar } from '../TTAvatar';


export function AdempimentoCover (props: IAdempimentoCover) {
    return (
        <Card bordered={false} className="card-full-height" title={props.title}>
            <Row>
                <Col>{props.description}</Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <TTAvatar tticon="scadenze" />&nbsp;&nbsp;&nbsp;<span className="ant-list-item-meta-title">{moment(props.date).format('LL')}</span>
                </Col>
            </Row>
        </Card>
    );
}