import { injectable } from "inversify"
import { IApiService } from '../Shared/ApiService'

import * as Api from "../../Constants/ApiUrlConstants"
import SERVICE_IDENTIFIER from "../../Wires/Identifiers"
import IoCContainer from '../../Wires/Bootstrapper'

import { IHeadRefreshAction } from "../../Redux/Reducers/Actions/HeadRefreshAction"
import { HeaderModel } from "../../Redux/Reducers/HeaderTs"

export interface IHeadService {
    GetLoggedUser()
}

@injectable()
export default class HeadService implements IHeadService {

    private _apiService: IApiService
    private _action: IHeadRefreshAction;

    constructor() {

        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE)
        this._action = IoCContainer.get(SERVICE_IDENTIFIER.HEADER_ACTION);
    }

    async GetLoggedUser() {
        let resp = await this._apiService.getAsync<HeaderModel>(Api.GetLoggedUser, {})
        this._action.RefreshHead(resp as HeaderModel);
    }

}

