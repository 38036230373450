import * as React from 'react'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'

class SingleSelectTable {
    public SelectedKeys: Array<string> = []
}

export interface ITTSingleSelectTable<T> {
    onClick?: (arg: T) => void;
    onDoubleClick?: (arg: T) => void;
}

export class TTSingleSelectTable<T> extends React.Component<TableProps<T> & ITTSingleSelectTable<T>, SingleSelectTable> {

    constructor(props) {
        super(props)

        this.state = new SingleSelectTable()
    }

    // Aggiornamento dell'elemento selezionato
    onSelectChange = selectedRow => {
        this.setState({ SelectedKeys: [selectedRow] });
    }

    onTableRowClick = (item) => {
        this.onSelectChange(item.Id)
        this.props.onClick(item)
    }

    onTableRowDoubleClick = (item) => {
        this.props.onDoubleClick(item)
    }

    // Definizione comportamento sulla singola riga
    onTableRow = (item) => {
        return {
            onClick: () => this.onTableRowClick(item),
            onDoubleClick: () => this.onTableRowDoubleClick(item),
        };
    }

    render() {
        return (
            <Table
                onRow={record => this.onTableRow(record)}
                rowSelection={{
                    selectedRowKeys: this.state.SelectedKeys,
                    type: 'radio'
                }}
                {...this.props}
            />
        )
    }
}