import * as React from 'react'
import { Spin, PageHeader, Card, List } from 'antd'
import moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router'

import container from '../../../Wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'

import { IAppTaskService } from '../../../Services/AppTaskService'
import { IAppCircolareService } from '../../../Services/AppCircolariService'
import { IFileService } from '../../../Services/FileService'

import { RouteParamId } from '../RouteParamId'
import { CircolariItem } from '../../../Models/AppCircolariModels'

import TTComponent from '../../../Shared/BaseComponent/TTComponentTs'
import { TTIcon } from '../../Contents/TTIcon'


class PageCircolareState extends CircolariItem {
    IsLoading: boolean
}

export class PageCircolareComp extends React.Component<RouteComponentProps<RouteParamId>, PageCircolareState> {

    service: IAppTaskService

    constructor(props) {
        super(props)

        this.service = container.get(SERVICE_IDENTIFIER.APP_TASK_SERVICE)
        this.state = new PageCircolareState()

        let toolbar = container.get<IAppCircolareService>(SERVICE_IDENTIFIER.APP_CIRCOLARE_SERVICE).getToolbar()
        toolbar.addBackClick(() => this.props.history.push("/Circolari"))
    }

    async componentDidMount() {
        this.setState({ IsLoading: true })

        let id = this.props.match.params.id
        let resp = await this.service.GetCircolare(id)
        if (resp.IsValid) {
            this.setState(resp)

            if (resp.ToRead == true)
                await this.service.PostCircolareRead(id)
        }

        this.setState({ IsLoading: false })
    }

    onFileClick = () => {
        let fsService = container.get<IFileService>(SERVICE_IDENTIFIER.FILE_SERVICE)
        fsService.GetFile(this.state.ResourceId)
    }

    renderAttachment = () => {
        return <>
            <Card bordered={false} style={{ padding: "12px 24px 12px 24px" }}>
                <List.Item.Meta
                    avatar={<TTIcon icon="documento" style={{ fontSize: 40 }} ttstyle="accent" />}
                    title={<a onClick={() => this.onFileClick()}>{this.state.ResourceName}</a>}
                    description="Clicca sul nome per visualizzare" />
            </Card>
        </>
    }

    render() {
        let attachment = <></>
        if (this.state.ResourceId != null)
            attachment = this.renderAttachment()

        return (
            <Spin spinning={this.state.IsLoading}>
                <Card bordered={false}>
                    <PageHeader title={<h2>{this.state.Title}</h2>}>
                        <p>{this.state.Description}</p>
                        <br />
                        <br />
                        <List.Item.Meta
                            avatar={<TTIcon icon="scadenze" style={{ fontSize: 40 }} ttstyle="accent" />}
                            title={moment(this.state.Date).format('LL')}
                            description="Data creazione" />
                    </PageHeader>
                </Card>
                <br />
                {attachment}
            </Spin>
        )
    }
}

export const PageCircolare = withRouter(TTComponent(PageCircolareComp, 'Circolare', SERVICE_IDENTIFIER.APP_CIRCOLARE_SERVICE))