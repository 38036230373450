import * as React from 'react'
import { Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal';
import { ModalFunc, ModalFuncProps } from 'antd/lib/modal/Modal';
import { TTIcon } from './TTIcon';

export interface IModalProps {
    do: (obj: any | {}) => void
    closeModal: () => void
}

export class TTModal extends React.Component<IModalProps & ModalProps, any> {
    static info: ModalFunc;

    static success: ModalFunc;

    static error: ModalFunc;

    static warn: ModalFunc;

    static warning: ModalFunc;

    static confirm: ModalFunc;

    constructor(props) {
        super(props)
    }

    onCancel = () => {
        this.props.closeModal()
    }

    onOk = () => {
        this.props.do(this.state)
        this.props.closeModal()
    }

    render() {
        return (
            <Modal onCancel={this.onCancel} onOk={this.onOk} {...this.props}>
                {this.props.children}
            </Modal>
        )
    }
}

TTModal.info = function infoFn(props: ModalFuncProps) {
    const config = {
        icon: <TTIcon icon="info" ttstyle="accent" />,
        ...props,
    };
    return Modal.info(config);
};

TTModal.success = function successFn(props: ModalFuncProps) {
    const config = {
        icon: <TTIcon icon="conferma" ttstyle="accent" />,
        ...props,
    };
    return Modal.success(config);
};

TTModal.error = function errorFn(props: ModalFuncProps) {
    const config = {
        icon: <TTIcon icon="errore" ttstyle="accent" />,
        ...props,
    };
    return Modal.error(config);
};

TTModal.warning = function warningFn(props: ModalFuncProps) {
    const config = {
        icon: <TTIcon icon="attenzione" ttstyle="accent" />,
        ...props,
    };
    return Modal.warning(config);
};

TTModal.confirm = function confirmFn(props: ModalFuncProps) {
    const config = {
        icon: <TTIcon icon="attenzione" ttstyle="accent" />,
        ...props,
    };
    return Modal.confirm(config);
};
