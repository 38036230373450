import { injectable } from "inversify";

import * as Api from "../Constants/ApiUrlConstants";
import { BaseComponentService, IBaseComponentService } from "./Shared/BaseComponentService";

import IoCContainer from '../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from "../Wires/Identifiers";
import { PROVIDERS_REGISTRATION } from "../Constants/IocConstants";
import { IApiServiceExt } from "./Shared/ApiServiceExt";
import { ModelBase } from "../Models/ModelBase";
import { PageClienteUtenteState } from "../app/Contents/Clienti/PageClienteUtente";
import { ListaAccessiModel } from "../Models/LogAccessiModels";
import { IApiService } from "./Shared/ApiService";

export interface IClienteUtenteService extends IBaseComponentService {
    DeleteUser(data: PageClienteUtenteState): Promise<ModelBase>
    ResetPwd(userId: string): Promise<ModelBase>
    GetAccessi(userId: string): Promise<ListaAccessiModel>
}

@injectable()
export class ClienteUtenteService extends BaseComponentService implements IClienteUtenteService {

    RenderApi: string = Api.RenderClienteUtente;
    _apiServiceExt: IApiServiceExt;
    _apiService: IApiService;

    constructor() {
        super();
        this._apiServiceExt = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE_EXT);
        this._apiService = IoCContainer.get(SERVICE_IDENTIFIER.API_SERVICE);
    }

    getProviderName(): string {
        return PROVIDERS_REGISTRATION.CLIENTEUTENTE;
    }

    async DeleteUser(data: PageClienteUtenteState): Promise<ModelBase> {
        return await this._apiServiceExt.postAsync<any>(Api.DeleteClienteUtente, data, "Eliminazione avvenuta correttamente")
    }

    async ResetPwd(userId: string): Promise<ModelBase> {
        return await this._apiServiceExt.getAsync<any>(Api.ResetPwdClienteUtente, {idUtente: userId}, 'Password resettata')
    }

    async GetAccessi(userId: string): Promise<ListaAccessiModel> {
        return await this._apiService.getAsync<any>(Api.GetClienteUtenteAccessi, { UserId: userId})
    }
}