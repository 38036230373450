import { PagedBaseState } from "./PagedBaseState";

export class Customer {
    public Id: string;
    public Descrizione: string;
    public Progresso: number;
}

export class AdempimentoCustomersState extends PagedBaseState<Customer>{
    FullText : string
}