import * as React from 'react'
import { Table } from 'antd'
import { TableProps, TableState } from 'antd/lib/table';

export class TTTable<T> extends React.Component<TableProps<T>, TableState<T>> {
    render() {
        const { pagination } = this.props

        const page = pagination as any
        page.size = 'small'

        return (
            <Table pagination={page} {...this.props}/>
        )
    }
}