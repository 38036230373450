import * as React from 'react'
import { Collapse, Upload } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'

import { IAppDocumentiService } from '../../../Services/AppDocumentiService'
import { IAppFSVirtualeService } from '../../../Services/AppFSVirtualeService'

import SERVICE_IDENTIFIER from '../../../Wires/Identifiers'
import container from '../../../Wires/Bootstrapper'

import { RcCustomRequestOptions } from './RcCustomRequestOptions'
import { RouteParamId } from '../RouteParamId'
import { FilesystemVirtualeStateEditModel } from '../../../Models/FilesystemVirtualeModels'

import { BaseFileSystem } from '../../Contents/FS/BaseFileSystem'
import { TTComponent } from '../../../Shared/BaseComponent/TTComponentTs'
import { TTUploadLayout } from '../../Contents/TTUploadLayout'

class PageDocumentiState {
    Data: FilesystemVirtualeStateEditModel
}

export class PageDocumentiComp extends React.Component<RouteComponentProps<RouteParamId>, PageDocumentiState> {

    service: IAppFSVirtualeService

    constructor(props) {
        super(props)

        let toolbar = container.get<IAppDocumentiService>(SERVICE_IDENTIFIER.APP_DOCUMENTI_SERVICE).getToolbar()
        toolbar.addBackClick(() => this.props.history.goBack())

        this.service = container.get(SERVICE_IDENTIFIER.APP_FSVIRTUALE_SERVICE)
        this.state = new PageDocumentiState()
    }

    componentDidMount() {
        this.loadFolderFromUrl()
    }

    loadFolderFromUrl = () => {
        let id = this.props.match.params.id
        this.loadFolder(id)
    }

    loadFolder = async (id: string) => {
        let resp = await this.service.GetFolder(id)
        this.setState({ Data: resp })
    }

    getFile = (id: string) => {
        this.service.GetFile(id)
    }

    uploadFile = async (options: RcCustomRequestOptions) => {
        const { onSuccess, onError, file } = options
        const { Id, Cliente_Id } = this.state.Data

        var resp = await this.service.Save(file, Id, Cliente_Id)
        if (resp.IsValid) {
            onSuccess(resp, file)
            this.loadFolder(Id)
        } else {
            const error = new Error(resp.Notifies[0].Message)
            onError(error)
        }
    }

    render() {
        return (
            <>
                <BaseFileSystem
                    data={this.state.Data}
                    onOpenFile={e => this.getFile(e.Id)}
                    onOpenFolder={e => this.loadFolder(e.Id)}
                    onBack={e => this.loadFolder(e)}>

                    <Collapse>
                        <Collapse.Panel header="Caricamento File" key="1">
                            <Upload.Dragger
                                showUploadList={false}
                                customRequest={options => this.uploadFile(options as RcCustomRequestOptions)}>
                                <TTUploadLayout />
                            </Upload.Dragger>
                        </Collapse.Panel>
                    </Collapse>

                </BaseFileSystem>
            </>
        )
    }
}

export const PageDocumenti = withRouter(TTComponent(PageDocumentiComp, 'Documenti', SERVICE_IDENTIFIER.APP_DOCUMENTI_SERVICE))