import * as React from 'react'
import {
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    FlexibleWidthXYPlot,
    VerticalBarSeriesPoint,
} from 'react-vis'

import { ChartHintState } from './../../../Models/ChartHintState'
import { TTVisHint } from '../../Contents/TTVisHint'
import { formatEuro } from '../../Contents/TTFormatUtils'

export class FatturatoBarSeriesPoint implements VerticalBarSeriesPoint {
    x: string | number
    y: number
    color?: string | number
    opacity?: string | number
    stroke?: string | number
    fill?: string | number
    year: number 
}

interface IAxesChartFatturatoProps {
    previousYear: Array<FatturatoBarSeriesPoint>
    currentYear: Array<FatturatoBarSeriesPoint>
    tickXLabelAngle: number
    barWidth: number
}

export class AxesChartFatturato extends React.Component<IAxesChartFatturatoProps, ChartHintState<FatturatoBarSeriesPoint>> {
    constructor(props) {
        super(props)
        this.state = new ChartHintState()
    }

    onShowHint = data => {
        this.setState({ hint: data })
    }

    onHideHint = () => {
        this.setState({ hint: null })
    }

    // Nota: L'anno è in riga perchè il grafico si presenterà in questo modo:
    // Novembre 2017 - Novembre 2018
    // Dicembre 2017 - Dicembre 2018
    // Gennaio 2018 - Gennaio 2019
    // Febbraio 2018 - Febbraio 2019
    // ...
    renderHint = () => {
        return (
            <TTVisHint value={this.state.hint}>
                <div>{this.state.hint.x} {this.state.hint.year}</div>
                <div>{formatEuro(this.state.hint.y)}</div>
            </TTVisHint>
        )
    }

    render() {
        return (
            <FlexibleWidthXYPlot margin={{ bottom: 50, left: 60 }} xType="ordinal" xDistance={100} height={350}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis tickLabelAngle={this.props.tickXLabelAngle} />
                <YAxis />
                <VerticalBarSeries
                    barWidth={this.props.barWidth}
                    colorType="literal"
                    onValueMouseOver={value => this.onShowHint(value)}
                    onValueMouseOut={() => this.onHideHint()}
                    data={this.props.currentYear} />

                <VerticalBarSeries
                    barWidth={this.props.barWidth}
                    colorType="literal"
                    onValueMouseOver={value => this.onShowHint(value)}
                    onValueMouseOut={() => this.onHideHint()}
                    data={this.props.previousYear} />
                {this.state.hint ? this.renderHint() : null}
            </FlexibleWidthXYPlot>
        )
    }
}