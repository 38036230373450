import * as React from 'react'
import { Col, Row, Card, Form, Input, Select, Upload, Icon, message } from 'antd';
import { withRouter } from 'react-router-dom';

import TTComponent from '../../../Shared/BaseComponent/TTComponentTs';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { RouteComponentProps } from 'react-router';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import { ITenantEditService } from '../../../Services/TenantEditService';
import IoCContainer from '../../../Wires/Bootstrapper';
import { FormComponentProps } from 'antd/lib/form';

import { BaseFormReactComponent } from '../BaseReactComponent';

const Option = Select.Option
const TextArea = Input.TextArea

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        lg: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        lg: { span: 14 },
    }
}


export class TenantData  {
    public Tenant: string;
    public Databasstringe: string;
    public Server: string;
    public AdminUserName: string;
    public Office: any;

    constructor() {
        this.Office = {};
    }
}

const ComponentService: symbol = SERVICE_IDENTIFIER.TENANT_EDIT_SERVICE;

export class PageTenantEdit extends BaseFormReactComponent<IStoreModel & RouteComponentProps & ITTProps & FormComponentProps, TenantData> {

    private _service: ITenantEditService

    constructor(props) {
        super(props);
        this.state = this.props.componentData || new TenantData();

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.TENANT_EDIT_SERVICE);
        //this._service.getToolbar().addBackClick(() => this.props.history.push("/Circolari"));
        this._service.getToolbar().addButtonClick("Salva", () => this.saveTenant())
    }

    saveTenant = () => {
        this._service.SaveTenant(this.state)
    }
 
    protected onChangeNestedText = e => {
        const target = e.target;
        const value = target.value;
        const name = target.name;        
        let data = this.state;
        
        data['Office'][name] = value;
        this.setState(data);
    };

    render() {
        const { getFieldDecorator } = this.props.form
              
        return (
            <div>
            <Row gutter={24}>
                <Col xs={{ span: 48 }} md={{ span: 24 }}>

                    <Card bordered={false} title="Modifica Tenant">
                        <Form>
                            <Form.Item {...formItemLayout} label="Tenant">
                                {this.getRequiredField(<Input name="Tenant" size="small" onChange={this.onChangeText} />)}
                            </Form.Item>
                            <Form.Item {...formItemLayout} label="Database">
                                {this.getRequiredField(<Input name="Database" onChange={this.onChangeText} />)}
                            </Form.Item>                                                       
                            <Form.Item {...formItemLayout} label="Server">
                                {this.getRequiredField(<Input name="Server" onChange={this.onChangeText} />)}
                            </Form.Item>  
                            <Form.Item {...formItemLayout} label="Admin Username">
                                {this.getRequiredField(<Input name="AdminUserName" onChange={this.onChangeText} />)}
                            </Form.Item>                              
                        </Form>
                    </Card>
                </Col>                
                </Row>            
                <Row gutter={24}>
                    <Col xs={{ span: 48 }} md={{ span: 24 }}>

                        <Card bordered={false} title="Dati dell'ufficio">
                            <Form>                               
                                <Form.Item {...formItemLayout} label="Nome">
                                    {this.getRequiredField(<Input name="Name" onChange={this.onChangeNestedText} />)}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Telefono">
                                    {this.getRequiredField(<Input name="PhoneNumber" onChange={this.onChangeNestedText} />)}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Email">
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                type: 'email',
                                                message: 'Non è un indirizzo e-mail valido',
                                            },
                                            {
                                                required: true,
                                                message: 'Indirizzo email vuoto',
                                            },
                                        ],
                                    })(<Input name="Email" onChange={this.onChangeNestedText} />)}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Indirizzo">
                                    {this.getRequiredField(<Input name="Address" onChange={this.onChangeNestedText} />)}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Logo">
                                    {this.getRequiredField(<Input name="Logo" onChange={this.onChangeNestedText} />)}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Latitudine">
                                    {this.getRequiredField(<Input name="Latitude" onChange={this.onChangeNestedText} />)}
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="Longitudine">
                                    {this.getRequiredField(<Input name="Longitude" onChange={this.onChangeNestedText} />)}
                                </Form.Item>                                
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export const page = Form.create()(PageTenantEdit)
const result = withRouter(TTComponent(page, 'Modifica Tenant', ComponentService));
export default result;