import * as React from 'react'
import { Card, List, Row, Col, Alert } from 'antd'

import { TTIconButton } from '../TTIconButton';
import { TTList } from '../TTList';
import { TTAvatar } from '../TTAvatar';
import { TTModal, IModalProps } from '../TTModal';
import { ClienteProjection } from '../../../Models/ClienteProjection';
import { ModalProps } from 'antd/lib/modal';
import Search from 'antd/lib/input/Search';
import { PagedBaseState } from '../../../Models/PagedBaseState';
import { TTTable } from '../TTTable';
import Column from 'antd/lib/table/Column';
import container from '../../../Wires/Bootstrapper';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import { IClienteService } from '../../../Services/ClienteService';
import { GetClientiByGruppiSearcher } from '../../../Models/GetClientiByGruppoSearcher';
import { IClientiService } from '../../../Services/ClientiService';

export interface AdempimentoEditCustomersProps {
    customers: Array<ClienteProjection>
    customersUpdate: (customers: Array<ClienteProjection>) => void
}

export class AdempimentoEditCustomersState {
    isModalVisible: boolean
    customers: Array<ClienteProjection>
}

export class AdempimentoEditCustomers extends React.Component<AdempimentoEditCustomersProps, AdempimentoEditCustomersState> {

    constructor(props) {
        super(props)

        this.state = {
            customers: this.props.customers,
            isModalVisible: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Serve al salvataggio
        if (prevProps.customers != this.props.customers)
            this.setState({ customers: this.props.customers })
    }

    onClienteDelete = (id: string) => {
        TTModal.confirm({
            title: 'Attenzione',
            content: 'Sei sicuro di voler rimuovere il cliente?',
            okText: 'Conferma',
            onOk: () => {
                let clienti = [...this.state.customers]
                var index = clienti.findIndex(c => c.Id == id)
                if (index != -1) {
                    clienti.splice(index, 1);
                    this.setState({ customers: clienti })
                    this.props.customersUpdate(clienti)
                }
            }
        })
    }

    onClientiUpdate = (clienti: Array<ClienteProjection>) => {
        this.setState({ customers: clienti })
        this.props.customersUpdate(clienti)
    }

    toggleModal = () => {
        this.setState({ isModalVisible: this.state.isModalVisible == false })
    }

    render() {
        return (
            <Card bordered={false} title="Clienti aggiuntivi" extra={<TTIconButton tticon="add" onClick={this.toggleModal} />} >
                <Alert
                    type="info"
                    message="Clienti Aggiuntivi"
                    description='Puoi aggiungere dei clienti in modo slegato dai gruppi usando il tasto "+" in alto a destra'
                    closable
                    showIcon
                />
                <br />
                <TTList
                    itemLayout="horizontal"
                    dataSource={this.state.customers}
                    rowKey={it => it.Id}
                    pagination={{
                        showTotal: (total) => `${total} elementi`,
                        pageSize: 10,
                    }}
                    renderItem={item => (
                        <List.Item key={item.Id} actions={[<TTIconButton tticon="elimina" onClick={() => this.onClienteDelete(item.Id)} />]}>
                            <List.Item.Meta
                                avatar={<TTAvatar tticon="user" />}
                                title={item.Descrizione}
                            />
                        </List.Item>
                    )}
                />

                <CustomersPickerModal
                    visible={this.state.isModalVisible}
                    closeModal={this.toggleModal}
                    do={this.onClientiUpdate}
                    selectedCustomers={this.state.customers} />
            </Card>
        )
    }
}

interface CustomersPickerModalProps {
    selectedCustomers: Array<ClienteProjection>
}

class CustomersPickerModalState extends PagedBaseState<ClienteProjection> {
    selectedRowKeys: Array<string>
    selectedRows: Array<ClienteProjection>
}

export class CustomersPickerModal extends React.Component<IModalProps & ModalProps & CustomersPickerModalProps, CustomersPickerModalState> {

    service: IClientiService;

    constructor(props) {
        super(props)
        this.state = {
            Data: {
                Data: [],
                Total: 0
            },
            Page: 0,
            Searcher: null,
            selectedRowKeys: [],
            selectedRows: []
        }

        this.service = container.get(SERVICE_IDENTIFIER.CLIENTI_SERVICE);
    }

    componentDidMount() {
        this.updateSelectedCustomers()
        this.onSearch(1)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedCustomers != this.props.selectedCustomers)
            this.updateSelectedCustomers()
    }

    updateSelectedCustomers = () => {
        this.setState({
            selectedRows: this.props.selectedCustomers,
            selectedRowKeys: this.props.selectedCustomers.map(c => c.Id)
        })
    }

    onSearch = async (page: number) => {
        let search = null

        if (this.state.Searcher != null && this.state.Searcher != "")
            search = this.state.Searcher

        this.onSearchFull(page, search)
    }

    onSearchFull = async (page: number, search: string) => {
        let searcher: GetClientiByGruppiSearcher = {
            Gruppi: [],
            Righe: 10,
            Pagina: page
        }

        if (search != null)
            searcher.FullText = search

        let result = await this.service.Search(searcher)
        this.setState({ Data: result, Page: page })
    }

    onConfirm = () => {
        this.props.do(this.state.selectedRows)
    }

    onCancel = () => {
        // Resetto le righe selezionate allo stato iniziale che mi viene passato dal componente padre
        this.updateSelectedCustomers()
        this.closeModal()
    }

    closeModal = () => {
        this.setState({ Searcher: null })
        this.onSearchFull(1, null)
        this.props.closeModal()
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        // Aggiungo gli elementi selezionati alla lista se non presenti (gestione aggiunta);
        // selectedRows restituisce solo gli elementi selezionati della pagina corrente
        let tmp = [...this.state.selectedRows]
        let unique = selectedRows.filter((item: ClienteProjection) => tmp.findIndex(t => t.Id == item.Id) < 0)
        let list = tmp.concat(unique)

        // Sincronizzo i clienti con gli id (gestione rimozione)
        let list2 = list.filter(item => selectedRowKeys.indexOf(item.Id) >= 0)

        this.setState({ selectedRowKeys, selectedRows: list2 })
    };

    render() { 
        return (
            <TTModal {...this.props} title="Seleziona clienti" do={this.onConfirm} onCancel={() => this.onCancel()}>
                <Row>
                    <Col>
                        <Search
                            placeholder="Cerca cliente"
                            value={this.state.Searcher}
                            onChange={e => this.setState({ Searcher: e.target.value })}
                            onSearch={() => this.onSearch(1)}
                            enterButton />
                    </Col>
                </Row>
                <br />
                <TTTable
                    showHeader={false}
                    dataSource={this.state.Data.Data}
                    rowKey={it => it.Id}
                    rowSelection={{
                        selectedRowKeys: this.state.selectedRowKeys,
                        onChange: this.onSelectChange
                    }}
                    pagination={{
                        onChange: (page) => this.onSearch(page),
                        pageSize: 10,
                        total: this.state.Data.Total,
                        showTotal: (total) => `${total} elementi`,
                        current: this.state.Page
                    }}>
                    {/*<Column dataIndex="Id" render={() => <TTAvatar tticon="user" />} />*/}
                    <Column dataIndex="Descrizione" /> 
                        </TTTable>
            </TTModal>
        )
    }
}
