import * as React from 'react'
import { Card, Col, List, Radio, Row, Progress } from 'antd'

import * as Constants from './../../../Constants/AppConstants'
import { AdempimentiDonutChart } from './AdempimentiDonutChart'
import { IClienteBaseProps } from '../../../Models/IClienteBaseProps';
import { IClienteService } from '../../../Services/ClienteService';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { AdempimentiClienteModel, AdempimentiProgressModel } from '../../../Models/AdempimentiClienteModels';
import moment from 'moment';
import { TTAvatar } from '../TTAvatar';
import { TTClientPagedList } from '../TTClientPagedList';

const RadioGroup = Radio.Group

export class Adempimento {
    public id: string
    public title: string
    public description: string
    public progress: number
}

export class ClienteAdempimentiState {
    public Data: AdempimentiClienteModel
    public filter: string
    public adempimenti: Array<AdempimentiProgressModel>

    constructor() {
        this.Data = new AdempimentiClienteModel()
        this.filter = Constants.StatoAdempimentoInCorso
    }
}

export class ClienteAdempimenti extends React.Component<any & IClienteBaseProps, ClienteAdempimentiState> {


    private service: IClienteService;

    constructor(props) {
        super(props)

        this.service = IoCContainer.get(SERVICE_IDENTIFIER.CLIENTE_SERVICE);
        this.state = new ClienteAdempimentiState()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.clienteId != this.props.clienteId) {
            this.service.GetClienteAdempimenti(this.props.clienteId).then(resp => {
                this.setState({ Data: resp, adempimenti: resp.ListData })
                this.onClickFilter(Constants.StatoAdempimentoInCorso)
            })
        }
    }

    onAdempimentoClick = item => {
        this.props.history.push('/AdempimentoCustomer/' + this.props.clienteId + '/' + item.Id);
    }

    onClickFilter = filterBy => {
        this.setState({ filter: filterBy });

        if (filterBy == "all") {
            this.setState({ adempimenti: this.state.Data.ListData });
            return;
        }

        var arr = this.state.Data.ListData.filter(item => this.filterArr(item, filterBy))
        this.setState({ adempimenti: arr });
    }

    filterArr = (item: AdempimentiProgressModel, filter: string) => {
        if (filter == Constants.StatoAdempimentoDaFare && item.Progresso == 0) {
            return true
        } else if (filter == Constants.StatoAdempimentoFatto && item.Progresso == 100) {
            return true
        } else if (filter == Constants.StatoAdempimentoInCorso && item.Progresso < 100 && item.Progresso > 0) {
            return true
        }
    }

    formatData = (item: AdempimentiProgressModel) => {
        return item.Scadenza == null ? '' : moment(item.Scadenza).format('LL');
    }

    renderRadioButton = source => {
        let items = [];

        Object.keys(source).map((key, index) => (
            items.push(<Radio.Button key={index} value={key}>{source[key]}</Radio.Button>)
        ));

        return items;
    }

    render() {
        return (
            <Card bordered={false} title="Adempimenti">
                <Row align="middle" type="flex" justify="center" gutter={24}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }}>
                        <AdempimentiDonutChart
                            data={this.state.Data.GraphData}
                            onClickFilter={data => this.onClickFilter(data)} />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 18 }}>
                        <Row type="flex" justify="space-around">
                            <Col>
                                <RadioGroup onChange={e => this.onClickFilter(e.target.value)} value={this.state.filter}>
                                    {this.renderRadioButton(this.state.Data.FilterSource)}
                                </RadioGroup>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <TTClientPagedList
                                    itemLayout="horizontal"
                                    dataSource={this.state.adempimenti}
                                        style={{ height: '350px' }}
                                        pagination={{
                                            total: this.state.adempimenti == null ? 0 : this.state.adempimenti.length,
                                            showTotal: (total) => `${total} elementi`,
                                            pageSize: Constants.ListSize.ClienteAdempimenti
                                        }}
                                        renderItem={item => (
                                            <List.Item
                                                key={item.Id}
                                                actions={[<Progress type="circle" percent={item.Progresso} width={45} />]}>
                                                <List.Item.Meta
                                                    avatar={<TTAvatar tticon="adempimenti" />}
                                                    title={<a onClick={(event) => this.onAdempimentoClick(item)}>{item.Titolo}</a>}
                                                    description={this.formatData(item)}
                                                />
                                            </List.Item>
                                        )}
                                    />
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Card>
        )
    }
}