import { ModelBase } from "./ModelBase";

export class AdempimentiStatoModel {
    public Count: number
    public Code: string
    public Description: string
    public color: string
}

export class AdempimentiProgressModel extends ModelBase {
    public Titolo: string
    public Scadenza: Date
    public Progresso: number
}

export class AdempimentiClienteModel {
    public GraphData: Array<AdempimentiStatoModel>
    public ListData: Array<AdempimentiProgressModel>
    public FilterSource: { [key: string]: string; } = {};

    constructor() {
        this.GraphData = []
        this.ListData = []
    }
}