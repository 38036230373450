import * as React from 'react'
import { Empty } from 'antd'
import { TTIcon } from './TTIcon';
import { EmptyProps } from 'antd/lib/empty';
import renderEmpty from 'antd/lib/config-provider/renderEmpty';

export const TTEmpty: React.FC<EmptyProps> = (props) => {
    let image = <TTIcon icon="nessunDato" style={{ fontSize: '100px' }} />
    return <Empty image={image} description="Nessun elemento" {...props} />
}


export const ttRenderEmpty = (componentName?: string): React.ReactNode => {
    switch (componentName) {
        case 'Table':
        case 'List':
            return <TTEmpty />
        default:
            return renderEmpty(componentName)
    }
};