import { injectable } from "inversify";

export interface IFormDataConverter {
    convert(model: any): FormData;
}

@injectable()
export class FormDataConverter implements IFormDataConverter {

    convert(model: any): FormData {
        return this.fillFormData(model);
    }

    // trasforma il modello in input in form-multipart
    fillFormData = (obj: any, form?: any, namespace?: string) => {
        var fd = form || new FormData();
        var formKey;

        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {

                if (namespace) {
                    formKey = namespace + '[' + property + ']';
                } else {
                    formKey = property;
                }

                if (typeof obj[property] === 'object' &&
                    !(obj[property] instanceof File) &&
                    !Array.isArray(obj[property])) {

                    if (obj[property] !== null)
                        this.fillFormData(obj[property], fd, formKey);

                } else if (Array.isArray(obj[property])) {
                    var that = this;
                    obj[property].map((item, i) => {
                        var key = formKey + '[' + i + ']'

                        if (item instanceof File) fd.append(formKey, item)
                        else if (typeof item === 'object') that.fillFormData(item, fd, key);
                        else fd.append(key, item)
                    });
                } else {
                    fd.append(formKey, obj[property]);
                }
            }
        }

        return fd;
    }
}