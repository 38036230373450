import * as React from 'react'
import { PageHeaderToolbar } from './PageHeaderToolbar';
import { ButtonsItem } from '../../Services/ToolbarService/IToolbarModel';

export interface IContainerLayoutProps {
    onButtonClick(obj: any)
    onBackClick()
    boxed: boolean
    title: string
    toolbarData: { [id: string]: ButtonsItem }
}

const ContainerLayout: React.FC<IContainerLayoutProps> = props => {

    const { boxed, ...others} = props

    return (
        boxed ?
            <div>
                <PageHeaderToolbar {...others} />
                <br />
                <div className="box box-default">
                    <div className="box-body">
                        {props.children}
                    </div>
                </div>
            </div>
            :
            <React.Fragment>
                <PageHeaderToolbar {...others} />
                <br />
                {props.children}
            </React.Fragment>
    );
}

export default ContainerLayout