import * as React from 'react'
import { TTIcon } from './TTIcon'

export interface ITTUploadLayout {
    maxFiles?: number
}

export const TTUploadLayout: React.FC<ITTUploadLayout> = props => {
    let maxFiles = props.maxFiles != null ? `(max ${props.maxFiles} file)` : ''
    let text = `Clicca o trascina qui per caricare ${maxFiles}`

    return (
        <>
            <p>
                <TTIcon icon="carica" style={{ fontSize: 45 }} />
            </p>
            <p>
                {props.children != null ? props.children : text}
            </p>
        </>
    )
}
