import { ModelBase } from './ModelBase'
import { KeyValuePair } from './KeyValuePair'

export class StatoFirma extends ModelBase {
    public IdTicket: string
    public Stato: string
    public FilesystemVirtuale_Id: string
    public LabelTasto: string
    public UrlBozza: string
    public UrlFirma: string
    public File_Id: string
}

export class FirmaInputBase {
    public FileId: string
}

export class CambioStatoFirmaInput extends FirmaInputBase {
    public TemplateId: string
    public Users: Array<string>
}

export class TemplateListModel extends ModelBase {
    public Template: Array<KeyValuePair>
}