import * as React from 'react';
import { Card, Row, Col, Input, List, Avatar } from 'antd';
import { IStoreModel } from '../../../Redux/Reducers/IStoreModel';
import { RouteComponentProps } from 'react-router';
import { ITTProps } from '../../../Shared/BaseComponent/ITTProps';
import { SearcherBaseModel } from '../../../Models/SearcherBaseModel';
import { PagedBaseState } from '../../../Models/PagedBaseState';
import SERVICE_IDENTIFIER from '../../../Wires/Identifiers';
import IoCContainer from '../../../Wires/Bootstrapper';
import { ClientiiByGruppoCriteriSearcher } from '../../../Models/ClientiiByGruppoCriteriSearcher';
import { ClienteProjection } from '../../../Models/ClienteProjection';
import { GruppiStateEditModel } from './PageGruppoEdit';
import { IGruppoEditService } from '../../../Services/GruppoEditService';
import { TTAvatar } from '../TTAvatar';
import { TTList } from '../TTList';
import * as Constants from './../../../Constants/AppConstants'

const Search = Input.Search;

export class GruppoCustomersProps {
    id: string;
}

export class ClientiByGruppoSearcher extends SearcherBaseModel {
    Gruppi : Array<string>
}


export class CustomerState extends PagedBaseState<ClienteProjection> {
    FullText: string
}

export class GruppoCustomers extends React.Component<IStoreModel & RouteComponentProps & ITTProps & GruppiStateEditModel, CustomerState> {
    private _service: IGruppoEditService;
    private RigheLista = Constants.ListSizeClientiInForm;

    constructor(props) {
        super(props);

        this._service = IoCContainer.get(SERVICE_IDENTIFIER.GRUPPO_EDIT_SERVICE);
        this.state = new CustomerState();
    }

    onChange = e => {
        
        const target = e.target;
        const value = target.value;
        const name = target.name;
        let data = this.state;
        data[name] = value;
        this.setState(data);
    }

    onSearch = value => {
        this.DoSearch(1);
    }

    onPageChange = page => {
        this.DoSearch(page);
    }

    DoSearch = (page: any) => {
        
        let searcher: ClientiiByGruppoCriteriSearcher = {
            FullText: this.state.Searcher,
            Tipo: this.props.Tipo,
            Liquidazione: this.props.Liquidazione,
            Citta: this.props.Citta,
            Contabilita: this.props.Contabilita,
            RegimeIva: this.props.RegimeIva,
            Righe: this.RigheLista,
            Pagina: page
        };
        //if (this.props.Tipo!= null && this.props.Citta != null && this.props.Liquidazione !=null)       
        this._service.GetClienti(searcher).then(resp => this.setState({ ...this.state, Data: resp, Page: page  }))
    }

    componentDidMount() {
        this.DoSearch(1);
    }

    componentDidUpdate(prevProps: GruppiStateEditModel, prevState, snapshot) {
        if (prevProps.Liquidazione != this.props.Liquidazione ||
            prevProps.Tipo != this.props.Tipo ||
            prevProps.Citta != this.props.Citta ||
            prevProps.Contabilita != this.props.Contabilita ||
            prevProps.RegimeIva != this.props.RegimeIva) {
            this.DoSearch(1);
        }        
    }

    render() {

        return (
            <Card bordered={false} title="Clienti" className="last-card">
                <Row>
                    <Col offset={1} span={22} >
                        <Search name="Searcher" placeholder="Cerca cliente" onSearch={this.onSearch} enterButton onChange={this.onChange} />
                    </Col>
                </Row>
                <br />
                <TTList
                    itemLayout="horizontal"
                    dataSource={this.state.Data.Data || []}
                    pagination={{
                        onChange: (page) => this.onPageChange(page),
                        pageSize: this.RigheLista,
                        total: this.state.Data.Total,
                        showTotal: (total) => `${total} elementi`,
                        current: this.state.Page
                    }}
                    renderItem={item => (
                        <List.Item key={item.Id}>
                            <List.Item.Meta
                                avatar={<TTAvatar tticon="user" />}
                                title={item.Descrizione}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        );
    }
}