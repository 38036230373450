import * as React from 'react'
import moment from 'moment'
import { Modal, List } from 'antd'

import { AdempimentoModel } from '../../../Models/AppAdempimentiModels'

import { TTAvatar } from '../../Contents/TTAvatar'
import { TTList } from '../../Contents/TTList'
import { IModalProps } from '../../Contents/TTModal'

interface MoalAdempimentiProps {
    data: Array<AdempimentoModel>
    moment: moment.Moment
    closeModal: () => void
}

export class ModalAdempimenti extends React.Component<MoalAdempimentiProps, any> {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Modal
                visible={true}
                title={`Adempimenti per il ${this.props.moment.format('LL')}`}
                onCancel={() => this.props.closeModal()}
                footer={null}>
                <TTList
                    dataSource={this.props.data}
                    rowKey={it => it.Id}
                    itemLayout="horizontal"
                    pagination={{ pageSize: 10, hideOnSinglePage: true }}
                    renderItem={item => (
                        <List.Item key={item.Id} >
                            <List.Item.Meta
                                avatar={<TTAvatar tticon="adempimenti" />}
                                title={item.Titolo}
                                description="Adempimento" />
                        </List.Item>
                    )} />
            </Modal>
        )
    }
}